import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';

import { STRINGS } from '../../../constants';

const SuccessScreen = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      height: `calc(100vh - 64px)`,
      textAlign: 'center',
      px: { xs: 2, md: 1 },
      py: { xs: 4 },
      color: theme => theme.palette.text.primary,
    }}
  >
    <CheckCircleIcon fontSize='large' fillOpacity='0.56' />
    <Typography
      variant={'h2'}
      sx={{
        color: 'inherit',
        py: 2,
      }}
    >
      {STRINGS.YOUR_ENQUIRY_HAS_BEEN_PASSED}
    </Typography>

    <Typography
      variant={'body1'}
      sx={{
        color: 'inherit',
        maxWidth: '680px',
      }}
    >
      {STRINGS.WE_ARE_SORRY_TO_HEAR}
    </Typography>
  </Box>
);

export default SuccessScreen;
