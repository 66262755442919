import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Portal,
  styled,
  Typography,
} from '@mui/material';
import { ArrowDropUp } from '@mui/icons-material';
import {
  AmericaExpress,
  ApplePay,
  GooglePay,
  Mastercard,
  Paypal,
  Visa,
} from '../icons';

import { SUMMARY_DETAILS } from '../../constants/strings';
import TotalPrice from './components/TotalPrice';
import CheckoutPriceList from './components/CheckoutPriceList';

const StyledAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'flex-end',
  },
});

const PaymentsList = () => (
  <Grid
    sx={{
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {[ApplePay, GooglePay, Paypal, Visa, Mastercard, AmericaExpress].map(
      (Icon, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '42px',
            height: '28px',
          }}
        >
          <Icon />
        </Box>
      )
    )}
  </Grid>
);

const SummaryDetails = ({
  price = {},
  basketItemsWithPrice,
  displayItemsPriceDetails = true,
  displayInsurance = true,
  showMoreDetails = true,
}) => {
  const { totalIncVatAmount, taxAmount } = price;
  const [accordionDetailsHeight, setAccordionDetailsHeight] = useState('auto');

  const handleAccordionChange = (_, isExpanded) => {
    if (isExpanded) {
      const summaryHeight = 115;
      const viewportHeight = window.innerHeight;

      // Calculate max height for AccordionDetails dynamically
      setAccordionDetailsHeight(`${viewportHeight - summaryHeight}px`);
    } else {
      setAccordionDetailsHeight('auto');
    }
  };

  return (
    <>
      {/* Desktop version */}
      <Paper sx={{ p: 2, mb: 2, display: { xs: 'none', md: 'block' } }}>
        <Typography variant='h3'>{SUMMARY_DETAILS.TITLE}</Typography>
        {displayItemsPriceDetails && (
          <CheckoutPriceList
            items={basketItemsWithPrice}
            displayInsurance={displayInsurance}
            showMoreDetails={showMoreDetails}
          />
        )}
        <TotalPrice totalIPrice={totalIncVatAmount} taxAmount={taxAmount} />
        <PaymentsList />
      </Paper>
      {/* Mobile Version */}
      <Portal>
        <Box
          sx={{
            display: { md: 'none' },
            position: 'sticky',
            bottom: 0,
            zIndex: 3,
            width: '100%',
          }}
        >
          <Accordion elevation={4} onChange={handleAccordionChange}>
            <StyledAccordionSummary
              expandIcon={<ArrowDropUp />}
              sx={{
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                p: 1,
              }}
            >
              <TotalPrice
                totalIPrice={totalIncVatAmount}
                taxAmount={taxAmount}
              />
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                // Dynamic and depends of parcels number and mobile device screen size
                maxHeight: accordionDetailsHeight,
                // Enable inner scroll only
                overflowY: 'auto',
              }}
            >
              <Typography variant='h3'>{SUMMARY_DETAILS.TITLE}</Typography>
              {displayItemsPriceDetails && (
                <CheckoutPriceList
                  items={basketItemsWithPrice}
                  displayInsurance={displayInsurance}
                  showMoreDetails={showMoreDetails}
                />
              )}
              <PaymentsList />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Portal>
    </>
  );
};

SummaryDetails.propTypes = {
  price: PropTypes.object,
  basketItemsWithPrice: PropTypes.arrayOf(PropTypes.object),
  displayItemsPriceDetails: PropTypes.bool,
  displayInsurance: PropTypes.bool,
  showMoreDetails: PropTypes.bool,
};

export default SummaryDetails;
