import apis from './apis';

export const fetchCountries = (query, options) =>
  apis.get('references/countries', {
    query,
    options,
  });

export const fetchNetworks = (query, options) =>
  apis.get('references/networks', {
    params: query,
    options,
  });

export const fetchNetworkPrice = (
  { busZone, networkCode, countryCode },
  options
) =>
  apis.get('references/prices', {
    params: { busZone, networkCode, countryCode },
    options,
  });

export const fetchCurrencies = (query, options) =>
  apis.get('references/currencies', {
    params: query,
    options,
  });

export const fetchExportReasons = (query, options) =>
  apis.get('references/exportReasons', {
    params: query,
    options,
  });

export const fetchNiRequiredFields = (query, options) =>
  apis.get('references/requiredFieldsForDomesticZone', {
    params: query,
    options,
  });
