import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { AuthModel, useAuth } from '../../../features/Auth';
import Loader from '../../../features/Loader/Loader';
import ReduxifiedWizard from '../../../features/Wizard/ReduxifiedWizard.jsx';
import { VERIFICATION } from '../../../constants/analytics';
import { EXPIRED_AUTH_CODES } from '../../../constants/error';
import { SIGN_UP } from '../../../constants/routes';
import Step from '../components/Step';
import EnterEmail from './EnterEmail';
import EmailSent from './EmailSent';
import { VerifyEmailSlice } from './slice';
import Error from './Error';

const STEPS = {
  ENTER: 'enter',
  SENT: 'sent',
  ERROR: 'error',
};

function VerifyEmail() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateAuthToken = useCallback(async ({ verificationId }) => {
    if (verificationId) {
      try {
        const session = await auth.verifyMagicLink(verificationId).unwrap();

        if (!AuthModel.isDpdUserAccount(session)) {
          navigate(SIGN_UP, {
            replace: true,
            state: {
              verificationId,
            },
          });
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        if (EXPIRED_AUTH_CODES.indexOf(error.code) >= 0) {
          dispatch(VerifyEmailSlice.actions.clearAll());
          return true;
        }

        throw error;
      }
    }
  }, []);

  return (
    <Trackable
      interfaceId={VERIFICATION.INTERFACE_ID}
      loadId={VERIFICATION.LOAD}
    >
      <Loader
        promiseFn={validateAuthToken}
        verificationId={searchParams.get('verificationId')}
        errorComponent={error => <Error values={{ error }} />}
      >
        {isExpired => (
          <ReduxifiedWizard
            isLazyMount={true}
            slice={VerifyEmailSlice}
            sx={{ flexGrow: 1, display: 'flex', width: '100%' }}
          >
            <Step
              stepName={STEPS.ENTER}
              component={EnterEmail}
              isExpired={isExpired}
            />
            <Step stepName={STEPS.SENT} component={EmailSent} />
            <Step stepName={STEPS.ERROR} component={Error} />
          </ReduxifiedWizard>
        )}
      </Loader>
    </Trackable>
  );
}

export default VerifyEmail;
