import moment from 'moment-timezone';
import { Box } from '@mui/material';
import { ORDERS_SECTION } from '../constants/strings';
import CopyToClipboardButton from '../components/CopyToClipboardButton';
import themeConfig from '../theme/theme.json';

const DATE_FORMAT = 'DD/MM/YY';

export const transformDateWithMessage = message => (row, pathName) =>
  row[pathName]
    ? `${message} ${moment(row[pathName]).format(DATE_FORMAT)}`
    : '-';

export const getParcelStatusContent = (row, pathName) => {
  const status = row[pathName];

  return (
    <Box
      sx={{
        color: themeConfig.colorSchemes.light.palette.parcelStatus[status],
      }}
    >
      {status}
    </Box>
  );
};

export const getParcelDescriptionContent = (row, pathName) =>
  row[pathName].toUpperCase();

export const getParcelNumberContent = (row, pathName) => {
  const parcelNumber = row[pathName];

  return (
    <Box
      sx={{
        position: 'relative',
        '.icon-copy-button': {
          opacity: 0,
        },
        '&:hover .icon-copy-button': {
          opacity: 1,
        },
      }}
    >
      <Box>{parcelNumber}</Box>
      <CopyToClipboardButton
        className='icon-copy-button'
        text={parcelNumber}
        successMessage={ORDERS_SECTION.NOTIFICATIONS.COPY_TO_CLIPBOARD_SUCCESS}
      />
    </Box>
  );
};

export const getAddressTypeContent = row => {
  const { isDefault, isBilling } = row;
  const sx = {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '16px',
    opacity: 0.7,
  };

  if (isDefault) {
    return <Box sx={sx}>{isBilling ? 'Primary / Billing' : 'Primary'}</Box>;
  }

  return <Box sx={sx}>{isBilling ? 'Billing' : ''}</Box>;
};

export const getFullAddress = row => {
  const addressLine2Part = row.addressLine2 ? `${row.addressLine2},` : '';
  const addressLine4Part = row.addressLine4 ? `${row.addressLine4},` : '';

  return `${row.addressLine1}, ${addressLine2Part} ${row.addressLine3}, ${addressLine4Part} ${row.postcode}`;
};
