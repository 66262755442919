import { get, set } from 'lodash';

export const bulkUpdate = (form, fields = {}) =>
  form.batch(() => {
    Object.entries(fields).forEach(([key, value]) => {
      form.change(key, value);
    });
  });

export const bulkReset = (form, fields = {}) =>
  form.batch(() => fields.map(fieldName => form.change(fieldName, undefined)));

export const hasErrorInRegisteredFields = (errors = {}, fields = []) => {
  const newErrors = {};

  fields.map(field => {
    if (get(errors, field)) {
      set(newErrors, field, get(errors, field));
    }
  });

  return newErrors;
};
