// Check if app is in development mode
export const IS_DEV_MODE =
  import.meta.env.MODE === 'development' || import.meta.env.DEV;

export const DEV_APIS_BASE_URL = 'http://localhost:8030';
export const DEV_AUTH_BASE_URL = 'http://localhost:8031';

export const APIS_BASE_URL = IS_DEV_MODE
  ? DEV_APIS_BASE_URL
  : import.meta.env.VITE_APIS_URL;

export const AUTH_BASE_URL = IS_DEV_MODE
  ? DEV_AUTH_BASE_URL
  : import.meta.env.VITE_APIS_URL;

const DEV_FMP_BASE_URL = 'https://d1-track.dpd.co.uk';
const PROD_FMP_BASE_URL = 'https://track.dpd.co.uk';

export const FMP_BASE_URL = IS_DEV_MODE ? DEV_FMP_BASE_URL : PROD_FMP_BASE_URL;

export const AFD = {
  ID: import.meta.env.VITE_AFD_ID,
  TOKEN: import.meta.env.VITE_AFD_TOKEN,
  SERIAL: import.meta.env.VITE_AFD_SERIAL,
  PASSWORD: import.meta.env.VITE_AFD_PASSWORD,
  API_BASE_URL_SERIAL_PASSWORD: 'https://pce.afd.co.uk/afddata.pce',
  API_BASE_URL_ID_TOKEN: 'https://apps.afd.co.uk/json',
};
