import { Box, Button, Paper, Typography } from '@mui/material';
import PickupPointWidget from '../../PickupPointwidget/PickupPointWidget';
import StaticMapPreview from '../StaticMapPreview';
import { getPartnerLogo, getPickupAddress } from '../../../utils/pickupPoint';
import { CUSTOM_MARKER } from '../../../constants/pickupPoint';

const PickupMapImage = ({ title, buttonLabel, pickupPoint, buttonOnClick }) => {
  const { latitude, longitude } = pickupPoint.pickupLocation.addressPoint;

  return (
    <Paper
      sx={{
        px: 3,
        py: 2,
        mb: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography sx={{ mb: 2 }} variant='h3'>
        {title}
      </Typography>
      <PickupPointWidget
        address={getPickupAddress(pickupPoint)}
        partnerLogo={getPartnerLogo(pickupPoint)}
        pickupPoint={pickupPoint}
      />
      {latitude && longitude && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 2,
          }}
        >
          <StaticMapPreview
            customMarker={CUSTOM_MARKER}
            longitude={longitude}
            latitude={latitude}
            zoom={14}
            style={{
              borderRadius: 16,
            }}
            width={580}
            height={170}
          />
        </Box>
      )}
      <Button sx={{ mt: 2 }} variant='outlined' onClick={buttonOnClick}>
        {buttonLabel}
      </Button>
    </Paper>
  );
};

export default PickupMapImage;
