import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import WizardForm from '../../../features/Wizard/Form';
import { Validators } from '../../../utils';
import { ShipmentValidator } from '../validators';
import { FormHelpers } from '../../../helpers';

const OrderForm = ({
  children,
  onSubmit,
  context,
  requiredKeys,
  additionalFields = [],
  validateOnInit = false,
  ...props
}) => {
  const formRef = useRef();

  const registeredFields = formRef?.current?.getRegisteredFields() || [];

  const schema = useMemo(
    () =>
      Validators.requireKeysSchema(
        ShipmentValidator.shipmentSchema(context),
        requiredKeys
      ),
    [requiredKeys, context]
  );

  const mutators = useMemo(
    () => ({
      markFieldTouched: ([name, touched], state) => {
        state.fields[name] = state.fields[name] || {};
        state.fields[name].touched = touched;
      },
    }),
    []
  );

  const validate = useCallback(
    values => {
      let valuesToValidate = values;

      const errors = Validators.validateWithJoi(valuesToValidate, schema, {
        allowUnknown: true,
      });
      let allocatedErrors = {};

      if (errors) {
        allocatedErrors = FormHelpers.hasErrorInRegisteredFields(errors, [
          ...registeredFields,
          ...additionalFields,
        ]);
      }

      return allocatedErrors;
    },
    [schema, JSON.stringify(registeredFields), JSON.stringify(additionalFields)]
  );

  const validateInitialValues = useCallback(form => {
    const state = form.getState();
    const registeredFields = form.getRegisteredFields();
    const errors = state.errors;
    registeredFields.forEach(field => {
      const initialValueRegisteredField = get(state.initialValues, field);
      if (initialValueRegisteredField) {
        const fieldError = get(errors, field);
        if (fieldError) {
          form.mutators.markFieldTouched(field, true);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (formRef.current && validateOnInit) {
      validateInitialValues(formRef.current);
    }
  }, [formRef.current]);

  return (
    <WizardForm
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur
      mutators={mutators}
      {...props}
    >
      {formProps => {
        const child =
          typeof children === 'function' ? children(formProps) : children;
        if (!formRef.current) {
          formRef.current = formProps.form;
        }
        return child;
      }}
    </WizardForm>
  );
};

OrderForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.object,
  requiredKeys: PropTypes.arrayOf(PropTypes.string),
  additionalFields: PropTypes.arrayOf(PropTypes.string),
  validateOnInit: PropTypes.bool,
};

export default OrderForm;
