export const LISTS_WITH_PROHIBITED_ITEMS = [
  'Accessories',
  'Active Mobile Phones Outside The EU',
  'Aerosol',
  'Aftershaves',
  'Air Bag',
  'Amber',
  'Ammunition',
  'Animals Or Organisms Of Any Form (Alive Or Dead)',
  'Antiques',
  'Any Articles (Or Part Of Them) That Are Made Up Of China, Glass, Porcelain, Earthenware Or Other Similar Materials',
  'Any Item Containing Petrol (Liquid, Gas Or Fumes)',
  'Aromatherapy Oil',
  'Art',
  'Bank Card',
  'Basin',
  'Basins',
  'Batteries',
  'Beds & Headboards',
  'Biological Samples',
  'Birds',
  'Boiler/Boilers',
  'Bottles',
  'Bulbs',
  'Camera',
  'Camping Gas All Types',
  'Canvas Print',
  'Car Panel',
  'Cash, Credit Cards, Debit Cards',
  'Ceramics/Figures/Figurines',
  'Chainsaw',
  'Cheques',
  'China/Porcelain',
  'Chocolate',
  'Christmas Crackers',
  'Clock',
  'Coffee Machines',
  'Computer Monitor/Monitors/Imac',
  'Concrete',
  'Confectionery',
  'Counterfeit Currency',
  'Covid Test',
  'Credit/Debit/Cash Cards',
  'Crockery/Pots/Pottery',
  'Crystal',
  'Dangerous Goods',
  'Delicate Items',
  'Detergent',
  'Diamonds',
  'Diffuser',
  'Documents',
  'Domestic Appliances (Washing Machines, Dryers, Fridges, Ovens)',
  'Driving Licence',
  'Drugs (Including Prescription)',
  'Engines',
  'Fiberglass',
  'Fire Crackers',
  'Fire Extinguisher',
  'Firearms',
  'Fireworks',
  'Fish (Live Or Dead)',
  'Fish Tank',
  'Fishing Rods',
  'Flammable Goods',
  'Fragile Items (All)',
  'Fragrance',
  'Framed Painting/Photograph',
  'Furniture (Including Flat-Packed)',
  'Furs',
  'FXS',
  'Gearbox',
  'Gems Or Gemstones',
  'Glass (DPD Local)',
  'Glass/Glassware',
  'Granite Products',
  'Hamper',
  'Hazardous Goods',
  'Headlight',
  'Hover Board/Swegway/Segway',
  'Human Flesh',
  'Human Remains',
  'Infectious Substances',
  'Item Described As "Unknown"',
  'Ivory',
  'Jewellery Including Watches',
  'Juice',
  'Kayaks',
  'Knives',
  'Ladders',
  'Lampshades',
  'Legal Or Business Documents',
  'Lenses',
  'Lighter',
  'Lights/Lighting/Lamps/Chandeliers',
  'Liquids/Creams/Oils',
  'Lottery Tickets',
  'Machines',
  'Magnets',
  'Marble Products',
  'Mattresses',
  'Meat',
  'Medical Equipment',
  'Men’s Fragrances',
  'Microscope',
  'Microwave',
  'Milk Powder (Formula)',
  'Mirror',
  'Mobile Phone/Mobile Phones With Or Without Sim To Any Address In Turkey/Pakistan/Ecuador',
  'Money/Cash/Currency/Vouchers',
  'Nail Varnish/Polish',
  'Oil',
  'Ornament',
  'Over Head Projector',
  'Packaging/Media Packaging',
  'Paint',
  'Painting/Pictures',
  'Passport',
  'Perfumes',
  'Perishable Goods (All)',
  'Personal Effects - Including Personal Belongings Or Personal Items',
  'Petrol Or Diesel Tanks (Full Or Empty) In Any Item',
  'Pewter Figures',
  'Picture Frames',
  'Plant/S',
  'Poster',
  'Precious Metals (Gold/Silver/Platinum Etc.)',
  'Print',
  'Printer Toner/ Ink Cartridges',
  'Projector',
  'Real Fur',
  'Replica Weapons',
  'Resin',
  'Scanner',
  'Seatbelt Tensioner',
  'Settee',
  'Sewing/Knitting Machine',
  'Shower Screen',
  'SIM Cards',
  'Slush Syrup',
  'Slush Syrup',
  'Sofa',
  'Spectacles',
  'Stamps (Unless Franked)',
  'Steering Wheel',
  'Stoneware',
  'Suitcase',
  'Suitcases/ Flight Cases (Not Acceptable As Packaging)',
  'Surf Board',
  'Telescope',
  'Television',
  'Tickets',
  'Tiles',
  'Tobacco And Tobacco Products',
  'Toilets',
  'Torches',
  'Trees',
  'Turntables',
  'TV',
  'Tyres',
  'Vase',
  'Vehicle Bonnet',
  'Vehicle Bumper',
  'Vehicle Door',
  'Velux',
  'Vermiculite Board',
  'Vouchers With A Face Value',
  'Washbasin (DPD Local, ExpressPak)',
  'Watch',
  'Water',
  'Weapons',
  'Wheels',
  'Window',
  'Window Frame',
  'WOMEN’s FRAGRANCES',
  'Wooden Items (Australia)',
];

export const LISTS_WITH_SPECIAL_PROVISIONS_ITEMS = [
  'Amplifiers - maximum Parcel Protection value for damages is £200.00, up to £2,500.00 for lost items.',
  'Apple Mac - maximum Parcel Protection value for damages is £200.00, up to £2,500.00 for lost items. iMacs are on the prohibited list and not protected for loss or damage.',
  'Banjo - maximum Parcel Protection value for damages is £100.00, up to £1,000.00 for lost items. Banjo must be sent in a hard case.',
  'Cello - maximum Parcel Protection value for damages is £100.00, up to £1,000.00 for lost items. Cello must be sent in a hard case.',
  'Guitar - maximum Parcel Protection value for damages is £100.00, up to £1,000.00 for lost items. Guitar must be sent in a hard case.',
  'Violin - maximum Parcel Protection value for damages is £100.00, up to £1,000.00 for lost items. Violin must be sent in a hard case.',
  'Beauty Products',
  'Clothing',
  'Fabric',
  'Gift',
  'Nail Products',
  'Skincare',
  'Toys',
];
