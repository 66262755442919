import HomeCollection from '../../components/icons/HomeCollection';
import LabelDropOff from '../../components/icons/LabelDropOff';
import LabelPack from '../../components/icons/LabelPack';
import LabelPrint from '../../components/icons/LabelPrint';
import { CONFIRMATION } from '../../constants/strings';
import { formattedDayOfTheWeekDayMonthShipmentDate } from '../../utils/date';

export const DROP_OFF_STEPS_CONFIG = [
  {
    id: 1,
    icon: LabelPrint,
    text: CONFIRMATION.STEPS.PACK_ITEM,
  },
  {
    id: 2,
    icon: LabelPack,
    text: CONFIRMATION.STEPS.PRINT_LABEL,
  },
  {
    id: 3,
    icon: LabelDropOff,
    text: CONFIRMATION.STEPS.BRING_PARCEL_TO_DROPOFF,
  },
];

export const COLLECTION_STEPS_CONFIG = (shipment, dropAtShop) => [
  {
    id: 1,
    icon: LabelPrint,
    text: CONFIRMATION.STEPS.PACK_ITEM,
  },
  {
    id: 2,
    icon: LabelPack,
    text: CONFIRMATION.STEPS.PRINT_LABEL,
  },
  {
    id: 3,
    icon: HomeCollection,
    text: dropAtShop ? (
      CONFIRMATION.STEPS.BRING_PARCEL_TO_DROPOFF
    ) : (
      <>
        {shipment?.shipmentDate &&
          formattedDayOfTheWeekDayMonthShipmentDate(shipment?.shipmentDate)}
        {shipment?.shipmentDate && <br />}
        {CONFIRMATION.STEPS.DRIVER_COLLECT}
      </>
    ),
  },
];
