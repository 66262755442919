import { createReducer } from '@reduxjs/toolkit';
import {
  createSession,
  getCurrentSession,
  logoutSession,
  sendMagicLink,
} from './actions';

const initialState = {
  isLoading: true,
  error: null,
  currentSession: null,
};

const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCurrentSession.pending, state => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getCurrentSession.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.currentSession = null;
    })
    .addCase(getCurrentSession.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.currentSession = action.payload;
    })
    .addCase(createSession.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.currentSession = action.payload;
    })
    .addCase(logoutSession.pending, state => {
      state.isLoading = true;
    })
    .addCase(logoutSession.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.currentSession = action.payload;
    })
    .addCase(logoutSession.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.currentSession = null;
    })
    .addCase(sendMagicLink.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
});

export default authReducer;
