import moment from 'moment-timezone';
import { get, groupBy, noop } from 'lodash';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Grid } from '@mui/material';
import { TO_BE_COLLECTED, TO_BE_DROPPED_OFF } from '../../constants/strings';
import {
  DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
} from '../../constants/dateFormat';
import { SHIPMENT_FIELDS } from '../../constants/forms';

const BasketItemsGroup = ({
  title,
  basketItems,
  basketItemComponent: BasketItemComponent,
}) => (
  <Box
    sx={{
      marginBottom: { xs: 2, md: 0 },
    }}
  >
    <Grid item sx={{ width: '100%', mt: 2 }}>
      <Divider variant='middle' sx={{ textTransform: 'uppercase' }}>
        {title}
      </Divider>
    </Grid>
    {basketItems.map((basketItem, index) => (
      <BasketItemComponent key={index} basketItem={basketItem} />
    ))}
  </Box>
);

BasketItemsGroup.propTypes = {
  title: PropTypes.string,
  basketItems: PropTypes.arrayOf(PropTypes.object),
  basketItemComponent: PropTypes.node.isRequired,
};

const BasketItemsGroupsList = ({ basketItems, basketItemComponent = noop }) => {
  const groupedBasketItems = groupBy(
    basketItems,
    `shipment.${SHIPMENT_FIELDS.COLLECT_FROM.KEY}`
  );

  const collectBasketItemsGroupedByDate = useMemo(
    () =>
      Object.entries(
        groupBy(groupedBasketItems.door, basketItem =>
          moment(
            get(basketItem, 'shipment.shipmentDate'),
            DATE_TIME_FORMAT
          ).format(DEFAULT_DATE_FORMAT)
        )
      )
        .map(([collectDate, basketItems]) => ({
          collectDate,
          basketItems: [...basketItems].sort((a, b) =>
            moment(a.createdAt, DEFAULT_DATE_FORMAT).diff(
              moment(b.createdAt, DEFAULT_DATE_FORMAT)
            )
          ),
        }))
        .sort((a, b) =>
          moment(a.collectDate, DEFAULT_DATE_FORMAT).diff(
            moment(b.collectDate, DEFAULT_DATE_FORMAT)
          )
        ),
    [groupedBasketItems.door]
  );

  const toDropOffBasketItems = groupedBasketItems.shop;

  const getCollectGroupTitle = collectDate =>
    `${TO_BE_COLLECTED} ${moment(collectDate, DEFAULT_DATE_FORMAT).format(
      DISPLAY_DATE_TIME_FORMAT
    )}`;

  return (
    <>
      {collectBasketItemsGroupedByDate.map(({ collectDate, basketItems }) => (
        <BasketItemsGroup
          key={collectDate}
          title={getCollectGroupTitle(collectDate)}
          basketItems={basketItems}
          basketItemComponent={basketItemComponent}
        />
      ))}
      {!!toDropOffBasketItems?.length && (
        <BasketItemsGroup
          title={TO_BE_DROPPED_OFF}
          basketItems={toDropOffBasketItems}
          basketItemComponent={basketItemComponent}
        />
      )}
    </>
  );
};

BasketItemsGroupsList.propTypes = {
  basketItems: PropTypes.arrayOf(PropTypes.object),
  basketItemComponent: PropTypes.node.isRequired,
};

export default BasketItemsGroupsList;
