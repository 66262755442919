import { createSelector } from '@reduxjs/toolkit';

export const getBasketData = state => state.basket;

export const getBasketId = createSelector(
  [getBasketData],
  data => data.basketId
);
export const getBasketItems = createSelector(
  [getBasketData],
  data => data?.items
);

export const getBasketItemsCount = createSelector(
  [getBasketItems],
  items => items.length
);

export const getBasketItemById = createSelector(
  [getBasketItems, (state, basketItemId) => basketItemId],
  (items, basketItemId) =>
    items.find(item => item.basketItemId === basketItemId)
);
