import { SvgIcon } from '@mui/material';

const Paypal = props => (
  <SvgIcon {...props} viewBox='0 0 32 10'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.16943 0.85293H1.74797C1.58227 0.85293 1.44134 0.973347 1.4155 1.13685L0.436134 7.34549C0.416668 7.46803 0.511556 7.57846 0.635838 7.57846H1.79187C1.95757 7.57846 2.0985 7.45804 2.12434 7.29421L2.38849 5.61966C2.41397 5.45567 2.55524 5.33541 2.7206 5.33541H3.48715C5.08225 5.33541 6.00278 4.56359 6.24331 3.03421C6.35162 2.36511 6.2479 1.83937 5.93445 1.47107C5.59036 1.06689 4.97985 0.85293 4.16943 0.85293ZM4.4488 3.12055C4.31641 3.98936 3.65251 3.98936 3.01059 3.98936H2.64518L2.90153 2.36675C2.91675 2.26878 3.00172 2.19653 3.10087 2.19653H3.26834C3.70561 2.19653 4.11815 2.19653 4.33132 2.44572C4.4583 2.59448 4.4973 2.81533 4.4488 3.12055ZM11.4078 3.0927H10.2482C10.1494 3.0927 10.064 3.16495 10.0488 3.26309L9.9975 3.58731L9.9164 3.46984C9.66537 3.10548 9.10565 2.98375 8.54692 2.98375C7.26543 2.98375 6.17105 3.95413 5.95788 5.31543C5.84712 5.99435 6.00458 6.64362 6.3898 7.09646C6.74323 7.51276 7.24888 7.68626 7.85038 7.68626C8.88281 7.68626 9.45548 7.02241 9.45548 7.02241L9.4037 7.34467C9.3842 7.46787 9.47907 7.57829 9.60262 7.57829H10.6472C10.8133 7.57829 10.9534 7.45787 10.9796 7.29404L11.6064 3.32567C11.6262 3.20361 11.5317 3.0927 11.4078 3.0927ZM9.79137 5.34934C9.67946 6.01171 9.15366 6.45636 8.48318 6.45636C8.14646 6.45636 7.87725 6.3484 7.70455 6.14377C7.53316 5.94061 7.46795 5.65129 7.52251 5.32919C7.62705 4.67238 8.1617 4.21332 8.82202 4.21332C9.1512 4.21332 9.41894 4.32259 9.59524 4.52902C9.77188 4.73758 9.842 5.02855 9.79137 5.34934ZM16.4182 3.09253H17.5835C17.7467 3.09253 17.8419 3.27554 17.7492 3.40939L13.8736 9.00299C13.8108 9.09359 13.7075 9.14749 13.597 9.14749H12.4332C12.2693 9.14749 12.1736 8.96301 12.2685 8.82883L13.4753 7.12562L12.1918 3.35942C12.1474 3.22851 12.2441 3.09253 12.3834 3.09253H13.5284C13.6771 3.09253 13.8084 3.19018 13.8513 3.33255L14.5324 5.60721L16.1397 3.24015C16.2026 3.14775 16.3071 3.09253 16.4182 3.09253Z'
      fill='#253B80'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.0527 7.34493L30.0465 1.02276C30.0617 0.924628 30.1468 0.852378 30.2456 0.852051H31.3643C31.4879 0.852051 31.5828 0.962801 31.5633 1.08535L30.5833 7.29365C30.5577 7.45749 30.4168 7.57791 30.2508 7.57791H29.2516C29.1281 7.57791 29.0332 7.46748 29.0527 7.34493ZM21.4419 0.852376H19.02C18.8547 0.852376 18.7138 0.972794 18.6879 1.1363L17.7085 7.34493C17.689 7.46748 17.7839 7.57791 17.9076 7.57791H19.1503C19.2658 7.57791 19.3646 7.49369 19.3826 7.37901L19.6605 5.61911C19.6861 5.45511 19.8273 5.33486 19.9926 5.33486H20.7588C22.3543 5.33486 23.2746 4.56304 23.5153 3.03365C23.624 2.36456 23.5196 1.83882 23.2063 1.47052C22.8624 1.06634 22.2523 0.852376 21.4419 0.852376ZM21.7213 3.12C21.5892 3.98881 20.9253 3.98881 20.283 3.98881H19.9179L20.1747 2.3662C20.1899 2.26823 20.2741 2.19598 20.3736 2.19598H20.5411C20.9781 2.19598 21.391 2.19597 21.604 2.44517C21.7311 2.59393 21.7698 2.81478 21.7213 3.12ZM28.6796 3.09214H27.5209C27.4212 3.09214 27.3367 3.16439 27.3218 3.26253L27.2705 3.58676L27.1891 3.46929C26.938 3.10492 26.3786 2.9832 25.8199 2.9832C24.5384 2.9832 23.4444 3.95358 23.2312 5.31487C23.1208 5.9938 23.2776 6.64307 23.6628 7.09591C24.0169 7.51221 24.5219 7.68571 25.1234 7.68571C26.1558 7.68571 26.7283 7.02185 26.7283 7.02185L26.6767 7.34412C26.6572 7.46732 26.7521 7.57774 26.8764 7.57774H27.9205C28.0858 7.57774 28.2267 7.45732 28.2526 7.29349L28.8797 3.32512C28.8989 3.20306 28.804 3.09214 28.6796 3.09214ZM27.0634 5.34878C26.9521 6.01116 26.4257 6.45581 25.755 6.45581C25.419 6.45581 25.1493 6.34784 24.9764 6.14322C24.805 5.94006 24.7406 5.65073 24.7945 5.32864C24.8995 4.67183 25.4335 4.21277 26.0939 4.21277C26.4232 4.21277 26.6908 4.32204 26.8672 4.52847C27.0445 4.73703 27.1147 5.028 27.0634 5.34878Z'
      fill='#179BD7'
    />
  </SvgIcon>
);

export default Paypal;
