import { add, round } from 'lodash';
import { replaceFirstZero, replaceLetters } from './strings';

export const fieldTrimOnBlur = {
  formatOnBlur: true,
  format: value => (typeof value === 'string' ? value.trim() : ''),
};

// Function to inject as a mutator for resetting dependent fields
export const resetFields = ([fields], state, { changeValue }) => {
  fields.forEach(field => changeValue(state, field, () => undefined));
};

// Function to control resetting dependent fields on rules change
export const resetDependentFields =
  fieldDependencyMap => (formApi, fieldName) => {
    const fieldsToReset = fieldDependencyMap[fieldName];

    if (fieldsToReset) {
      formApi.mutators.resetFields(fieldsToReset);
    }
  };

export const resetDependentSections = ({
  sectionDependencyMap,
  sectionFieldsMap,
  reset,
  section,
}) => {
  const sectionsToReset = sectionDependencyMap[section];

  if (!sectionsToReset || sectionsToReset.length === 0) {
    return;
  }

  const fieldsToReset = sectionsToReset.reduce((acc, current) => {
    const fields = sectionFieldsMap[current].map(({ name }) => name);
    return [...acc, ...fields];
  }, []);

  if (fieldsToReset?.length) {
    reset(fieldsToReset);
  }
};

export const restrictToDigitsAndLimit = (value, limit) =>
  value ? replaceFirstZero(replaceLetters(value)).slice(0, limit) : '';

export const getLiabilityCoverByValue = (insuranceConfig, value) => {
  const MAX_COVERAGE =
    insuranceConfig[insuranceConfig.length - 1].insuranceValue;
  const coverageArray = insuranceConfig.map(
    insurance => insurance.insuranceValue
  );

  return coverageArray.find(cVal => value <= cVal) || MAX_COVERAGE;
};

export const getTotalCoverage = (parcels = [], insuranceConfig = []) => {
  const totalValue = parcels.reduce(
    (totalParcelsValue, parcel) =>
      add(
        totalParcelsValue,
        round(Number(parcel._value || 0) * Number(parcel._quantity))
      ),
    0
  );
  return getLiabilityCoverByValue(insuranceConfig, totalValue);
};
