import { HttpStatusCode } from 'axios';
import { get } from 'lodash';

import { STRINGS } from '../constants';
import { StringUtil } from '.';

export const isNotFoundError = error =>
  get(error, 'statusCode') === HttpStatusCode.NotFound;

export const isBadRequestError = error =>
  get(error, 'statusCode') === HttpStatusCode.BadRequest;

export const formatParcelError = error => ({
  ...error.response.data.error,
  message: `${STRINGS.PARCEL_NUMBER_IS_NOT_VALID} ${StringUtil.formatMessage(
    STRINGS.PLEASE_ENTER_VALID_$,
    'value'
  )}.`,
});
