import { get } from 'lodash';
import { ShipmentHelpers } from '../../../../helpers';
import { FORM, SHIPMENT, STRINGS } from '../../../../constants';
import { StringUtil } from '../../../../utils';
import { MAX_NO_OF_PRODUCTS, MAX_VALUE_OF_PRODUCTS } from './constants';

export const calculateTotalWeight = product => {
  const { productQty, unitWeight } = product;
  const quantity = Number(productQty) || 0;
  const weight = Number(unitWeight) || 0;

  return weight * quantity;
};

export const calculateTotalValue = product => {
  const { productQty, unitValue } = product;
  const quantity = Number(productQty) || 0;
  const value = Number(unitValue) || 0;

  return value * quantity;
};

export const calculateTotal = (products = []) =>
  products?.reduce(
    (acc, product) => {
      const quantity = Number(product.productQty) || 0;
      const totalWeight = calculateTotalWeight(product);
      const totalValue = calculateTotalValue(product);
      return {
        quantity: acc.quantity + quantity,
        weight: acc.weight + totalWeight,
        value: acc.value + totalValue,
      };
    },
    {
      quantity: 0,
      weight: 0,
      value: 0,
    }
  );

export const getProductAvailableFields = values => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
  const isDescriptionOnly = ShipmentHelpers.isFlagDescription(
    saebiFlags,
    FORM.NETWORK_FIELDS.PROD_REQUIRED
  );

  return {
    isDescriptionOnly,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY]:
      !isDescriptionOnly &&
      !ShipmentHelpers.isFlagNeedles(
        saebiFlags,
        FORM.NETWORK_FIELDS.COUNTRY_OF_ORIGIN
      ),
  };
};

export const isCommodityRequired = saebiFlags =>
  [SHIPMENT.REQUIRED_TYPE.MANDATORY, SHIPMENT.REQUIRED_TYPE.OPTIONAL].includes(
    get(saebiFlags, FORM.NETWORK_FIELDS.PROD_REQUIRED)
  ) &&
  ShipmentHelpers.isFlagRequired(
    saebiFlags,
    FORM.NETWORK_FIELDS.COMMODITY_REQUIRED
  );

export const getProductRequiredFields = values => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(values);

  if (
    !ShipmentHelpers.isFlagRequired(
      saebiFlags,
      FORM.NETWORK_FIELDS.PROD_REQUIRED
    )
  ) {
    return {};
  }

  if (
    ShipmentHelpers.isFlagDescription(
      saebiFlags,
      FORM.NETWORK_FIELDS.PROD_REQUIRED
    )
  ) {
    return { [FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY]: true };
  }

  return {
    [FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY]:
      isCommodityRequired(saebiFlags),
    [FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY]: true,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY]:
      ShipmentHelpers.isFlagRequired(
        saebiFlags,
        FORM.NETWORK_FIELDS.COUNTRY_OF_ORIGIN
      ),
  };
};

export const getProductDataErrors = (currentProduct, products, totalWeight) => {
  const errors = {};
  const productQuantity = Number(currentProduct.productQty) || 0;
  const productWeight = calculateTotalWeight(currentProduct);
  const productValue = calculateTotalValue(currentProduct);
  const restOfProducts = products.filter(
    product => product.id !== currentProduct.id
  );
  const total = calculateTotal(restOfProducts);

  if (
    totalWeight &&
    productWeight &&
    productWeight + total.weight > totalWeight
  ) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY] =
      STRINGS.SHIPMENT_TOTAL_WEIGHT_ERROR;
  }

  if (
    productQuantity &&
    productQuantity + total.quantity > MAX_NO_OF_PRODUCTS
  ) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY] =
      StringUtil.formatMessage(
        STRINGS.SHIPMENT_TOTAL_NUMBER_ERROR,
        MAX_NO_OF_PRODUCTS
      );
  }

  if (productValue && productValue + total.value > MAX_VALUE_OF_PRODUCTS) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY] =
      StringUtil.formatMessage(
        STRINGS.SHIPMENT_TOTAL_VALUE_ERROR,
        MAX_VALUE_OF_PRODUCTS
      );
  }

  return errors;
};

export const getProductRowBgColor = (theme, isSelected, isInvalid) => {
  switch (true) {
    case isInvalid && isSelected:
      return theme.palette.primary._states.focusVisible;
    case isInvalid:
      return theme.palette.primary._states.selected;
    case isSelected:
      return theme.palette.primary.blue;
    default:
      return 'inherit';
  }
};
