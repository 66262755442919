import apis from './apis';

export const getCommodities = async args => {
  const response = await apis.get('references/commodities', {
    params: {
      ...args,
    },
  });

  return response;
};

export const getCommoditiesByCode = async args => {
  const response = await apis.get('references/commodities/code', {
    params: {
      ...args,
    },
  });

  return response;
};
