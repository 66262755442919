import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CloseOutlined } from '@mui/icons-material';
import {
  BACK,
  ENSURE_OUR_SERVICES,
  FOR_YOUR_PARCEL_PREFERENCES,
  HOW_WE_USE_YOUR_DATA,
  I_ACCEPT_TERMS,
  NEVER_SELL_YOUR_DATA,
  NEVER_USE_YOUR_DATA_TO,
  NEXT,
  NO_KEEP_DATA_LONGER_THAN_NEEDED,
  NO_SEND_MARKETING_INFO,
  TO_KEEP_YOU_INFORMED,
} from '../../../constants/strings';
import { SIGN_IN_WITH_EMAIL } from '../../../constants/routes';
import { AuthModel, useAuth } from '../../../features/Auth';
import { VerifyEmailSlice } from '../SignIn/slice';

const TermsAndConditions = ({ nextStep }) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuth();

  const handleChange = useCallback(
    event => {
      setTermsChecked(event.target.checked);
    },
    [setTermsChecked]
  );

  const handleBack = useCallback(() => {
    dispatch(VerifyEmailSlice.actions.clearAll());
    navigate(`${SIGN_IN_WITH_EMAIL}?step=enter`, { replace: true });
  }, [navigate, dispatch]);

  const handleNext = useCallback(async () => {
    if (!AuthModel.isDpdUserAccount(auth.currentSession)) {
      nextStep();
    } else {
      navigate('/', { replace: true });
    }
  }, [nextStep, auth.currentSession]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h5'>{HOW_WE_USE_YOUR_DATA}</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CheckCircleOutlineIcon color='success' />
          </ListItemIcon>
          <ListItemText primary={TO_KEEP_YOU_INFORMED} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircleOutlineIcon color='success' />
          </ListItemIcon>
          <ListItemText primary={ENSURE_OUR_SERVICES} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircleOutlineIcon color='success' />
          </ListItemIcon>
          <ListItemText primary={FOR_YOUR_PARCEL_PREFERENCES} />
        </ListItem>
      </List>
      <Typography variant='h5'>{NEVER_USE_YOUR_DATA_TO}</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CloseOutlined color='error' />
          </ListItemIcon>
          <ListItemText primary={NEVER_SELL_YOUR_DATA} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CloseOutlined color='error' />
          </ListItemIcon>
          <ListItemText primary={NO_KEEP_DATA_LONGER_THAN_NEEDED} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CloseOutlined color='error' />
          </ListItemIcon>
          <ListItemText primary={NO_SEND_MARKETING_INFO} />
        </ListItem>
      </List>
      <Box>
        <Typography sx={{ display: 'inline' }}>Please see our </Typography>
        <Link href='#' underline='none' sx={{ display: 'inline' }}>
          Privacy Policy
        </Link>
        <Typography sx={{ display: 'inline' }}> and </Typography>
        <Link href='#' underline='none' sx={{ display: 'inline' }}>
          Terms & Conditions
        </Link>
        <Typography sx={{ display: 'inline' }}>
          {' '}
          for more details on how your personal data will be used.
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={handleChange}
            />
          }
          label={I_ACCEPT_TERMS}
        />
      </Box>
      <Box
        sx={{
          mt: 4,
          alignSelf: 'flex-end',
        }}
      >
        <Button
          sx={{
            alignSelf: 'flex-end',
          }}
          variant='outlined'
          color='inherit'
          onClick={handleBack}
        >
          {BACK}
        </Button>
        <Button
          sx={{
            ml: 2,
            alignSelf: 'flex-end',
          }}
          type='submit'
          variant='contained'
          disabled={!termsChecked}
          onClick={handleNext}
        >
          {NEXT}
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
