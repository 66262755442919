import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { OrderSliceSelectors } from '../../slice';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';

export const getBillingAddress = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const collectionLocality = get(
      values,
      SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY
    );
    return {
      phoneNumber: get(
        values,
        SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY
      ),
      streetAddress: get(values, SHIPMENT_FIELDS.COLLECTION_STREET.KEY),
      extendedAddress: collectionLocality, // ?
      locality: collectionLocality,
      region: get(values, SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY),
      postalCode: get(values, SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY),
      countryCodeAlpha2: get(values, SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
    };
  }
);

export const getBillingEmail = createSelector(
  [OrderSliceSelectors.getValues],
  values => get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY)
);

export const getBillingMobilePhone = createSelector(
  [OrderSliceSelectors.getValues],
  values => get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY)
);

export const getThreeDSecureData = createStructuredSelector({
  email: getBillingEmail,
  mobilePhoneNumber: getBillingMobilePhone,
  billingAddress: getBillingAddress,
});
