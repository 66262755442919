import { get } from 'lodash';

export const getBasketItemsWithPrice = (
  basketItemsIds = [],
  basketItems = [],
  price = {}
) =>
  basketItemsIds.map(basketItemId => {
    const basketItem = basketItems.find(
      basketItem => basketItem.basketItemId === basketItemId
    );
    const basketItemPrice = get(price, `items[${basketItemId}]`, {});

    return {
      ...basketItem,
      price: basketItemPrice,
    };
  });
