import { useLocation, useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { InfoOutlined, NavigateBefore } from '@mui/icons-material';
import { HttpStatusCode } from 'axios';
import Loader from '../../features/Loader';
import { shipmentsApis } from '../../apis';
import Error from '../Auth/SignIn/Error';
import { BASKET } from '../../constants/analytics';
import {
  CONFIRMATION,
  FETCHING_ERROR,
  FILE_FORMAT,
  ORDER_SUMMARY,
  VIEW_NEARBY_PICKUPS,
} from '../../constants/strings';
import { SHIPMENT_FIELDS } from '../../constants/forms';
import PickupModal from '../../components/PickupModal';
import { getBarcode } from '../../apis/barcodes';
import ShareModal from '../../components/ShareModal/ShareModal';
import useErrorHandler from '../../hooks/useErrorHandler';
import { InvoiceHelpers } from '../../helpers';
import { getOrderInvoiceDownloadUrl } from '../UserPreferences/OrdersSection/utils/downloadUrl';
import { TimeUtil } from '../../utils';
import { DONT_HAVE_ACCESS } from '../../constants/routes';
import { getParcelToContactTitle } from './utils/shipment';
import { getShipmentLabelsDownloadUrl } from './utils/downloadUrl';
import Collection from './Collection';
import DropOff from './DropOff';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={actionMenuBtnHandleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

const getParcelCodeFromParcelNumber = shipment =>
  `${
    get(shipment, `${SHIPMENT_FIELDS.PARCELS.KEY}[0]`)?.parcelNumber
  }*${TimeUtil.convertToInternalDate(shipment?.createdDate)}`;

const Shipment = () => {
  const { shipmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showBackButton, setShowBackButton] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [shipment, setShipment] = useState(null);
  const theme = useTheme();
  const { errorHandler } = useErrorHandler();
  const title = getParcelToContactTitle(shipment);

  useEffect(() => {
    if (location.state?.fromOrderDetails) {
      setShowBackButton(true);
    }
  }, [location]);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePickupPointChange = point => {
    setSelectedPickupPoint(point);
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const actionEditBtnHandleClick = event => {
    setActionMenuAnchorEl(event.currentTarget);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getTitleText = dropAtShop => {
    if (!dropAtShop) {
      return CONFIRMATION.HOME_COLLECTION_INSTRUCTIONS;
    }
    return selectedTab === 0
      ? CONFIRMATION.PARCEL_PASS_LABEL
      : CONFIRMATION.HOME_COLLECTION_INSTRUCTIONS;
  };

  const dropAtShop = get(shipment, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);

  const loadData = useCallback(async () => {
    try {
      const shipmentData = await shipmentsApis.getShipmentById(shipmentId);

      if (get(shipmentData, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY)) {
        const barcodeData = await getBarcode(shipmentId);
        setBarcode(barcodeData?.barcodes);
      }

      setShipment(shipmentData);
    } catch (error) {
      errorHandler(FETCHING_ERROR, error);
      if (error?.status === HttpStatusCode.Forbidden) {
        navigate(DONT_HAVE_ACCESS);
      }
    }
  }, [shipmentId]);

  const handleDownloadInvoice = format => {
    // Invoice contains all of the related parcels, to generate the invoice we need to provide one of these parcel numbers
    const parcelNumber = get(
      shipment,
      `${SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelNumber`
    );

    if (parcelNumber) {
      const url = getOrderInvoiceDownloadUrl(parcelNumber, format);
      window.open(url, '_blank');
      actionMenuBtnHandleClose();
    }
  };

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Loader
        promiseFn={loadData}
        errorComponent={error => <Error values={{ error }} />}
        triggerOnMount={true}
        triggerOnCondition={() => false}
      >
        <Grid sx={{ background: theme.palette.primary.pageBackground, pb: 4 }}>
          <Grid container alignItems='center' justifyContent='center'>
            <Grid
              alignItems='center'
              justifyContent='center'
              spacing={2}
              sx={{ mt: 0 }}
            >
              <Grid item xs={12} md={12}>
                <Grid
                  sx={{
                    m: 4,
                    marginX: 0,
                    paddingX: 2,
                    maxWidth: 400,
                  }}
                  item
                >
                  {showBackButton && (
                    <Button
                      onClick={handleBack}
                      sx={{ mb: 2 }}
                      variant='outlined'
                      startIcon={<NavigateBefore />}
                    >
                      {ORDER_SUMMARY}
                    </Button>
                  )}
                  <Typography variant='body1'>
                    {CONFIRMATION.THANK_YOU}
                  </Typography>
                  <Typography
                    variant='h2'
                    sx={{ mt: 1, overflowWrap: 'anywhere' }}
                  >
                    {title}
                  </Typography>
                </Grid>
                {!!shipment?.invoice && (
                  <>
                    <Box
                      sx={{
                        background: theme.palette.primary.pink,
                        width: '400px',
                        p: 1,
                        border: `1px solid ${theme.palette.primary.light}`,
                        borderRadius: '5px',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <InfoOutlined />
                        <Box>
                          <Typography variant='body2'>
                            {CONFIRMATION.INTERNATIONAL.INFO_TITLE}
                          </Typography>
                          <Typography variant='body2'>
                            {CONFIRMATION.INTERNATIONAL.INFO_DESCRIPTION}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      variant='contained'
                      sx={{ width: '400px', mt: 1, mb: 2 }}
                      href={InvoiceHelpers.getShipmentInvoiceDownloadUrl(
                        shipmentId
                      )}
                    >
                      {CONFIRMATION.INTERNATIONAL.CREATE_PROFORMA_INVOICE}
                    </Button>
                  </>
                )}
                <Grid sx={{ mt: 1 }}>
                  <Paper sx={{ p: 0, maxWidth: '400px' }}>
                    <Box
                      sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h3'>
                        {getTitleText(dropAtShop)}
                      </Typography>
                      <ActionMenuBtn
                        actionMenuBtnHandleClick={actionEditBtnHandleClick}
                      />
                    </Box>
                    <Menu
                      anchorEl={actionMenuAnchorEl}
                      open={!!actionMenuAnchorEl}
                      onClose={actionMenuBtnHandleClose}
                      MenuListProps={{ sx: { width: '154px' } }}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 120,
                        vertical: 90,
                      }}
                    >
                      <StyledMenuItem
                        disableRipple
                        theme={theme}
                        onClick={() => handleDownloadInvoice(FILE_FORMAT.PDF)}
                      >
                        {CONFIRMATION.DOWNLOAD_INVOICE}
                      </StyledMenuItem>
                      <StyledMenuItem
                        disableRipple
                        theme={theme}
                        onClick={() => {
                          handleOpenShareModal();
                          actionMenuBtnHandleClose();
                        }}
                      >
                        {CONFIRMATION.SHARE_PARCEL_INFO}
                      </StyledMenuItem>
                    </Menu>
                    {dropAtShop && (
                      <Box
                        sx={{
                          mt: 0,
                          mb: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Tabs
                          sx={{ width: '100%' }}
                          value={selectedTab}
                          onChange={handleTabChange}
                          TabIndicatorProps={{
                            sx: {
                              backgroundColor: theme.palette.primary.main,
                              height: '2px',
                            },
                          }}
                        >
                          <Tab
                            sx={{ width: '50%' }}
                            label={CONFIRMATION.VIEW_QR_CODE}
                          />
                          <Tab
                            sx={{ width: '50%' }}
                            label={CONFIRMATION.VIEW_LABEL}
                          />
                        </Tabs>
                      </Box>
                    )}
                    <Divider />
                    <Box sx={{ p: 2 }}>
                      {dropAtShop && selectedTab === 0 ? (
                        <DropOff
                          shipment={shipment}
                          shipmentId={shipmentId}
                          barcode={barcode}
                        />
                      ) : (
                        <Collection
                          shipment={shipment}
                          dropAtShop={dropAtShop}
                        />
                      )}
                    </Box>
                    <Divider />
                    <Box
                      sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}
                    >
                      <Button
                        target='_blank'
                        href={getShipmentLabelsDownloadUrl(shipmentId)}
                        sx={{ mx: 1, mb: 0 }}
                        variant='contained'
                      >
                        {CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                      </Button>
                      {dropAtShop && (
                        <PickupModal
                          label={VIEW_NEARBY_PICKUPS}
                          id='pickup-modal'
                          variant='outlined'
                          disabled={false}
                          style={{ mt: 0 }}
                          selectedPickupPoint={selectedPickupPoint}
                          onChange={handlePickupPointChange}
                          showSelectButton={false}
                        />
                      )}
                      <Button
                        href='/order'
                        sx={{
                          mx: 1,
                          mb: 2,
                          mt: 1,
                          color: theme.palette.primary.main,
                        }}
                        variant={dropAtShop ? 'none' : 'outlined'}
                      >
                        {CONFIRMATION.SEND_ANOTHER_PARCEL}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                <ShareModal
                  parcel={{
                    parcelCode: getParcelCodeFromParcelNumber(shipment),
                  }}
                  open={openShareModal}
                  onClose={() => setOpenShareModal(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Loader>
    </Trackable>
  );
};

export default Shipment;
