import { SESSION_TYPE } from './constants';

export const isPhoneLinkedAccount = session =>
  session.decodedIdToken?.firebase?.identities?.['phone'];

export const isMagicLinkAccountSession = session =>
  session.decodedIdToken?.firebase?.sign_in_provider === 'custom' &&
  // eslint-disable-next-line no-prototype-builtins
  session.decodedIdToken?.firebase?.identities?.hasOwnProperty('email');

export const isEmailAccountVerified = (session, email) => {
  const isMagicLinkAccount = isMagicLinkAccountSession(session);
  if (isMagicLinkAccount) {
    const matchEmail =
      session.decodedIdToken?.firebase?.identities?.email?.find(
        e => e === email
      );

    return !!matchEmail;
  }

  return false;
};

export const isDpdUserAccount = session =>
  !!session?.decodedIdToken?.dpdonline_user_id;

export const isConsumerAccount = session =>
  !!session?.decodedIdToken?.consumerId;

export const getSessionType = session => {
  if (!session) {
    return SESSION_TYPE.GUEST;
  }
  if (isConsumerAccount(session)) {
    return SESSION_TYPE.CONSUMER_ACCOUNT;
  } else if (isPhoneLinkedAccount(session)) {
    return SESSION_TYPE.PHONE_ACCOUNT;
  } else {
    return SESSION_TYPE.ACCOUNT;
  }
};

/*
find first not phone provider & consider as initial signup provider
@example: {
  "identities": {
    "google.com": [
      "11111111111111"
    ],
    "phone": [
      "+447000000000"
    ]
  },
  "sign_in_provider": "custom"
}
 */
export const getSignUpInitialProviderId = session =>
  Object.keys(session.decodedIdToken?.firebase?.identities).find(
    p => p !== 'phone'
  );
