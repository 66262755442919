import { SvgIcon } from '@mui/material';

const Mastercard = props => (
  <SvgIcon {...props} viewBox='0 0 24 16'>
    <path
      d='M15.0921 2.44362H8.90816V13.5562H15.0921V2.44362Z'
      fill='#FF5F00'
    />
    <path
      d='M9.30084 7.99993C9.29986 6.9297 9.54238 5.87328 10.01 4.91063C10.4777 3.94799 11.1582 3.10436 12.0001 2.44361C10.9576 1.62412 9.70546 1.1145 8.38694 0.972981C7.06842 0.831463 5.73669 1.06376 4.54396 1.64333C3.35123 2.2229 2.34561 3.12636 1.64207 4.25043C0.93852 5.3745 0.56543 6.67384 0.56543 7.99993C0.56543 9.32601 0.93852 10.6254 1.64207 11.7494C2.34561 12.8735 3.35123 13.777 4.54396 14.3565C5.73669 14.9361 7.06842 15.1684 8.38694 15.0269C9.70546 14.8854 10.9576 14.3757 12.0001 13.5562C11.1582 12.8955 10.4777 12.0519 10.01 11.0892C9.54239 10.1266 9.29987 9.07015 9.30084 7.99993Z'
      fill='#EB001B'
    />
    <path
      d='M23.4345 7.99992C23.4345 9.32599 23.0615 10.6253 22.358 11.7494C21.6545 12.8735 20.6489 13.7769 19.4562 14.3565C18.2635 14.9361 16.9317 15.1684 15.6133 15.0269C14.2948 14.8854 13.0427 14.3757 12.0001 13.5562C12.8413 12.8948 13.5213 12.0511 13.9889 11.0886C14.4565 10.1261 14.6994 9.06998 14.6994 7.99992C14.6994 6.92987 14.4565 5.87378 13.9889 4.91128C13.5213 3.94879 12.8413 3.10503 12.0001 2.44361C13.0427 1.62412 14.2948 1.11449 15.6133 0.972979C16.9317 0.831463 18.2635 1.06377 19.4562 1.64334C20.6489 2.22292 21.6545 3.12638 22.358 4.25045C23.0615 5.37452 23.4345 6.67385 23.4345 7.99992Z'
      fill='#F79E1B'
    />
    <path
      d='M22.7602 12.3792V12.1517H22.852V12.1054H22.6183V12.1517H22.7101V12.3792H22.7602ZM23.2138 12.3792V12.1049H23.1422L23.0598 12.2936L22.9774 12.1049H22.9058V12.3792H22.9563V12.1723L23.0336 12.3507H23.086L23.1633 12.1719V12.3792H23.2138Z'
      fill='#F79E1B'
    />
  </SvgIcon>
);

export default Mastercard;
