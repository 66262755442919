import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  HOME_SIZES_HEADING,
  HOME_SIZES_TEXT,
  LOCKER_SIZES_HEADING,
  LOCKER_SIZES_TEXT,
  MAX_PARCEL_SIZES,
  SHOP_SIZES_HEADING,
  SHOP_SIZES_TEXT,
} from '../../constants/strings';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: 1,
}));

const MaxParcelDimsModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{MAX_PARCEL_SIZES}</DialogTitle>
    <IconButton
      aria-label='close'
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500],
      }}
    >
      <CloseOutlined />
    </IconButton>
    <DialogContent sx={{ py: 1 }}>
      <StyledTypography variant='subtitle2'>
        {SHOP_SIZES_HEADING}
      </StyledTypography>
      <Typography variant='body1' sx={{ mb: 1 }}>
        {SHOP_SIZES_TEXT}
      </Typography>
      <StyledTypography variant='subtitle2'>
        {LOCKER_SIZES_HEADING}
      </StyledTypography>
      <Typography variant='body1' sx={{ mb: 1 }}>
        {LOCKER_SIZES_TEXT}
      </Typography>
      <StyledTypography variant='subtitle2'>
        {HOME_SIZES_HEADING}
      </StyledTypography>
      <Typography variant='body1' sx={{ mb: 1 }}>
        {HOME_SIZES_TEXT}
      </Typography>
    </DialogContent>
  </Dialog>
);

MaxParcelDimsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MaxParcelDimsModal;
