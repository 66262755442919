import { createReducer } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { AuthActions } from '../../features/Auth';
import {
  checkout,
  createBasket,
  createBasketItem,
  deleteBasketItem,
  duplicateBasketItem,
  fetchBasketItem,
  getBasketItems,
  updateBasketItem,
} from './actions';

const initialState = {
  basketId: null,
  items: [],
};

const basketReducer = createReducer(initialState, builder => {
  const handleUpdateBasketItem = (state, action) => {
    const updatedItem = action.payload;
    const index = state.items.findIndex(
      item => item.basketItemId === updatedItem.basketItemId
    );

    if (index !== -1) {
      state.items[index] = updatedItem; // Update the item in the array
    }
  };

  const handleUpdateBasketId = (state, action) => {
    if (state.basketId !== action.payload?.basketId) {
      state.basketId = action.payload?.basketId;
      state.items = [];
    }
  };

  builder
    .addCase(AuthActions.getCurrentSession.fulfilled, handleUpdateBasketId)
    .addCase(AuthActions.createSession.fulfilled, handleUpdateBasketId)
    .addCase(createBasket.fulfilled, (state, action) => {
      state.basketId = action.payload?.basketId;
      state.items = [];
    })
    .addCase(getBasketItems.fulfilled, (state, action) => {
      state.items = action.payload;
    })
    .addCase(createBasketItem.fulfilled, (state, action) => {
      state.items = state.items.concat(action.payload);
    })
    .addCase(duplicateBasketItem.fulfilled, (state, action) => {
      state.items = state.items.concat(action.payload);
    })
    .addCase(fetchBasketItem.fulfilled, handleUpdateBasketItem)
    .addCase(updateBasketItem.fulfilled, handleUpdateBasketItem)
    .addCase(deleteBasketItem.fulfilled, (state, action) => {
      const index = findIndex(state.items, {
        basketItemId: action.payload?.basketItemId,
      });
      state.items.splice(index, 1);
      return state;
    })
    .addCase(checkout.fulfilled, (state, { payload }) => {
      payload.shipments.forEach(shipment => {
        const index = findIndex(state.items, {
          basketItemId: shipment.basketItemId,
        });
        state.items.splice(index, 1);
      });
      return state;
    });
});

export default basketReducer;
