import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { referenceApis } from '../apis';

const initialState = {
  data: [],
};

const fetchExportReasons = createAsyncThunk('exportReasons/fetch', async () => {
  const response = await referenceApis.fetchExportReasons();
  return response.data;
});

const exportReasonsSlice = createSlice({
  name: 'exportReasons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchExportReasons.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchExportReasons.rejected, state => {
      state.data = [];
    });
  },
});

const getExportReasonsState = state => state.exportReasons || [];

const getExportReasons = createSelector([getExportReasonsState], ({ data }) =>
  data?.length ? data : []
);

export const ExportReasonsReducer = exportReasonsSlice.reducer;

export const ExportReasonsActions = {
  fetchExportReasons,
};

export const ExportReasonsSelectors = {
  getExportReasons,
};
