// NOTE: export reason mapper
// export const DEFAULT_REASONS_KEY_VALUE = [
//   {
//     label: 'Sale',
//     value: '01',
//   },
//   {
//     label: 'Gift',
//     value: '03',
//   },
// ];

export const DEFAULT_REASONS = ['01', '03'];

export const DELIVERED_AT_PLACE = 'DAP';

// NOTE: invoice types mapper
// export const INVOICE_TYPE = {
//   PROFORMA: 1,
//   COMMERCIAL: 2,
// };

export const INVOICE_TYPES = {
  '03': 1,
  '01': 2,
};
