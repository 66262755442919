import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import { CLOSE, PROHIBITED_MODAL } from '../../constants/strings';
import {
  LISTS_WITH_PROHIBITED_ITEMS,
  LISTS_WITH_SPECIAL_PROVISIONS_ITEMS,
} from '../SalesConditions/constants';

const ProhibitedModal = ({ isOpen, onClose }) => {
  const theme = useTheme();

  return (
    <Modal
      open={isOpen}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
      }}
    >
      <Card
        variant='elevation'
        sx={{
          borderRadius: '8px',
          maxWidth: '500px',
          width: '100%',
          outline: 'none',
        }}
      >
        <CardHeader
          title={
            <Typography variant='h3' sx={{ textTransform: 'capitalize' }}>
              {PROHIBITED_MODAL.PROHIBITED_ITEMS}
            </Typography>
          }
        ></CardHeader>
        <Divider />
        <CardContent>
          <Stack
            direction='column'
            spacing={2}
            sx={{ color: theme.palette.info.main }}
          >
            <Typography variant='body2' sx={{ fontSize: '12px' }}>
              {PROHIBITED_MODAL.PROHIBITED_ITEMS_DESCRIPTION}
            </Typography>
            <Typography variant='body2' sx={{ fontSize: '12px' }}>
              {PROHIBITED_MODAL.ORDER_CANCELLATION_WARNING}
            </Typography>
            <List
              sx={{
                pb: 0,
                overflow: 'auto',
                maxHeight: { md: 'auto', xs: '260px' },
              }}
            >
              {LISTS_WITH_PROHIBITED_ITEMS.map((item, index) => (
                <ListItem key={index} sx={{ padding: 0, fontSize: '12px' }}>
                  • {item}
                </ListItem>
              ))}
              <Typography
                variant='text'
                sx={{
                  color: theme.palette.primary.main,
                  display: 'block',
                  py: 1,
                }}
              >
                {PROHIBITED_MODAL.SPECIAL_PROVISIONS}
              </Typography>
              {LISTS_WITH_SPECIAL_PROVISIONS_ITEMS.map((item, index) => (
                <ListItem key={index} sx={{ padding: 0, fontSize: '12px' }}>
                  • {item}
                </ListItem>
              ))}
            </List>
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            px: 2,
            pt: 1,
            pb: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant='outlined'
            onClick={onClose}
            sx={{
              padding: '6px 24px',
            }}
          >
            {CLOSE}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

ProhibitedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProhibitedModal;
