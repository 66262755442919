import { createAsyncThunk } from '@reduxjs/toolkit';

import { retrieve } from '../../apis/address';
import { AuthActions, AuthSelectors } from '../../features/Auth';
import { BasketActions } from '../../redux/basket';
import { ObjectUtil } from '../../utils';
import addressBooksSlice from '../../redux/addressBooksSlice';
import * as QuotesSelector from './selectors';

export const load = createAsyncThunk(
  'quotes/load',
  async (
    { deliveryCountryCode, deliveryAddressKey },
    { dispatch, getState }
  ) => {
    let deliveryAddress;
    let collectionDetailedAddress;

    if (deliveryAddressKey && deliveryCountryCode) {
      deliveryAddress = await retrieve({
        key: deliveryAddressKey,
        format: 'gcp',
        countryISO: deliveryCountryCode,
      });
    }

    const currentSession = AuthSelectors.getCurrentSession(getState());

    if (!currentSession?.basketId) {
      await dispatch(BasketActions.createBasket()).unwrap();
      // need to refresh session straight after basket creation
      await dispatch(AuthActions.getCurrentSession()).unwrap();
    }

    const defaultAddressBook =
      addressBooksSlice.selectors.getDefaultAddressBook(getState());
    if (defaultAddressBook) {
      collectionDetailedAddress = defaultAddressBook;
    }

    const shipmentDto = QuotesSelector.getShipmentDto(getState(), {
      deliveryCountryCode,
      deliveryAddress,
      collectionDetailedAddress,
    });

    return ObjectUtil.convertToNestedObject(shipmentDto);
  }
);
