import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import useAnalytics from '../../utils/useAnalytics';
import { BASKET } from '../../constants/analytics';
import { CONFIRMATION } from '../../constants/strings';
import ShareModal from '../../components/ShareModal/ShareModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import ParcelDisplay from '../../components/ParcelDisplay/ParcelDisplay';
import getMobileOS from '../../utils/getMobileOS';

const DropOff = ({ pickupPoint, barcode, shipment, shipmentId }) => {
  const theme = useTheme();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [fetchedBarcode, setFetchedBarcode] = useState(barcode);
  const { setInterfaceId, Trackable } = useAnalytics();
  const isSmallScreen = useBreakpoint('sm');
  const os = getMobileOS();

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, [setInterfaceId]);

  useEffect(() => {
    if (barcode) {
      setFetchedBarcode(barcode);
    }
  }, [barcode]);

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.contrastText, pb: 4 }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            width='100%'
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12} sx={{ width: '100%' }}>
              <Grid
                sx={{
                  mt: 1,
                  position: 'relative',
                  p: 0,
                  maxWidth: isSmallScreen ? '100vw' : '400px',
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    py: 2,
                    textAlign: 'center',
                    color: theme.palette.primary.iconGray,
                  }}
                >
                  <Typography variant='body1'>{CONFIRMATION.TITLE}</Typography>
                </Box>
                <ParcelDisplay
                  barcode={fetchedBarcode}
                  isSmallScreen={isSmallScreen}
                  os={os}
                  shipment={shipment}
                  shipmentId={shipmentId}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

DropOff.propTypes = {
  shipment: PropTypes.object,
  shipmentId: PropTypes.string,
  pickupPoint: PropTypes.object,
  barcode: PropTypes.string,
};

export default DropOff;
