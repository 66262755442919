import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import { parcelsApis } from '../apis';

const initialState = {
  data: [],
};

const fetchParcels = createAsyncThunk(
  'parcels/fetch',
  async ({ searchPage, searchPageSize }) => {
    const response = await parcelsApis.getParcels({
      searchPage,
      searchPageSize,
    });

    return response.data;
  }
);

const slice = createSlice({
  name: 'parcels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParcels.fulfilled, (state, { payload }) => {
        state.data = payload.parcels;
      })
      .addCase(fetchParcels.rejected, state => {
        state.data = [];
      });
  },
});

const getParcelsState = state => state.parcels || [];

const getParcels = createSelector([getParcelsState], parcels => parcels.data);

export default {
  reducer: slice.reducer,
  actions: {
    fetchParcels,
  },
  selectors: {
    getParcels,
  },
};
