import { Box, Typography } from '@mui/material';

import { ORDER_DETAILS } from '../../../constants/strings';

const Label = ({ children, sx }) => (
  <Typography
    variant='body2'
    color='textSecondary'
    sx={{
      fontFamily: 'Roboto',
      marginY: 1,
      ...sx,
    }}
  >
    {children}
  </Typography>
);

const EventDetails = ({ date, text, color, last, theme }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            border: 2,
            borderColor: color,
            borderRadius: 10,
            mr: 1,
          }}
        />
      </Box>
      <Box>
        <Label sx={{ fontSize: 12, m: 0 }}>
          {date || ORDER_DETAILS.UNKNOWN}
        </Label>
        <Typography
          variant='body1'
          color={'textSecondary'}
          sx={{
            fontFamily: 'Roboto',
            marginY: 0,
            fontWeight: 400,
          }}
        >
          {text || ORDER_DETAILS.UNKNOWN}
        </Typography>
      </Box>
    </Box>
    {last ? (
      <Box
        sx={{ borderBottom: 1, borderColor: theme.palette.grey[200], mt: 2 }}
      />
    ) : (
      <Box
        sx={{
          height: 35,
          borderLeft: 2,
          borderColor: theme.palette.grey[200],
          ml: 0.5,
          marginY: -0.5,
        }}
      />
    )}
  </Box>
);

const EventsSection = ({ parcelEvents, theme }) =>
  parcelEvents?.length > 0 ? (
    <>
      <Label>{ORDER_DETAILS.HISTORY}</Label>
      {parcelEvents.map((event, key) => (
        <EventDetails
          key={key}
          date={event.date}
          text={event.text}
          color={
            theme.palette.parcelStatus[event.status] ||
            theme.palette.parcelStatus['Complete']
          }
          theme={theme}
          last={parcelEvents.length === key + 1}
        />
      ))}
    </>
  ) : null;

export default EventsSection;
