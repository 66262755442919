const ElectricVehicle = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M13.654 18.7145C12.8115 18.7149 12.0544 18.5172 11.53 18.3326C11.4319 18.298 11.3539 18.2222 11.3171 18.1248C11.2804 18.0277 11.2879 17.919 11.3387 17.8283L14.8681 11.4753C14.963 11.3052 15.1776 11.2431 15.3477 11.338C15.5182 11.433 15.5796 11.6476 15.485 11.8177L12.166 17.7937C13.174 18.0753 14.7322 18.2409 16.0497 17.2131C18.071 15.6369 18.8807 11.7115 18.4003 5.84452C10.9819 9.6517 9.66291 12.7692 9.54467 14.3059C9.42503 15.8582 10.3776 16.7112 10.793 17.0059C10.9519 17.1185 10.9893 17.3391 10.8763 17.4983C10.7634 17.6568 10.5435 17.6949 10.384 17.5816C9.29938 16.8115 8.73679 15.5981 8.84056 14.2516C9.0022 12.1548 10.8174 8.84346 18.5482 4.97805C18.6523 4.92652 18.7762 4.92828 18.8775 4.98546C18.9795 5.04193 19.0466 5.14534 19.0579 5.26111C19.6617 11.7584 18.7956 15.9672 16.4835 17.7697C15.5641 18.4869 14.56 18.7145 13.654 18.7145Z'
      fill='#414042'
    />
    <path
      d='M8.11841 16.2352C8.10041 16.2352 8.08205 16.2338 8.0637 16.231C7.08005 16.0774 6.28841 15.5583 5.77452 14.7288C4.61194 12.853 4.81205 9.40508 6.37029 4.48155C6.40523 4.37108 6.49205 4.28461 6.60288 4.25073C6.71299 4.2172 6.83335 4.23908 6.92476 4.31073C8.86947 5.84143 10.3328 7.49038 11.2183 8.6039C11.3397 8.75673 11.3139 8.97873 11.1615 9.09979C11.0083 9.22049 10.7866 9.19543 10.6656 9.04296C9.86582 8.03673 8.58182 6.58226 6.89017 5.18885C5.21088 10.7198 5.67394 13.226 6.37488 14.3572C6.78288 15.0154 7.38782 15.4111 8.17311 15.5336C8.36582 15.5636 8.49746 15.7443 8.46746 15.937C8.43958 16.1106 8.28923 16.2352 8.11841 16.2352Z'
      fill='#DC0032'
    />
  </svg>
);

export default ElectricVehicle;
