import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Pagination,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { CLOSE } from '../../constants/strings';
import notFoundImg from '../../assets/images/image-not-found.png';
import { getImageUrl } from '../../utils/images';

const GalleryWidget = ({ images, parcelNumber, isOpen, onClose, title }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'primary.contrastText',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          overflowY: {
            xs: 'scroll',
            sm: 'auto',
          },
          position: 'relative',
        }}
      >
        <Typography variant='h3' sx={{ p: 2 }}>
          {title}
        </Typography>
        <IconButton
          aria-label='close'
          sx={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
        >
          <Close onClick={onClose} />
        </IconButton>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box
            component='img'
            width='100%'
            src={getImageUrl({ ...images[currentImageIndex], parcelNumber })}
            onError={e => (e.target.src = notFoundImg)}
            sx={{ borderRadius: 1 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
            <Pagination
              page={currentImageIndex + 1}
              count={images.length}
              siblingCount={0}
              shape='rounded'
              showFirstButton
              showLastButton
              onChange={(_, imageIndex) => {
                setCurrentImageIndex(imageIndex - 1);
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingX: 2,
            pb: 2,
          }}
        >
          <Button onClick={onClose} variant='contained' color='primary'>
            {CLOSE}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

GalleryWidget.propTypes = {
  title: PropTypes.string,
  parcelNumber: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageKey: PropTypes.string,
      imageType: PropTypes.string,
      imageCaption: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default GalleryWidget;
