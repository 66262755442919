import { add, filter, get, max, min, multiply } from 'lodash';
import { SHIPMENT_FIELDS } from '../constants/forms';
import {
  getBasePriceForNetwork,
  getDefaultNetwork,
  getDriverSurchargePrice,
  getMinPriceNetworks,
  getTotalPrice,
} from './networks-helper';

/**
 *
 * @param {Array} networks - Array of networks
 * @param {number} shipment - Shipment
 * @returns {Array} - Available networks based on number of parcels and their weights
 */
export const getNetworksByParcelSizeAndWeight = (networks = [], shipment) =>
  networks.filter(({ networkDetails }) => {
    const parcels = get(shipment, SHIPMENT_FIELDS.PARCELS.KEY, []);
    return (
      parcels.every(
        ({ _weight }) => _weight <= networkDetails?.maxWeight / 1000
      ) &&
      get(shipment, SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY) <=
        networkDetails?.maxParcels
    );
  });

/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Boolean indicator of whether parcel goes to shop
 * @returns {Array} - Sorted array of networks by price based on whether parcel goes to shop
 */
export const sortNetworksByPrice = networks =>
  networks.sort((prev, curr) => prev?.price?.baseRate - curr?.price?.baseRate);

export const getParcelsTotalWeight = (shipment, customParcels = []) => {
  const parcels = customParcels.length
    ? customParcels
    : get(shipment, SHIPMENT_FIELDS.PARCELS.KEY);

  return parcels.reduce(
    (acc, curr) =>
      add(
        acc,
        multiply(
          Number(curr._weight || 0),
          Number.parseInt(curr._quantity || 0)
        )
      ),
    0
  );
};

export const getParcelTotalQuantity = (parcels = []) =>
  parcels.reduce(
    (acc, curr) => add(acc, Number.parseInt(curr._quantity || 0)),
    0
  );

/**
 *
 * @param {Array} networks - List of networks
 * @returns Maximum weight and parcel number for networks
 */
export const getMaxWeightAndParcelNumberFromNetworks = (networks = []) =>
  networks.reduce((acc, curr) => {
    let maxWeight = acc.maxWeight;
    let maxParcelNumber = acc.maxParcelNumber;

    if (curr.networkDetails?.maxWeight) {
      maxWeight = max([maxWeight, curr.networkDetails?.maxWeight / 1000]);
    }
    if (curr.networkDetails?.maxParcels) {
      maxParcelNumber = max([maxParcelNumber, curr.networkDetails?.maxParcels]);
    }

    return { maxWeight, maxParcelNumber };
  }, {});

/**
 *

 * @param {boolean} dropAtShop
 * @param {object} param
 * @param {Array} param.networks - List of networks
 * @param {object} param.collectionPickup - Collection pickup point
 * @param {object} param.deliveryPickup - Delivery pickup point
 * @param {boolean} param. - Boolean indicator of whether parcel goes to shop
 * @returns
 */
export const getMaxWeightAndParcelNumber = (
  dropAtShop,
  { networks, collectionPickup, deliveryPickup }
) => {
  const collectionPickupMaxWeight =
    get(collectionPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const deliveryPickupMaxWeight =
    get(deliveryPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const collectionPickupMaxQuantity = get(
    collectionPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const deliveryPickupMaxQuantity = get(
    deliveryPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const { maxWeight, maxParcelNumber } =
    getMaxWeightAndParcelNumberFromNetworks(networks);

  let minMaxWeight = maxWeight;
  let minMaxParcelNumber = maxParcelNumber;

  if (dropAtShop) {
    minMaxWeight = min([
      collectionPickupMaxWeight || minMaxWeight,
      deliveryPickupMaxWeight || minMaxWeight,
      minMaxWeight,
    ]);
    minMaxParcelNumber = min([
      collectionPickupMaxQuantity || minMaxParcelNumber,
      deliveryPickupMaxQuantity || minMaxParcelNumber,
      minMaxParcelNumber,
    ]);
  }

  return {
    maxWeight: minMaxWeight || 30,
    maxParcelNumber: minMaxParcelNumber || 99,
  };
};

const getArrivalServicesExtraPrices = ({
  networks,
  shipment,
  minDoorPrice,
  minFromDoorPriceNetwork,
}) => {
  const arrivalServicesNetworks = filter(networks, network => {
    const baseRate = network?.price?.baseRate;

    return baseRate && baseRate >= minFromDoorPriceNetwork?.price?.baseRate;
  });

  return arrivalServicesNetworks.map(network => {
    const servicePrice = getBasePriceForNetwork({
      network,
      parcels: get(shipment, SHIPMENT_FIELDS.PARCELS.KEY),
    });

    return {
      networkKey: network?.networkKey,
      extraPrice:
        servicePrice -
        minDoorPrice +
        ((network?.price?.driverCollectionSurcharge || 0) -
          (minFromDoorPriceNetwork?.price?.driverCollectionSurcharge || 0)),
    };
  });
};

export const getPriceData = ({ price = {}, networks, shipment }) => {
  const parcels = get(shipment, SHIPMENT_FIELDS.PARCELS.KEY, []);
  const selectedNetwork = get(shipment, SHIPMENT_FIELDS.NETWORK.KEY);
  const minNet = getMinPriceNetworks(networks);
  const defaultNetwork = getDefaultNetwork({ shipment, minNet });
  const defaultDriverSurchargePrice = getDriverSurchargePrice({
    // network: selectedNetwork || defaultNetwork,
    network: defaultNetwork,
  });

  const totalPrice = getTotalPrice({
    network: selectedNetwork || defaultNetwork,
    shipment,
  });
  const minDoorPrice = getBasePriceForNetwork({
    parcels,
    network: minNet.minFromDoorPriceNetwork,
  });

  return {
    ...price,
    basePrice: getBasePriceForNetwork({ parcels, network: defaultNetwork }),
    minShopPrice: getBasePriceForNetwork({
      parcels,
      network: minNet.minFromShopPriceNetwork,
    }),
    minDoorPrice,
    deliveryExtraPrice: getBasePriceForNetwork({
      parcels,
      network: minNet.minFromDoorPriceNetwork,
    }),
    collectionExtraPrice: defaultDriverSurchargePrice || 0,
    taxAmount: price.taxAmount || totalPrice.taxAmount || 0,
    totalIncVatAmount:
      price.totalIncVatAmount || totalPrice.totalIncVatAmount || 0,
    arrivalServicesExtraPrices: getArrivalServicesExtraPrices({
      networks,
      shipment,
      minDoorPrice,
      minFromDoorPriceNetwork: minNet.minFromDoorPriceNetwork,
    }),
  };
};

export const removeBusinessUnit = (value = '') =>
  value.replace(/^[12]{1}\^/, '');

export const getNetworkByCode = (networks = [], code = '') =>
  networks.find(({ networkKey }) => networkKey === code);
