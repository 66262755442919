export const convertToInternalDate = localDate => {
  if (!localDate) {
    return null;
  }
  const dateInternal = new Date('1967-12-31');

  const targetDate = new Date(localDate);

  const msInDay = 24 * 60 * 60 * 1000;
  const daysDifference = Math.floor((targetDate - dateInternal) / msInDay);

  return daysDifference;
};
