export const REFERENCE_NAME = {
  COLLECTION_DATES: 'collectionDates',
  COLLECTION_PICKUP: 'collectionPickup',
  COLLECTION_ADDRESS: 'collectionAddress',
  DELIVERY_PICKUP: 'deliveryPickup',
  DELIVERY_ADDRESS: 'deliveryAddress',
  CURRENCIES: 'currencies',
  EXPORT_REASON: 'exportReasons',
  NETWORKS: 'networks',
  VOUCHER: 'voucher',
  COLLECTION_ADDRESS_BOOK: 'collectionAddressBook',
  DELIVERY_ADDRESS_BOOK: 'deliveryAddressBook',
};

export const USER_TYPE = {
  INDIVIDUAL: 'false',
  COMPANY: 'true',
};

export const USER_TYPES = [
  { label: 'Individual', value: USER_TYPE.INDIVIDUAL },
  { label: 'Company', value: USER_TYPE.COMPANY },
];

export const FREE_PROTECTION_DEDUCTION = 50;

export const INSURANCE_SECTION = {
  TITLE: coveredPrice => `Cover up to £${coveredPrice}`,
  DESCRIPTION: (coveredPrice, additionalPrice) =>
    additionalPrice !== 0
      ? `Your delivery will be covered up to £${coveredPrice}`
      : `Your delivery is automatically covered up to £${coveredPrice}`,
  PRICE: additionalPrice =>
    additionalPrice !== 0 ? `+${additionalPrice.toFixed(2)}` : 'Free',
};

export const DEFAULT_BOOLEAN_KEY_VALUE = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '0',
  },
];

export const DEFAULT_NAVIGATION_STEPS = [
  {
    key: 'parcelDetails',
    label: 'Parcel',
  },
  {
    key: 'addressDetails',
    label: 'Details',
  },
  {
    key: 'customsDetails',
    label: 'Customs',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

export const PICKUP_STATUS_ACTIVE = 2;
