import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { trackImg } from '../../../assets/images';
import {
  CUSTOMIZE_APP_TEXT,
  DID_YOU_KNOW,
  DOWNLOAD_APP_TEXT,
  DOWNLOAD_ON_APPLE,
  DOWNLOAD_ON_GOOGLE,
} from '../../../constants/strings';
import useBreakpoint from '../../../hooks/useBreakpoint';
import configSlice from '../../../redux/configSlice';

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const InitialSection = () => {
  const isSmallScreen = useBreakpoint('md');
  const theme = useTheme();

  const { appleStoreDpdApp, googleStoreDpdApp } = useSelector(
    configSlice.selectors.getLinksConfig
  );

  return (
    <StyledBox
      sx={{
        flexGrow: 1,
        backgroundColor: theme.palette.primary.backgroundLightGray,
        p: 2,
      }}
    >
      <Box
        component='img'
        src={trackImg}
        sx={{ width: { xs: '100%', md: 346 } }}
      />
      <Box sx={{ maxWidth: 400, mt: 3 }}>
        <Typography variant='h2' sx={{ textAlign: 'center', mb: 2 }}>
          {DID_YOU_KNOW}
        </Typography>
        <Typography variant='body1' sx={{ textAlign: 'center', mb: 2 }}>
          {CUSTOMIZE_APP_TEXT}
        </Typography>
        <Typography variant='body1' sx={{ textAlign: 'center' }}>
          {DOWNLOAD_APP_TEXT}
        </Typography>
      </Box>
      {isSmallScreen && (
        <StyledBox
          sx={{
            gap: 1,
            mt: 3,
            mb: 4,
            width: '100%',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            target='_blank'
            href={appleStoreDpdApp}
            sx={{ width: '100%' }}
          >
            {DOWNLOAD_ON_APPLE}
          </Button>
          <Button
            variant='contained'
            color='primary'
            target='_blank'
            href={googleStoreDpdApp}
            sx={{ width: '100%' }}
          >
            {DOWNLOAD_ON_GOOGLE}
          </Button>
        </StyledBox>
      )}
    </StyledBox>
  );
};

export default InitialSection;
