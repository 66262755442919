import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Sentry from '../../sentry';
import Step from './Step';
import { WizardContext } from './Context';

export default class StepWizard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  componentDidMount() {
    // Provide instance to parent
    this.props.instance(this);
  }

  /** Setup Steps */
  initialState = ({
    initialValues = {},
    initialReferences = {},
    isStepValues,
  } = {}) => {
    const state = {
      activeStep: 0,
      classes: {},
      namedSteps: {},
      values: {},
      references: initialReferences,
    };

    // Set initial classes
    const children = this.getSteps();
    children.forEach((child, i) => {
      // Create namedSteps map
      state.namedSteps[i] =
        (child.props && child.props.stepName) || `step${i + 1}`;
      state.namedSteps[state.namedSteps[i]] = i;
      if (isStepValues) {
        state.values[state.namedSteps[i]] =
          initialValues[state.namedSteps[i]] || {};
      } else {
        state.values = initialValues;
      }
    });

    // Set activeStep to initialStep if exists
    const initialStep = StepWizard.getInitialStep(state, this.props);

    if (initialStep && children[initialStep]) {
      state.activeStep = initialStep;
    }

    // Give initial step an intro class
    if (this.props.transitions) {
      state.classes[state.activeStep] = this.props.transitions.intro || '';
    }

    return state;
  };

  static getInitialStep(state, props) {
    return state.namedSteps[props.initialNamedStep] || props.initialStep - 1;
  }

  isInvalidStep = next => next < 0 || next >= this.totalSteps;

  setActiveStep = next => {
    const active = this.state.activeStep;

    if (active === next) {
      return;
    }

    if (this.isInvalidStep(next)) {
      if (import.meta.env.NODE_ENV !== 'production') {
        console.error(`${next + 1} is an invalid step`);
      }
      return;
    }

    const { classes } = this.state;

    this.setState(
      {
        activeStep: next,
        classes,
      },
      () => {
        // Step change callback
        this.onStepChange({
          previousStep: active + 1,
          previousNamedStep: this.state.namedSteps[active],
          activeStep: next + 1,
          activeNamedStep: this.state.namedSteps[next],
        });
      }
    );
  };

  componentDidCatch(error, info) {
    Sentry.captureException(error);
    this.setState(this.initialState(this.props), () => {
      this.props.onComponentDidCatch(error);
    });
  }

  onStepChange = stats => {
    // User callback
    this.props.onStepChange(stats);
  };

  /** Getters */
  get currentStep() {
    return this.state.activeStep;
  }

  get currentNamedStep() {
    return this.state.namedSteps[this.currentStep];
  }

  get totalSteps() {
    return this.getSteps().length;
  }

  getSteps = () => React.Children.toArray(this.props.children);

  /** Go to first step */
  firstStep = () => this.goToStep(1);

  /** Go to last step */
  lastStep = () => this.goToStep(this.totalSteps);

  /** Next Step */
  nextStep = values => {
    if (values) {
      this.setStepValues(this.state.activeStep + 1, values);
    }

    return this.setActiveStep(this.state.activeStep + 1);
  };

  /** Previous Step */
  previousStep = () => this.setActiveStep(this.state.activeStep - 1);

  /** Go to step index */
  goToStep = step => {
    this.setActiveStep(step - 1);
  };

  /** Go to named step */
  goToNamedStep = (step, values) => {
    if (typeof step === 'string' && this.state.namedSteps[step] !== undefined) {
      if (values) {
        this.setStepValues(this.state.namedSteps[step], values);
      }

      this.setActiveStep(this.state.namedSteps[step]);
    } else {
      console.error(`Cannot find step with name "${step}"`);
    }
  };

  setStepValues = (step, values) => {
    const namedStep = this.state.namedSteps[step];

    let newValues = values;
    if (this.props.isStepValues) {
      newValues = {
        ...this.state.values,
        [namedStep]: values,
      };
    }

    return this.setState(
      {
        values: newValues,
      },
      () => {
        if (this.props.isStepValues) {
          this.props.onStepValuesChange(namedStep, values);
        } else {
          this.props.onValuesChange(values);
        }
      }
    );
  };

  setReferences = (name, value) => {
    let newReferences = name;
    if (typeof name === 'string') {
      newReferences = {
        [name]: value,
      };
    }

    return this.setState(
      prevState => ({
        references: {
          ...prevState.references,
          ...newReferences,
        },
      }),
      () => {
        this.props.onReferencesChange(this.state.references);
      }
    );
  };

  setValues = values => this.setStepValues(this.currentStep, values);

  // Allows for using HTML elements as a step
  isReactComponent = ({ type }) =>
    typeof type === 'function' || typeof type === 'object';

  /** Render */
  render() {
    const props = {
      currentStep: this.currentStep,
      currentNamedStep: this.currentNamedStep,
      totalSteps: this.totalSteps,
      /** Functions */
      nextStep: this.nextStep,
      previousStep: this.previousStep,
      goToStep: this.goToStep,
      goToNamedStep: this.goToNamedStep,
      firstStep: this.firstStep,
      lastStep: this.lastStep,

      /** Custom Functionality */
      values: this.props.isStepValues
        ? this.state.values[this.currentNamedStep]
        : this.state.values,
      setValues: this.setValues,
      references: this.state.references,
      setReferences: this.setReferences,
    };

    const { classes } = this.state;
    const childrenWithProps = React.Children.map(
      this.getSteps(),
      (child, i) => {
        if (!child) {
          return null;
        }

        props.isActive = i === this.state.activeStep;
        props.transitions = classes[i];

        // Not Lazy Mount || isLazyMount && isActive
        if (
          !this.props.isLazyMount ||
          (this.props.isLazyMount && props.isActive)
        ) {
          return (
            <Step {...props}>
              {this.isReactComponent(child)
                ? React.cloneElement(child, props)
                : child}
            </Step>
          );
        }

        return null;
      }
    );

    return (
      <WizardContext.Provider value={props}>
        <Box sx={this.props.sx}>
          {this.props.nav && React.cloneElement(this.props.nav, props)}
          <div
            style={{
              position: 'relative',
              flexGrow: 1,
              display: 'flex',
              width: '100%',
            }}
          >
            {childrenWithProps}
          </div>
        </Box>
      </WizardContext.Provider>
    );
  }
}

StepWizard.propTypes = {
  children: PropTypes.node,
  initialStep: PropTypes.number,
  initialNamedStep: PropTypes.string,
  initialValues: PropTypes.object,
  initialReferences: PropTypes.object,
  instance: PropTypes.func,
  isLazyMount: PropTypes.bool,
  nav: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  isStepValues: PropTypes.bool,
  onStepChange: PropTypes.func,
  onValuesChange: PropTypes.func,
  onStepValuesChange: PropTypes.func,
  onReferencesChange: PropTypes.func,
  onComponentDidCatch: PropTypes.func,
  transitions: PropTypes.object,
};

StepWizard.defaultProps = {
  children: [],
  initialStep: 1,
  initialValues: {},
  initialReferences: {},
  instance: () => {},
  isLazyMount: false,
  nav: null,
  isStepValues: true,
  onStepChange: () => {},
  onValuesChange: () => {},
  onStepValuesChange: () => {},
  onReferencesChange: () => {},
  onComponentDidCatch: error => {},
  transitions: undefined,
};
