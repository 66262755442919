import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { ElectricVehicle } from '../../icons';
import { ORDER_DETAILS } from '../../../constants/strings';

const DeliveryStatusSection = ({
  parcelStatus,
  parcelStatusHtml,
  onRouteElectric,
  withBorder,
}) => {
  const theme = useTheme();

  return (
    <>
      {parcelStatusHtml || onRouteElectric ? (
        <Box
          sx={{
            borderBottom: withBorder && 1,
            borderColor: withBorder && theme.palette.grey[200],
            py: 1.5,
          }}
        >
          <Typography
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parcelStatusHtml),
            }}
            variant='body1'
            color={theme.palette.parcelStatus[parcelStatus]}
            sx={{
              fontFamily: 'Roboto',
              my: 0,
              fontWeight: 400,
            }}
          />
          {onRouteElectric && (
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <ElectricVehicle />
              <Box>
                <Typography
                  color={theme.palette.info.main}
                  sx={{
                    fontSize: '12px',
                    letterSpacing: '0.17px',
                  }}
                  variant='body2'
                >
                  {ORDER_DETAILS.DELIVERING_IN_AN_ALL_ELECTRIC_VEHICLE}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

DeliveryStatusSection.propTypes = {
  parcelStatus: PropTypes.string,
  parcelStatusHtml: PropTypes.string,
  onRouteElectric: PropTypes.bool,
  withBorder: PropTypes.bool,
};

export default DeliveryStatusSection;
