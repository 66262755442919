import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { STRINGS } from '../../../../../constants';
import { countriesSelectors } from '../../../../../redux/countriesSlice';
import { DefaultBasketCard } from '../../../../../components/BasketCard';
import { SHIPMENT_FIELDS } from '../../../../../constants/forms';

const PaymentCard = ({ sx, shipment, references }) => {
  const collectionCountryName = useSelector(state => {
    const countryCode = get(shipment, SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY);
    if (countryCode === STRINGS.GB) {
      return;
    }
    return countriesSelectors.getCountryName(state, countryCode);
  });
  const deliveryCountryName = useSelector(state => {
    const countryCode = get(shipment, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY);
    if (countryCode === STRINGS.GB) {
      return;
    }
    return countriesSelectors.getCountryName(state, countryCode);
  });

  return (
    <DefaultBasketCard
      outboundConsignment={shipment?.outboundConsignment}
      collectionCountryName={collectionCountryName}
      deliveryCountryName={deliveryCountryName}
      references={references}
      header={
        <Typography
          variant='h3'
          sx={{ fontSize: '20px', fontWeight: 400, lineHeight: '24px' }}
        >
          {STRINGS.SHIPPING_DETAILS.TITLE}
        </Typography>
      }
      contentContainerStyles={{ paddingTop: 3 }}
      containerStyles={{ p: 2, ...sx }}
    />
  );
};

PaymentCard.propTypes = {
  sx: PropTypes.object,
  shipment: PropTypes.object,
};

export default PaymentCard;
