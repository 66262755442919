import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Autocomplete, TextField as TextFieldRFF } from 'mui-rff';
import { v4 as uuidv4 } from 'uuid';
import { useOverlay } from '../../../../features/Overlay/index';
import { BasketActions } from '../../../../redux/basket';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import ProhibitedModal from '../../../../components/ProhibitedModal';
import { CommodityFinderModal } from '../../../../features/CommodityFinderModal';
import CommodityCard from '../../../../features/CommodityFinderModal/components/CommodityCard';
import AddCommodityCodeBtn from '../../../../features/CommodityFinderModal/components/AddCommodityCodeBtn';
import { productSchema } from '../../validators/parcel';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import Summary from '../../components/Summary';
import { ANALYTICS, FORM, STRINGS } from '../../../../constants';
import { ObjectUtil, useAnalytics, Validators } from '../../../../utils';
import Debugger from '../../../../components/Debugger';
import OrderForm from '../../components/OrderForm';
import * as OrderSelectors from '../../selectors';
import { PRODUCT_TABLE_CONFIG, TOTAL_TABLE_CONFIG } from './constants';
import { calculateTotal, getProductDataErrors } from './helpers';
import ProductTable from './components/ProductTable';
import * as CustomsDetailsSelectors from './selectors';
import { useOrderSnackbar } from '../../hooks/index.js';

const PRODUCTS_PATH = `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`;

const CustomsDetails = ({ nextStep, values, setValues }) => {
  const theme = useTheme();
  const overlay = useOverlay();
  const snackbar = useOrderSnackbar();
  const [searchParams] = useSearchParams();
  const { Trackable, setInterfaceId } = useAnalytics();
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelectors.getCountries);
  const availableFields = useSelector(
    CustomsDetailsSelectors.getAvailableFields
  );
  const productRequiredFields = useSelector(
    CustomsDetailsSelectors.getRequiredFields
  );
  const [checkedProhibitedItem, setCheckedProhibitedItem] = useState(false);
  const [isOpenProhibitedModal, setIsOpenProhibitedModal] = useState(false);
  const [commodityFinderOpen, setCommodityFinderOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const products = useMemo(() => get(values, PRODUCTS_PATH) || [], [values]);
  const total = useMemo(() => calculateTotal(products), [products]);
  const currency = get(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY);
  const totalWeight = get(values, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY);
  const productRequiredKeys = useMemo(
    () => ObjectUtil.convertObjectKeysToArray(productRequiredFields),
    [productRequiredFields]
  );
  const context = {
    ...useSelector(OrderSelectors.getValidationContext),
    productRequiredKeys,
  };
  const validateProductSchema = useMemo(
    () => Validators.requireKeysSchema(productSchema, productRequiredKeys),
    [productRequiredKeys]
  );
  const validateProduct = useCallback(
    values => {
      const joiErrors = Validators.validateWithJoi(
        values,
        validateProductSchema,
        {
          allowUnknown: true,
        }
      );
      const dataErrors = getProductDataErrors(values, products, totalWeight);

      return { ...joiErrors, ...dataErrors };
    },
    [products, totalWeight, validateProductSchema]
  );

  const invalidProductsIds = useMemo(
    () =>
      products.reduce((acc, product) => {
        const errors = validateProduct(product);
        if (!isEmpty(errors)) {
          acc.push(product.id);
        }
        return acc;
      }, []),
    [products, validateProduct]
  );

  useEffect(() => {
    setInterfaceId(ANALYTICS.ORDER_CUSTOMS_DETAILS.INTERFACE_ID);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      overlay.show();
      const newValues = cloneDeep(values);
      const basketItemId = searchParams.get('basketItemId');

      const firstProductDesc = get(
        newValues,
        FORM.SHIPMENT_FIELDS.CONSIGNMENT_PRODUCT_DESCRIPTION.KEY(0, 0)
      );
      set(
        newValues,
        FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION.KEY,
        firstProductDesc
      );

      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: newValues,
      };

      await dispatch(
        BasketActions.updateBasketItem(updatedBasketItem)
      ).unwrap();

      nextStep(newValues);
    } catch (e) {
      snackbar.showSubmitError({
        originError: e,
        message: STRINGS.FAILED_TO_SAVE_BASKET,
      });
    } finally {
      overlay.hide();
    }
  }, [dispatch, nextStep, overlay, searchParams, snackbar, values]);

  const handleOpenCommodityModal = () => {
    setCommodityFinderOpen(true);
  };
  const handleCloseCommodityModal = () => {
    setCommodityFinderOpen(false);
  };

  const handleSetCommoditySelected = (form, commodityData) => {
    form.batch(() => {
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_DESCRIPTION.KEY,
        commodityData?.commodity?.description || ''
      );
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY,
        commodityData?.commodity?.code || ''
      );
    });
  };
  const handleSaveProduct = useCallback(
    formValues => {
      const newValues = cloneDeep(values);
      const newProducts = cloneDeep(products);
      const productId = formValues.id || uuidv4();
      const existingProductIndex = products.findIndex(
        product => product.id === productId
      );
      const updatedProduct = { ...formValues, id: productId };

      if (existingProductIndex > -1) {
        newProducts[existingProductIndex] = updatedProduct;
      } else {
        newProducts.push(updatedProduct);
      }

      set(
        newValues,
        `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`,
        newProducts
      );

      setValues(newValues);
      setSelectedProduct(updatedProduct);
    },
    [values, products, setValues]
  );
  const handleDeleteProduct = useCallback(
    productToDelete => {
      const newValues = cloneDeep(values);
      const newProducts = products.filter(
        product => product.id !== productToDelete.id
      );

      set(
        newValues,
        `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].products`,
        newProducts
      );

      setValues(newValues);

      if (isEmpty(newProducts)) {
        setSelectedProduct(null);
      }
    },
    [products, setValues, values]
  );

  return (
    <Trackable loadId={ANALYTICS.ORDER_CUSTOMS_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Form
          onSubmit={handleSaveProduct}
          validate={validateProduct}
          initialValues={selectedProduct || {}}
        >
          {({ values: formValues, invalid, errors, form }) => (
            <>
              <Grid item xs={12} md={8}>
                <form id='customsDetailsForm'>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                      {STRINGS.CUSTOMS_PAGE.TITLE}
                    </Typography>
                    {!isEmpty(invalidProductsIds) && (
                      <Alert severity='error' icon={false} sx={{ mb: 2 }}>
                        {STRINGS.PRODUCTS_INVALID}
                      </Alert>
                    )}
                    <Typography variant='body1' sx={{ mb: 2 }}>
                      {STRINGS.CUSTOMS_PAGE.PARCEL_DETAILS}
                    </Typography>
                    <Grid
                      item
                      sx={{
                        pr: 1,
                        mt: { xs: 2, md: 0 },
                      }}
                    >
                      <TextFieldRFF
                        name={FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY}
                        label={FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.LABEL}
                        helperText={STRINGS.CUSTOMS_PAGE.PROD_DESC_EXAMPLE}
                        required
                        size='small'
                      />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        mt: 1,
                      }}
                    >
                      <Checkbox
                        checked={checkedProhibitedItem}
                        onChange={event =>
                          setCheckedProhibitedItem(event.target.checked)
                        }
                        sx={{ color: 'primary.main' }}
                      />
                      <Typography variant='caption'>
                        {STRINGS.SALES_CONDITIONS.CONFIRM_THAT_MY_SHIPMENT}
                        <Typography
                          variant='caption'
                          onClick={() => setIsOpenProhibitedModal(true)}
                          sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                          }}
                        >
                          {STRINGS.PROHIBITED_MODAL.PROHIBITED_ITEMS}
                        </Typography>
                      </Typography>
                    </Box>
                    {!availableFields.isDescriptionOnly && (
                      <>
                        <Grid container sx={{ mt: 0 }} spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY}
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.TOTAL_QUANTITY_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY}
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.PRODUCT_VALUE_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY
                              }
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.PRODUCT_WEIGHT_HELPER_TEXT
                              }
                              required
                              size='small'
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={12} md={12}>
                            <Field
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY
                              }
                            >
                              {({ input }) => (
                                <>
                                  {input.value ? (
                                    <CommodityCard
                                      commodity={{
                                        code: input.value,
                                        description:
                                          formValues[
                                            FORM.SHIPMENT_FIELDS
                                              .PRODUCT_COMMODITY_DESCRIPTION.KEY
                                          ],
                                        applyCode: true,
                                      }}
                                      setCommoditySelected={() =>
                                        handleSetCommoditySelected(form, null)
                                      }
                                      onEditClick={handleOpenCommodityModal}
                                    />
                                  ) : (
                                    <AddCommodityCodeBtn
                                      required={
                                        productRequiredFields[
                                          FORM.SHIPMENT_FIELDS
                                            .PRODUCT_COMMODITY_CODE.KEY
                                        ]
                                      }
                                      onClick={handleOpenCommodityModal}
                                    />
                                  )}
                                  <CommodityFinderModal
                                    open={commodityFinderOpen}
                                    setCommoditySelected={commodity => {
                                      handleSetCommoditySelected(
                                        form,
                                        commodity
                                      );
                                    }}
                                    productDescription={
                                      formValues[
                                        FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION
                                          .KEY
                                      ]
                                    }
                                    onClose={handleCloseCommodityModal}
                                  />
                                </>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {availableFields[
                      FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY
                    ] && (
                      <Box sx={{ mt: 3 }}>
                        <Autocomplete
                          options={countries}
                          getOptionLabel={option => option.countryName}
                          getOptionValue={option => option.countryKey}
                          name={
                            FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY
                          }
                          renderInput={params => (
                            <TextFieldRFF
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                  .LABEL
                              }
                              variant='outlined'
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                  .KEY
                              }
                              required={
                                productRequiredFields[
                                  FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                    .KEY
                                ]
                              }
                              size='small'
                              helperText={
                                STRINGS.CUSTOMS_PAGE
                                  .ENTER_OR_SELECT_COUNTRY_OF_ORIGIN
                              }
                              {...params}
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} md={12}>
                        <ProductTable
                          ariaLabel='products table'
                          config={PRODUCT_TABLE_CONFIG(
                            currency,
                            availableFields.isDescriptionOnly,
                            handleDeleteProduct
                          )}
                          items={
                            isEmpty(products)
                              ? [{ isDefaultValue: true }]
                              : products
                          }
                          selectedItemId={selectedProduct?.id}
                          onRowSelect={product => {
                            setSelectedProduct(product);
                            form.restart();
                          }}
                          invalidIds={invalidProductsIds}
                        />
                      </Grid>
                    </Grid>
                    {!availableFields.isDescriptionOnly && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} md={12}>
                          <ProductTable
                            ariaLabel='total products table'
                            config={TOTAL_TABLE_CONFIG(currency)}
                            items={[total]}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} md={6}>
                        <Button
                          startIcon={<Add />}
                          onClick={() => {
                            setSelectedProduct(null);
                            form.restart();
                          }}
                        >
                          {STRINGS.CUSTOMS_PAGE.ADD_ANOTHER_PRODUCT}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display='flex'
                        justifyContent='flex-end'
                      >
                        <Button
                          variant='contained'
                          disabled={invalid || !checkedProhibitedItem}
                          onClick={() => handleSaveProduct(formValues)}
                        >
                          {STRINGS.CUSTOMS_PAGE.SAVE_PRODUCT}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Debugger>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  </Debugger>
                </form>
              </Grid>
            </>
          )}
        </Form>
        <OrderForm
          context={context}
          onSubmit={onSubmit}
          additionalFields={[PRODUCTS_PATH]}
        >
          {({ invalid, submitting }) => (
            <Grid item xs={12} md={4}>
              <form>
                <Summary
                  submitDisabled={isEmpty(products) || invalid || submitting}
                />
              </form>
            </Grid>
          )}
        </OrderForm>
      </Grid>
      <ProhibitedModal
        isOpen={isOpenProhibitedModal}
        onClose={() => setIsOpenProhibitedModal(false)}
      />
    </Trackable>
  );
};

export default CustomsDetails;
