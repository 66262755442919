import apis from './apis';

export const getBarcode = async shipmentId => {
  const response = await apis.get(`shipments/${shipmentId}/barcode`, {
    params: {
      shipmentId,
    },
  });

  return response.data;
};
