import { Box, Typography } from '@mui/material';

import { DEFAULT_PRICE, SUMMARY_DETAILS } from '../../../constants/strings';
import { Formatters } from '../../../utils';

const TotalPrice = ({ totalIPrice, taxAmount }) => (
  <Box
    sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      py: 1,
    }}
    xs={12}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
      <Typography variant='body2'>{SUMMARY_DETAILS.VAT}</Typography>
      <Typography variant='body2'>
        {Formatters.formatPrice(taxAmount) || DEFAULT_PRICE}
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
      xs={12}
    >
      <Typography variant='h3' component='span' color='primary.main'>
        {SUMMARY_DETAILS.TOTAL}
        <Typography variant='body2' component='span' color='text.secondary'>
          {' '}
          {SUMMARY_DETAILS.INC_VAT}
        </Typography>
      </Typography>

      <Typography variant='h3' color='primary.main'>
        {Formatters.formatPrice(totalIPrice) || DEFAULT_PRICE}
      </Typography>
    </Box>
  </Box>
);

export default TotalPrice;
