import { BrowserRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Footer from './components/Footer/Footer';
import AppRoutes from './router/Routes';
import AppBar from './components/AppBar';
import AuthProvider from './features/Auth/AuthProvider';
import { OverlayProvider } from './features/Overlay';
import { CustomSnackbarProvider } from './features/CustomSnackbar';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import DataProvider from './DataProvider';

const StyledBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledContainer = styled(Box)({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export default function App() {
  return (
    <BrowserRouter>
      <OverlayProvider>
        <StyledBox>
          <CustomSnackbarProvider>
            <StyledContainer>
              <AuthProvider>
                <DataProvider>
                  <ScrollToTop />
                  <AppBar />
                  <AppRoutes />
                </DataProvider>
              </AuthProvider>
            </StyledContainer>
            <Footer />
          </CustomSnackbarProvider>
        </StyledBox>
      </OverlayProvider>
    </BrowserRouter>
  );
}
