import { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { VERIFICATION } from '../../../constants/analytics';
import {
  BACK,
  LOGIN_ERROR,
  NEXT,
  PLEASE_PROVIDE_YOUR_PHONE,
} from '../../../constants/strings';
import { validateWithJoi } from '../../../utils/validators';
import { verifyPhoneSchema } from '../../Order/validators/shipment';
import { useAuth } from '../../../features/Auth';
import { useOverlay } from '../../../features/Overlay';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { FIELDS } from '../../../constants/forms';
import { Formatters } from '../../../utils';

function VerifyPhone({ nextStep, previousStep }) {
  const auth = useAuth();
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();

  const confirmPhoneNumber = useCallback(
    async ({ phoneNumber }) => {
      overlay.show();

      try {
        const verificationId = await auth
          .verifyPhoneNumber(phoneNumber)
          .unwrap();
        nextStep({
          verificationId,
          pin: null,
          resendPinTimer: 60,
          phoneNumber,
        });
      } catch (e) {
        // TODO: Need a message here
        snackbar.showError({ message: e.message ?? LOGIN_ERROR });
      } finally {
        overlay.hide();
      }
    },
    [auth, nextStep, overlay, snackbar]
  );

  const handleBack = useCallback(() => {
    previousStep();
  }, [previousStep]);

  return (
    <Trackable
      interfaceId={VERIFICATION.INTERFACE_ID}
      loadId={VERIFICATION.LOAD}
    >
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          validate={values => validateWithJoi(values, verifyPhoneSchema)}
          onSubmit={confirmPhoneNumber}
        >
          {({ handleSubmit, invalid }) => (
            <Box
              component='form'
              onSubmit={handleSubmit}
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant='h5'>{PLEASE_PROVIDE_YOUR_PHONE}</Typography>
              <Box
                sx={{
                  mt: 2,
                  flexGrow: { xs: 1, md: 0 },
                }}
              >
                <TextField
                  variant='standard'
                  label={FIELDS.PHONE_NUMBER.LABEL}
                  fullWidth
                  name={FIELDS.PHONE_NUMBER.KEY}
                  fieldProps={{
                    formatOnBlur: true,
                    format: Formatters.formatUKPhoneNumber,
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 5,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  sx={{
                    alignSelf: 'flex-end',
                  }}
                  variant='outlined'
                  color='inherit'
                  onClick={handleBack}
                >
                  {BACK}
                </Button>
                <Button
                  sx={{
                    ml: 2,
                    alignSelf: 'flex-end',
                  }}
                  type='submit'
                  variant='contained'
                  disabled={invalid}
                >
                  {NEXT}
                </Button>
              </Box>
            </Box>
          )}
        </Form>
      </Box>
    </Trackable>
  );
}

export default VerifyPhone;
