import Joi from 'joi';
import moment from 'moment-timezone';
import { values } from 'lodash/object';
import {
  FORM,
  INVOICE_CONSTANTS,
  POINT_TYPE,
  REGEX,
  STRINGS,
} from '../../../constants';
import { StringUtil, Validators } from '../../../utils';
import { maxTotalParcelsValue, parcelSchema } from './parcel';

const numberOfParcels = Joi.number().min(1).max(99).label(FORM.QUANTITY);
const totalWeight = Joi.number().greater(0);

export const shipmentDate = Joi.string()
  .allow(null)
  .when(FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY, {
    is: POINT_TYPE.DOOR,
    then: Joi.custom((value, helpers) => {
      if (value) {
        const date = moment(value);
        const now = moment();

        if (date.isBefore(now)) {
          return helpers.error('date.invalid');
        }
      }
      return value;
    }),
  })
  .messages({
    'date.invalid': STRINGS.DATE_CANNOT_BE_IN_THE_PAST,
  });

export const currency = Validators.emptyString
  .length(3)
  .label(FORM.SHIPMENT_FIELDS.CURRENCY.LABEL)
  .example('GBP');

export const customsValue = Joi.number()
  .greater(0)
  .max(maxTotalParcelsValue)
  .precision(2)
  .label(FORM.CUSTOMS_VALUE)
  .example('333.3');

export const deliveryDescription = Validators.emptyString
  .max(45)
  .label(FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION.LABEL);

export const email = Joi.string()
  .pattern(REGEX.EMAIL_REGEX)
  .max(100)
  .messages({
    'string.pattern.base': StringUtil.formatMessage(
      STRINGS.PLEASE_ENTER_VALID_$,
      STRINGS.EMAIL
    ),
  })
  .label(FORM.EMAIL);

export const destinationTaxId = Validators.emptyString
  .max(45)
  .label(FORM.SHIPMENT_FIELDS.IOSS_NUMBER.LABEL);

export const gstVatPaid = Joi.boolean()
  .label(FORM.SHIPMENT_FIELDS.GST_VAT_PAID.LABEL)
  .allow(...Validators.empty);

export const termsOfDelivery = Validators.emptyString.label(
  FORM.SHIPMENT_FIELDS.INVOICE_INTERNATIONAL_BILLING_TERMS.LABEL
);

export const contactName = Joi.string().max(35).label(FORM.CONTACT_NAME);

export const generateCustomsData = Joi.boolean()
  .allow(null)
  .label(FORM.SHIPMENT_FIELDS.GENERATE_CUSTOMS_DATA.LABEL);

export const addressDetailsSchema = countryValue =>
  Joi.object({
    [FORM.FIELDS.ADDRESS_KEY.KEY]: Validators.emptyString,
    ...Validators.addressSchemaWithPostCode(countryValue),
    _coordinates: Joi.object({
      latitude: Joi.number(),
      longitude: Joi.number(),
    }).allow(null),
  }).unknown();

export const contactDetails = Joi.object({
  contactName,
  telephone: Validators.phoneNumber(FORM.PHONE_NUMBER).allow(
    ...Validators.empty
  ),
  _addressBookId: Validators.emptyString,
});

export const notificationDetails = Joi.object({
  mobile: Validators.phoneNumber(FORM.PHONE_NUMBER).allow(...Validators.empty),
  email,
});

export const pointType = Joi.string().valid(POINT_TYPE.SHOP, POINT_TYPE.DOOR);

const baseImporterExporterField = Validators.emptyString.min(4).max(45);

export const deliveryDetailsSchema = (
  deliveryCountryValue,
  withContactDetails = false
) =>
  Joi.object({
    address: addressDetailsSchema(deliveryCountryValue),
    _deliverTo: pointType,
    ...(withContactDetails && {
      // TODO: add only 1 error on the Basket page
      notificationDetails,
      contactDetails,
    }),
  });

export const collectionDetailsSchema = (
  collectionCountryValue,
  withContactDetails = false
) =>
  Joi.object({
    address: addressDetailsSchema(collectionCountryValue),
    dropAtShop: Joi.boolean().allow(null),
    _collectFrom: pointType,
    ...(withContactDetails && {
      // TODO: add only 1 error on the Basket page
      contactDetails: contactDetails.keys({
        email,
      }),
    }),
  });

export const pickupDetailsSchema = Joi.object({
  pickupLocationCode: Joi.string().max(20),
  pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
  pickupOrganisation: Validators.emptyString.max(35),
  pickupPostcode: Validators.emptyString,
  pickupAddressLine1: Validators.emptyString.max(35),
  pickupAddressLine2: Validators.emptyString.max(35),
  pickupAddressLine3: Validators.emptyString.max(35),
  pickupAddressLine4: Validators.emptyString.max(35),
});

export const consignmentSchema = ({
  deliveryCountryValue,
  collectionCountryValue,
  maxWeight,
  maxParcelNumber,
  withContactDetails = false,
  productRequiredKeys,
}) =>
  Joi.object({
    deliveryDetails: deliveryDetailsSchema(
      deliveryCountryValue,
      withContactDetails
    ),
    collectionDetails: collectionDetailsSchema(
      collectionCountryValue,
      withContactDetails
    ),
    pickupDetails: pickupDetailsSchema
      .keys({
        allowRemotePickup: Joi.boolean().allow(null),
      })
      .allow(null),
    _dropOffDetails: pickupDetailsSchema.allow(null),
    numberOfParcels,
    totalWeight,
    networkCode: Joi.string(),
    _network: Joi.object(),
    liability: Joi.boolean(),
    liabilityValue: Joi.number(),
    parcels: Joi.array().items(
      parcelSchema({ maxWeight, maxParcelNumber, productRequiredKeys })
    ),
    currency,
    customsValue: customsValue.allow(...Validators.empty),
    deliveryDescription,
    destinationTaxId,
    gstVatPaid,
  });

// TODO: add ioss validation
export const invoiceSchema = Joi.object({
  exportReason: Joi.string().label(FORM.REASON_FOR_EXPORT),
  importerDetails: Joi.object({
    vatNumber: baseImporterExporterField.label(
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.LABEL
    ),
    pidNumber: baseImporterExporterField.label(
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.LABEL
    ),
    eoriNumber: baseImporterExporterField.label(
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.LABEL
    ),
    ukimsNumber: Joi.string()
      .length(32)
      .example('11111111111111111111111111111111')
      .label(FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.LABEL),
    isBusiness: Joi.boolean(),
    atRisk: Joi.boolean(),
    contactDetails: contactDetails.keys({
      email,
    }),
  }),
  exporterDetails: Joi.object({
    vatNumber: baseImporterExporterField.label(
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.LABEL
    ),
    eoriNumber: Joi.string()
      .regex(REGEX.BUSINESS_EORI_REGEX)
      .messages({
        'string.pattern.base': StringUtil.formatMessage(
          STRINGS.PLEASE_ENTER_VALID_$,
          STRINGS.EORI
        ),
      })
      .label(FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.LABEL),
    fdaRegistrationNumber: Joi.string()
      .max(30)
      .label(FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.LABEL),
    ukimsNumber: Joi.string()
      .length(32)
      .example('11111111111111111111111111111111')
      .label(FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.LABEL),
    isBusiness: Joi.boolean(),
    termsOfDelivery,
    invoiceType: Joi.number()
      .valid(...values(INVOICE_CONSTANTS.INVOICE_TYPES))
      .label(FORM.SHIPMENT_FIELDS.INVOICE_TYPE.LABEL)
      .allow(...Validators.empty),
  }),
});

export const verifyPhoneSchema = Joi.object({
  phoneNumber: Validators.emptyString
    .pattern(REGEX.REGISTRATION_PHONE_NUMBER_REGEXP)
    .required()
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.MOBILE_NUMBER
      ),
    })
    .label(FORM.MOBILE_NUMBER),
});

export const verifyPinSchema = Joi.object({
  pin: Joi.string().min(6).max(6).required(),
});

export const shipmentSchema = ({
  deliveryCountryValue,
  collectionCountryValue,
  maxWeight,
  maxParcelNumber,
  withContactDetails,
  productRequiredKeys,
}) =>
  Joi.object({
    shipmentDate,
    generateCustomsData,
    outboundConsignment: consignmentSchema({
      deliveryCountryValue,
      collectionCountryValue,
      maxWeight,
      maxParcelNumber,
      withContactDetails,
      productRequiredKeys,
    }),
    invoice: invoiceSchema.allow(null),
  });
