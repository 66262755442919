import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { STRINGS } from '../../../constants';

const ImageDropzoneInnerText = () => (
  <Box sx={{ p: 3, textAlign: 'center' }}>
    <UploadFileIcon sx={{ color: 'primary.main', fontSize: '24px', m: 1 }} />
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        variant='subtitle1'
        sx={{
          color: 'primary.main',
          textDecoration: 'underline',
        }}
      >
        {STRINGS.CLICK_TO_UPLOAD}
      </Typography>
      <Typography variant='body1' sx={{ ml: 0.5 }}>
        {STRINGS.DRAG_AND_DROP}
      </Typography>
    </Box>
    <Typography variant='caption' color='textSecondary'>
      {STRINGS.ALLOWED_FORMATS_AND_SIZE}
    </Typography>
  </Box>
);

export default ImageDropzoneInnerText;
