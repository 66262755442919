import { Alert, Typography } from '@mui/material';

import { ORDER_DETAILS } from '../../../constants/strings';

const IssueSection = ({ issueDetails }) =>
  issueDetails?.issueName && (
    <Alert severity='error' icon={false} sx={{ my: 1 }}>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: '0.15px',
        }}
      >
        {issueDetails?.issueDelayDays
          ? ORDER_DETAILS.issueDelayDaysMessage_$(
              issueDetails?.issueDelayDays,
              issueDetails?.issueName
            )
          : ORDER_DETAILS.issueDelayMessage_$(issueDetails?.issueName)}
      </Typography>
    </Alert>
  );

export default IssueSection;
