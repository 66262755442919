import Joi from 'joi';
import {
  HEIGHT,
  LENGTH,
  QUANTITY,
  SHIPMENT_FIELDS,
  VALUE,
  WEIGHT,
  WIDTH,
} from '../../../constants/forms';
import { Validators } from '../../../utils';

const dimensionValidator = Joi.number().greater(0).max(100).messages({
  'number.max': 'Size too long',
});

export const maxTotalParcelsValue = 1000;
export const minParcelValue = 0.01;
export const maxUnitValue = 1000;

export const productSchema = Joi.object({
  id: Joi.string(),
  productDescription: Joi.string()
    .max(200)
    .label(SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.LABEL),
  productQty: Joi.number()
    .max(99)
    .label(SHIPMENT_FIELDS.PRODUCT_QUANTITY.LABEL),
  unitValue: Joi.number()
    .min(minParcelValue)
    .max(maxUnitValue)
    .label(SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.LABEL),
  unitWeight: Joi.number()
    .max(30)
    .label(SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.LABEL),
  countryOfOrigin: Joi.string().label(
    SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.LABEL
  ),
  _commodityDescription: Joi.string(),
  commodityCode: Joi.string().label(
    SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.LABEL
  ),
});

export const parcelSchema = ({
  maxWeight = 30,
  maxParcelNumber = 99,
  productRequiredKeys = [],
}) =>
  Joi.object({
    _parcelId: Joi.string(),
    _weight: Joi.number()
      .greater(0)
      .max(maxWeight)
      .label(WEIGHT)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxWeight}`,
      }),
    _quantity: Joi.number()
      .min(1)
      .max(maxParcelNumber)
      .label(QUANTITY)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxParcelNumber}`,
      }),
    _width: dimensionValidator.label(WIDTH).required(),
    _height: dimensionValidator.label(HEIGHT).required(),
    _length: dimensionValidator.label(LENGTH).required(),
    _value: Joi.number()
      .required()
      .min(minParcelValue)
      .max(maxTotalParcelsValue)
      .required()
      .messages({
        'number.min': `Min value is ${minParcelValue}`,
        'number.max': `Max value is ${maxTotalParcelsValue}`,
      })
      .label(VALUE),
    // NOTE: Temporary, remove after column in the table will be removed
    _description: Joi.string().allow('', null),
    products: Joi.array()
      .items(Validators.requireKeysSchema(productSchema, productRequiredKeys))
      .allow(null),
  });
