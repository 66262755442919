import { get, isBoolean } from 'lodash';
import { APIS_BASE_URL } from '../constants/env';
import { INVOICE_CONSTANTS, STRINGS } from '../constants';

export const getShipmentInvoiceDownloadUrl = shipmentId =>
  `${APIS_BASE_URL}/shipments/${shipmentId}/invoice`;

export const filterExportReasons = (exportReasons = []) =>
  exportReasons.reduce((acc, { code: value, description: label }) => {
    if (INVOICE_CONSTANTS.DEFAULT_REASONS.includes(value)) {
      acc.push({ label, value });
    }

    return acc;
  }, []);

export const sortCurrencies = (currencies = []) =>
  currencies.sort((a, b) => {
    if (a.currencyCode === STRINGS.GBP) return -1;
    if (b.currencyCode === STRINGS.GBP) return 1;
    if (a.currencyCode === STRINGS.EUR) return -1;
    if (b.currencyCode === STRINGS.EUR) return 1;

    return a.currencyCode > b.currencyCode ? 1 : -1;
  });

export const getCurrenciesKeyValue = (currencies = []) =>
  currencies.map(({ currencyCode }) => ({
    label: currencyCode,
    value: currencyCode,
  }));

export const getDefaultIsBusiness = (
  shipment,
  { businessPath, organisationPath }
) => {
  let defaultValue = get(shipment, businessPath);

  if (isBoolean(defaultValue)) {
    return defaultValue;
  }

  const organisation = get(shipment, organisationPath);

  return !!organisation;
};
