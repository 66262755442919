import { createSelector } from '@reduxjs/toolkit';
import { OrderSliceSelectors } from '../../slice';
import { getProductAvailableFields, getProductRequiredFields } from './helpers';

export const getAvailableFields = createSelector(
  OrderSliceSelectors.getValues,
  getProductAvailableFields
);

export const getRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  getProductRequiredFields
);
