import { SvgIcon } from '@mui/material';

const AmericaExpress = props => (
  <SvgIcon {...props} viewBox='0 0 20 20'>
    <path
      d='M20.0004 20.0009H10.0597H-0.000976562V9.90098V-0.000488281H9.92569H20.0004V9.99938V20.0009Z'
      fill='white'
    />
    <path
      d='M20.0002 20.0009V16.8316H17.5872L16.3449 15.4579L15.0963 16.8316H7.14058V10.4292H4.57277L7.75787 3.22109H10.8296L11.9261 5.6905V3.22109H15.7283L16.3885 5.08193L17.053 3.22109H20.0004V-0.000488281H-0.000976562V20.0009H20.0004L20.0002 20.0009ZM17.9937 16.03H20.0002L17.346 13.2153L20.0002 10.4325H18.0256L16.3864 12.2238L14.763 10.4325H12.756L15.3948 13.2313L12.756 16.03H14.7072L16.3544 14.2228L17.9937 16.03ZM18.4631 13.2176L20.0002 14.8529V11.596L18.4631 13.2176ZM9.51734 14.7346V13.863H12.6758V12.5995H9.51734V11.728H12.7558V10.4325H7.98984V16.0301H12.7558V14.7347H9.51734V14.7346ZM18.4887 9.62028H20.0001V4.0227H17.6491L16.3936 7.50906L15.1462 4.0227H12.7554V9.62028H14.2666V5.70205L15.706 9.62028H17.0494L18.4887 5.69397V9.62028ZM11.0362 9.62028H12.7555L10.2847 4.0227H8.31754L5.84654 9.62028H7.52581L7.98968 8.5008H10.5645L11.0362 9.62028ZM10.0368 7.24532H8.51736L9.27709 5.41416L10.0368 7.24532Z'
      fill='#006FCF'
    />
  </SvgIcon>
);

export default AmericaExpress;
