import { get } from 'lodash';
import { Box, Button, useTheme } from '@mui/material';
import Slider from 'react-slick/lib/slider';
import { ADD_TO_WALLET, OS } from '../../constants/strings';
import { getLinkForParselToSetInWallet } from '../../helpers';
import GooglePayIcon from '../../assets/images/googlePayIcon.png';
import ApplePayIcon from '../../assets/images/applePayIcon.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SHIPMENT_FIELDS } from '../../constants/forms';

const MultiParcel = ({ barcodes, shipmentId, shipment, os, isSmallScreen }) => {
  const theme = useTheme();

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    arrows: true,
  };

  const images = barcodes
    ? barcodes.map((barcode, index) => ({
        id: index,
        imageKey: barcode.barcodeImage.imageData,
        imageType: 'png',
        imageCaption: 'Barcode Image',
        src: `data:image/png;base64,${barcode.barcodeImage.imageData}`,
        alt: 'Barcode',
      }))
    : [];

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        sx={{
          '& .slick-list': {
            width: '100%',
            mx: 'auto',
          },
          '& .slick-track': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .slick-dots': {
            bottom: isSmallScreen ? '65px' : '5px',
          },
          '& .slick-next': {
            right: '5px',
            '& :before': {
              color: theme.palette.primary.main,
            },
          },
          '& .slick-prev': {
            left: '5px',
            zIndex: 1,
            '& :after': {
              color: theme.palette.primary.main,
            },
          },
        }}
      >
        <Slider {...settings}>
          {images.map(image => (
            <Box sx={{ px: 1 }} key={image.id}>
              <img
                src={image.src}
                alt={image.alt}
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  width: '100%',
                  padding: '20px',
                }}
              />
              {isSmallScreen && (
                <Box
                  sx={{
                    pt: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {os === OS.IOS ? (
                    <Button
                      href={getLinkForParselToSetInWallet(
                        'apple',
                        shipmentId,
                        get(
                          shipment,
                          `${SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelId`
                        )
                      )}
                      rel='noopener noreferrer'
                      sx={{
                        px: 1,
                        m: (1, 2, 1, 2),
                        width: '100%',
                        maxWidth: '100%',
                        background: theme.palette.primary.black,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          background: theme.palette.primary.black,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      variant='outlined'
                    >
                      <img
                        style={{ marginRight: 8 }}
                        src={ApplePayIcon}
                        alt='Apple Pay'
                      />
                      {ADD_TO_WALLET.APPLE}
                    </Button>
                  ) : (
                    <Button
                      href={getLinkForParselToSetInWallet(
                        'google',
                        shipmentId,
                        get(
                          shipment,
                          `${SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelId`
                        )
                      )}
                      rel='noopener noreferrer'
                      sx={{
                        px: 1,
                        m: (1, 2, 1, 2),
                        width: '100%',
                        maxWidth: '100%',
                        background: theme.palette.primary.black,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          background: theme.palette.primary.black,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      variant='outlined'
                    >
                      <img
                        style={{ marginRight: 8 }}
                        src={GooglePayIcon}
                        alt='Google Pay'
                      />
                      {ADD_TO_WALLET.GOOGLE}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default MultiParcel;
