import { get, set } from 'lodash';
import { FORM } from '../constants';
import { ObjectUtil } from '../utils';
import { isNiShipment } from './shipment';

export const getNetworkQuery = values => {
  const data = ObjectUtil.pickDeep(values, [
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_CITY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_CITY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,

    FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
    FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
  ]);

  if (!get(data, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY)) {
    set(data, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY, 1);
  }

  if (!get(data, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY)) {
    set(data, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY, 1);
  }

  const query = data.outboundConsignment;

  const isNiZone = isNiShipment(values);

  if (isNiZone) {
    const company = get(
      values,
      FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
    );
    query.isBusiness = get(
      values,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
      !!company
    );
    query.atRisk = get(
      values,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY,
      false
    );
  }

  return query;
};

export const getNiRequiredFieldsQuery = values => ({
  countryCode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY, ''),
  postcode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY, ''),
  isBusiness: get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  ),
  atRisk: get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY, false),
});
