import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)({
  // fontSize: '16px',
  // fontWeight: 370,
  // lineHeight: '150%',
  // letterSpacing: '0.15px',
  // TODO make sure that variants equals to mockups
  wordWrap: 'break-word',
});

// eslint-disable-next-line complexity
export const BasketCardContent = ({ data, title }) => {
  const {
    county,
    locality,
    organisation,
    postcode,
    street,
    town,
    contactName,
    email,
    telephone,
    countryName,
  } = data;

  return (
    <Grid item flexGrow={1}>
      <Grid>
        <StyledTypography
          variant='subtitle2'
          color='primary.main'
          spacing={2}
          sx={{
            letterSpacing: '0.1px',
            lineHeight: '157%',
            fontWeight: 400,
          }}
        >
          {title}
        </StyledTypography>
        {organisation && (
          <StyledTypography variant='body1'>{organisation}</StyledTypography>
        )}
        {contactName && (
          <StyledTypography variant='body1'>{contactName}</StyledTypography>
        )}
        {street && (
          <StyledTypography variant='body1'>{street}</StyledTypography>
        )}
        {town && <StyledTypography variant='body1'>{town}</StyledTypography>}
        {county && (
          <StyledTypography variant='body1'>{county}</StyledTypography>
        )}
        {locality && (
          <StyledTypography variant='body1'>{locality}</StyledTypography>
        )}
        {postcode && (
          <StyledTypography variant='body1'>{postcode}</StyledTypography>
        )}
        {countryName && (
          <StyledTypography variant='body1'>{countryName}</StyledTypography>
        )}
      </Grid>
      <Grid>
        {telephone && (
          <StyledTypography variant='body1' sx={{ mt: 3 }}>
            {telephone}
          </StyledTypography>
        )}
        {email && <StyledTypography variant='body1'>{email}</StyledTypography>}
      </Grid>
    </Grid>
  );
};
