import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import * as OrderSelectors from '../selectors';

const StyledStepper = styled(Stepper)(({ theme }) => ({
  justifyContent: 'center',
  '& .MuiStepIcon-root': {
    display: 'none',
  },
  '& .MuiStepConnector-root': {
    width: '71px',
    flexGrow: 0,
    [theme.breakpoints.down('md')]: {
      width: '53px',
    },
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: theme.palette.primary.main,
  },
}));

const Navigation = ({ currentStep, goToNamedStep }) => {
  const [completed, setCompleted] = React.useState({});
  const steps = useSelector(OrderSelectors.getNavigationSteps);

  const handleStep = step => () => {
    goToNamedStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledStepper activeStep={currentStep}>
        {steps.map(({ key, label }, index) => (
          <Step key={key} completed={completed[index]}>
            <StepButton
              id={`wizard-nav-button-${key}`}
              color='inherit'
              onClick={handleStep(key)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </StyledStepper>
    </Box>
  );
};

export default Navigation;
