import Async from 'react-async';
import { HttpStatusCode } from 'axios';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../ErrorBoundary';
import { useAuth } from '../Auth';
import Pending from './Pending';

const Loader = ({
  children,
  promiseFn,
  errorComponent,
  pendingProps,
  ...args
}) => {
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();
  const { currentSession } = useAuth();
  const dontHaveAccessError = (
    <ErrorBoundary
      config={getErrorBoundaryConfig[
        !currentSession?.uid
          ? ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS
          : ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS_LOGGED
      ]()}
    />
  );
  const defaultErrorComponent = error => (
    <ErrorBoundary
      config={getErrorBoundaryConfig[ERROR_BOUNDARY_TYPES.LOADING_CRASH](
        error.message
      )}
    />
  );
  return (
    <Async promiseFn={promiseFn} {...args}>
      <Async.Pending>
        <Pending {...pendingProps} />
      </Async.Pending>
      <Async.Fulfilled>{children}</Async.Fulfilled>
      <Async.Rejected>
        {error => {
          if (error?.status === HttpStatusCode.Forbidden) {
            return dontHaveAccessError;
          }
          return errorComponent
            ? errorComponent(error)
            : defaultErrorComponent(error);
        }}
      </Async.Rejected>
    </Async>
  );
};

Loader.propTypes = Async.propTypes;

export default Loader;
