import { Select } from 'mui-rff';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const DropDown = ({ options = [], ...props }) => (
  <Select {...props}>
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
);

DropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default DropDown;
