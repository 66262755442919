import Dropzone from 'react-dropzone';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { STRINGS } from '../../constants';
import { useOverlay } from '../../features/Overlay';
import ImageThumb from './components/ImageThumb';

const ImageDropzone = ({
  input: { value, onChange },
  disabled,
  innerContent,
  showPreview,
  ...props
}) => {
  const theme = useTheme();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();

  const fileToBase64 = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }),
    []
  );

  const handleFile = useCallback(
    async file => {
      try {
        overlay.show();
        const base64 = await fileToBase64(file);

        return {
          image: base64,
          fileName: file.name,
          contentType: file.type,
          preview: URL.createObjectURL(file),
        };
      } catch (error) {
        showError({ message: STRINGS.UPLOAD_ERROR_IMAGE });
      } finally {
        overlay.hide();
      }
    },
    [fileToBase64, overlay, showError]
  );

  const onDropAccepted = async acceptedFiles => {
    const validFiles = await Promise.all(acceptedFiles.map(handleFile));

    onChange(value ? [...value, ...validFiles] : [...validFiles]);
  };

  const removeFile = fileToRemove => {
    const updatedFiles = value
      ? value.filter(file => file !== fileToRemove)
      : [];
    onChange(updatedFiles);
    URL.revokeObjectURL(fileToRemove.preview);
  };

  return (
    <Dropzone onDropAccepted={onDropAccepted} {...props}>
      {({ getRootProps, getInputProps }) => (
        <Box>
          <Box
            {...getRootProps()}
            sx={{
              border: `2px dashed ${theme.palette.divider}`,
              borderRadius: 2,
              cursor: !disabled && 'pointer',
            }}
          >
            <input {...getInputProps()} />
            {innerContent}
          </Box>

          {showPreview && (
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}
            >
              {value &&
                value.map((file, index) => (
                  <ImageThumb
                    image={file.preview}
                    alt={file.name}
                    onRemoveClick={() => removeFile(file)}
                    key={`${file.name}-${index}`}
                  />
                ))}
            </Box>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

ImageDropzone.propTypes = {
  innerContent: PropTypes.node,
  disabled: PropTypes.bool,
  showErrors: PropTypes.bool,
  showPreview: PropTypes.bool,
  ...Dropzone.propTypes,
};

export default ImageDropzone;
