import { get, upperFirst } from 'lodash';
import { FORM, STRINGS } from '../../../constants';

export const getParcelToContactTitle = shipment => {
  const contactName = get(
    shipment,
    FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
  );
  const numberOfParcels = get(
    shipment,
    FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY
  );
  const maxContactNameLength = 35;

  return contactName
    ? upperFirst(
        STRINGS.PARCEL_TO_$(
          contactName.substr(0, maxContactNameLength),
          numberOfParcels
        )
      )
    : '';
};
