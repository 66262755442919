import { useParams } from 'react-router-dom';

const useGetParcelParams = () => {
  const params = useParams();
  const parcelCode = params?.parcelCode ?? '';
  const parcelNumber = parcelCode.split('*')[0];
  const postcode = localStorage.getItem(`tracking/${parcelCode}`);

  return { parcelCode, parcelNumber, postcode };
};

export default useGetParcelParams;
