import apis from './apis';

export const searchLocations = (postcode, options) =>
  apis.get({
    options,
    path: '/locations/search',
    query: {
      postcode,
    },
  });

export const searchAddressesByPostcode = (postcode, options) =>
  apis.get({
    options,
    path: '/locations/searchByPostcode',
    query: {
      postcode,
    },
  });

export const getPickupLocations = async ({ latitude, longitude }, options) => {
  const { maxDistance, searchPageSize, searchPage, countryCode } = options;

  const response = await apis.get('/pickupLocations/', {
    params: {
      latlong: `${latitude},${longitude}`,
      maxDistance,
      searchPageSize,
      searchPage,
      countryCode,
    },
  });

  return response.data;
};

export const getPickupLocationsByAddress = async options => {
  const {
    searchAddress,
    searchCriteria,
    maxDistance,
    searchPageSize,
    searchPage,
    countryCode,
  } = options;

  const response = await apis.get('/pickupLocations/address', {
    params: {
      searchAddress,
      searchCriteria,
      maxDistance,
      searchPageSize,
      searchPage,
      countryCode,
    },
  });

  return response.data;
};

export const getPickupLocationsByPostcode = (postcode, options) =>
  apis.get('/pickupLocations/address', {
    options,
    query: {
      searchAddress: postcode,
    },
  });

export const getPickupLocationsById = (id, options) =>
  apis.get(`/pickupLocations/${id}`, {
    options,
  });

export const getAddressByUdprn = (udprn, options) =>
  apis.get(`/locations/${udprn}`, {
    options,
  });
