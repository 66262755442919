import { Input } from '@mui/material';
import OtpInput from 'react-otp-input';

const PinInput = ({ onChange, value }) => (
  <OtpInput
    value={value}
    onChange={onChange}
    numInputs={6}
    skipDefaultStyles
    renderSeparator={<span style={{ width: '8px' }} />}
    renderInput={({ style, ref, ...props }) => (
      <Input
        {...props}
        inputRef={ref}
        style={{
          ...style,
          width: '45px',
          height: '45px',
          textAlign: 'center',
        }}
        inputProps={{ style: { textAlign: 'center' } }}
      />
    )}
  />
);

export default PinInput;
