import {
  createWizardSlice,
  initState as wizardInitialState,
} from '../../../features/Wizard/slice';

export const VerifyEmailSlice = createWizardSlice('verifyEmail', undefined, {
  clearAll: state => wizardInitialState,
});

export const VerifyEmailSliceSelectors = VerifyEmailSlice.selectors;

export const VerifyEmailReducer = VerifyEmailSlice.reducer;
