import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { STRINGS } from '../../../constants';

const AddCommodityCodeBtn = ({ onClick, required }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-end',
    }}
  >
    <Button variant='outlined' onClick={onClick}>
      {STRINGS.ADD_COMMODITY_CODE}
    </Button>
    {required && (
      <Typography
        variant='caption'
        color='primary'
        sx={{
          ml: 2,
        }}
      >
        * {STRINGS.REQUIRED}
      </Typography>
    )}
  </Box>
);

AddCommodityCodeBtn.propTypes = {
  required: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AddCommodityCodeBtn;
