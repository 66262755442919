import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LocationContext from './Context';
import { useLocationController } from './useLocationController';

const Provider = ({ children }) => {
  const { permissionStatus, currentPosition, error } = useLocationController();

  const contextValue = useMemo(
    () => ({
      permissionStatus,
      currentPosition,
      error,
    }),
    [permissionStatus, currentPosition, error]
  );

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
