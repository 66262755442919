export const INVALID_AUTH_CODE = 'auth/invalid-verification-id';
export const INVALID_VERIFICATION_ID_CODE = 'auth/verification-id-invalid';
export const EXPIRED_AUTH_CODE = 'auth/link-expired';
export const REACH_MAX_LOGIN_ATTEMPTS_CODE = 'auth/reached-max-login-attempts';
export const AUTH_TOO_MANY_REQUESTS = 'auth/too-many-requests';
export const AUTH_MISSING_COOKIE = 'auth/missing-session-cookie';
export const TOTAL_QUANTITY_ERROR_$ =
  'Total quantity must not be more than {0}';
export const TOTAL_VALUE_ERROR_$ = 'Total value must not be more than {0}£';

export const EXPIRED_AUTH_CODES = [
  EXPIRED_AUTH_CODE,
  INVALID_AUTH_CODE,
  INVALID_VERIFICATION_ID_CODE,
];
