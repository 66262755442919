export const slicePage = (array, { page, pageSize }) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Return the slice of the array for the given page
  return array.slice(startIndex, endIndex);
};

export const findInArray = ({ array, key, value }) =>
  array.find(item => item[key] === value);
