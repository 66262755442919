import apis from './apis';

export const getClientToken = async basketId => {
  const response = await apis.get(`/baskets/${basketId}/payment/clientToken`);
  const {
    data: { token },
  } = response;

  return token;
};

// Verification and Payment
export const verifyAndPay = async (basketId, basketItemIds, purchaseData) => {
  const result = await apis.post(
    `/baskets/${basketId}/payment/checkout`,
    purchaseData,
    {
      params: {
        basketItemIds: basketItemIds.toString(),
      },
    }
  );
  return result.data;
};

export const getPrice = async ({ basketId, basketItemIds, voucherId }) => {
  const result = await apis.get(`/baskets/${basketId}/payment/price`, {
    params: {
      basketItemIds: basketItemIds.toString(),
      voucherId,
    },
  });
  return result.data;
};
