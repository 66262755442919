export const splitCode = code => {
  const pairs = [];
  for (let i = 0; i < code.length; i += 2) {
    pairs.push(code.slice(i, i + 2));
  }
  return pairs;
};

export const getBackgroundColor = (level, theme) => {
  const backgroundColors = {
    0: theme.palette.primary.violet,
    1: theme.palette.primary.blue,
    2: theme.palette.primary.green,
    3: theme.palette.primary.green,
  };
  return backgroundColors[level] || 'transparent';
};
