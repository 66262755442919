import { get, sortBy } from 'lodash';

import { FORM, POINT_TYPE } from '../constants';
import { SHIPMENT_FIELDS } from '../constants/forms';

export const getDriverSurchargePrice = ({ network }) => {
  return network ? get(network, 'price.driverCollectionSurcharge', 0) : 0;
};

export const getDriverSurcharge = ({ shipment, network }) => {
  let driverSurchargePrice = 0;
  const isCollectedFromDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR;
  if (isCollectedFromDoor) {
    driverSurchargePrice = getDriverSurchargePrice({ network });
  }
  return driverSurchargePrice;
};

export const getTotalPrice = ({ shipment, network }) => {
  const parcels = get(shipment, SHIPMENT_FIELDS.PARCELS.KEY, []);
  let driverSurcharge = 0;
  let taxAmount = 0;
  let totalIncVatAmount = 0;

  if (parcels.length > 0) {
    let totalExcVatAmount = getBasePriceForNetwork({ parcels, network });
    driverSurcharge = getDriverSurcharge({ shipment, network });
    totalExcVatAmount = totalExcVatAmount + driverSurcharge;
    const vatRate = get(network, 'price.vatRate', 0);
    taxAmount =
      Math.round(((totalExcVatAmount * vatRate) / 100) * 100) / 100 || 0;
    totalIncVatAmount = totalExcVatAmount + taxAmount || 0;
  }

  return {
    taxAmount,
    totalIncVatAmount,
    driverSurcharge,
  };
};

export const getBasePriceForNetwork = ({ parcels, network }) => {
  if (!network) return;

  return parcels?.reduce((total, parcel) => {
    const parcelPrice = getParcelPrice({
      parcel,
      network,
    });
    return total + parcelPrice * (parcel._quantity || 1);
  }, 0);
};

export const getParcelPrice = ({ parcel, network }) => {
  const basePrice = network?.price?.baseRate;

  const extraWeightPrice = getExtraWeightPriceForParcel({
    parcel,
    network,
  });

  return basePrice + extraWeightPrice;
};

export const getExtraWeightPriceForParcel = ({ parcel, network }) => {
  const weight = parcel._weight;
  const baseRateWeight = get(network, 'price.baseRateWeight');

  const additionalRateWeight = get(network, 'price.additionalRateWeight', 0);
  const additionalRate = get(network, 'price.additionalRate', 0);
  // Calculate extra weight
  const extraWeight = weight > baseRateWeight ? weight - baseRateWeight : 0;

  // Calculate additional charge based on increments
  const additionalCharges =
    Math.ceil(extraWeight / additionalRateWeight) * (additionalRate || 1);

  return additionalCharges || 0;
};

export const getDefaultNetwork = ({ shipment, minNet }) => {
  if (!minNet) return;
  const isDroppedToDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY) === POINT_TYPE.DOOR;
  return isDroppedToDoor
    ? minNet.minFromDoorPriceNetwork
    : minNet.minFromShopPriceNetwork;
};

export const getMinPriceNetworks = networks => {
  const minFromShopPriceNetwork = sortBy(
    networks.filter(
      network => network.price.baseRate && network.networkKey === '2^91'
    ),
    'price.baseRate'
  )[0];

  const minFromDoorPriceNetwork = sortBy(
    networks.filter(
      network => network.price.baseRate && network.networkKey !== '2^91'
    ),
    'price.baseRate'
  )[0];

  return { minFromShopPriceNetwork, minFromDoorPriceNetwork };
};
