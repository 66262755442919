import { Box, Button, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SummaryDetails } from '../../../../components/SummaryDetails';
import * as OrderSelectors from '../../selectors';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { ANALYTICS, STRINGS } from '../../../../constants';
import { useAnalytics } from '../../../../utils';
import { useWizard } from '../../../../features/Wizard';

const Summary = ({
  displayItemsPriceDetails,
  displayInsurance,
  onClickBack,
  submitDisabled,
}) => {
  const theme = useTheme();
  const price = useSelector(OrderSelectors.getTotalAmount);
  const isSmallScreen = useBreakpoint('md');
  const { trackAction } = useAnalytics();
  const { previousStep } = useWizard();
  const form = useForm();
  const { values } = form.getState();

  const handleNext = useCallback(
    event => {
      trackAction(event);
      event.preventDefault();

      form.submit();
    },
    [trackAction, form]
  );

  const handleBack = useCallback(
    event => {
      trackAction(event);
      previousStep();
    },
    [previousStep, trackAction]
  );

  return (
    <Box
      sx={{ position: 'sticky', top: theme.spacing(2) }}
      data-testid='summary-details'
    >
      <SummaryDetails
        price={price}
        basketItemsWithPrice={[
          {
            shipment: values,
            price,
          },
        ]}
        displayItemsPriceDetails={displayItemsPriceDetails}
        displayInsurance={displayInsurance}
      />
      <Box
        sx={{
          gap: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {!isSmallScreen && (
          <Button
            actionid={ANALYTICS.PARCEL_DETAILS.CLICK_BACK}
            variant='outlined'
            onClick={onClickBack || handleBack}
            sx={{ flexGrow: 1 }}
          >
            {STRINGS.BACK}
          </Button>
        )}
        <Button
          variant='contained'
          form='parcelDetailsForm'
          onClick={handleNext}
          disabled={submitDisabled}
          sx={{ flexGrow: 1, marginX: { xs: 3, md: 0 } }}
        >
          {STRINGS.NEXT_STEP}
        </Button>
      </Box>
    </Box>
  );
};

Summary.propTypes = {
  displayItemsPriceDetails: PropTypes.bool,
  displayInsurance: PropTypes.bool,
  onClickBack: PropTypes.func,
  onSubmit: PropTypes.func,
  submitDisabled: PropTypes.bool,
};

export default Summary;
