import apis from './apis';

export const getAddressBooks = ({ searchPage, searchPageSize }) =>
  apis.get('addressBook/filter', {
    params: {
      searchPage,
      searchPageSize,
    },
  });

export const getAddressBooksBySearch = ({
  searchPage,
  searchPageSize,
  searchString,
}) =>
  apis.get('addressBook/search', {
    params: {
      searchPage,
      searchPageSize,
      searchString,
    },
  });

export const getDefaultAddress = config =>
  apis.get('addressBook/default', config);

export const getAddressBookById = addressBookId =>
  apis.get(`addressBook/${addressBookId}`);

export const updateAddressBook = ({ addressBookId, addressBookData }) =>
  apis.put(`addressBook/${addressBookId}`, addressBookData);

export const deleteAddressBook = addressBookId =>
  apis.delete(`addressBook/${addressBookId}`);

export const createAddressBook = addressBookData =>
  apis.post('addressBook/', addressBookData);

export const downloadAddressBookTemplate = () =>
  apis.get('addressBook/download-template', { responseType: 'blob' });

export const uploadAddressBookFile = (formData, config) =>
  apis.post('addressBook/upload', formData, config);

export const importAddressBook = config =>
  apis.get('addressBook/import', config);
