import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { configApis } from '../apis';

const initialState = {
  data: {},
};

const fetchConfig = createAsyncThunk('config/fetch', async path => {
  const response = await configApis.fetchConfigByPath({ path });
  return response.data;
});

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

const getConfig = state => state.config.data;
const getInsuranceConfig = state =>
  state.config.data.common?.insurance
    ? Object.entries(state.config.data.common?.insurance)
        .reduce((acc, [insuranceValue, insuranceCost]) => {
          acc.push({ insuranceValue: Number(insuranceValue), insuranceCost });
          return acc;
        }, [])
        .sort((a, b) => a.insuranceValue - b.insuranceValue)
    : {};
const getPriceConfig = state => ({
  ...state.config.data.common?.price,
  insurance: state.config.data.common?.insurance,
});
const getCollectionCutOffConfig = state => state.config.data.common?.cutOff;
const getLinksConfig = state => getConfig(state).ui?.links || {};
const getFeatureConfig = state => getConfig(state).ui?.feature || {};

export default {
  reducer: slice.reducer,
  actions: {
    fetchConfig,
  },
  selectors: {
    getConfig,
    getPriceConfig,
    getCollectionCutOffConfig,
    getInsuranceConfig,
    getLinksConfig,
    getFeatureConfig,
  },
};
