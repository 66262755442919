import { useCallback, useEffect, useState, Fragment } from 'react';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import { isBoolean } from 'lodash';
import { Paper, Typography } from '@mui/material';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { SECTION, SECTION_FIELDS_MAP } from '../constants';

const FormSection = ({
  title,
  section,
  related = [],
  disabled = false,
  children,
}) => {
  const [isActive, setIsActive] = useState();
  const form = useForm();

  const handleFieldsReset = useCallback(() => {
    if (related.length === 0) {
      return;
    }

    const fieldsToReset = related.reduce((acc, section) => {
      const fields = SECTION_FIELDS_MAP[section].map(({ name }) => name);
      return [...acc, ...fields];
    }, []);

    if (fieldsToReset.length) {
      form.batch(() => {
        fieldsToReset.forEach(field => {
          form.change(field, undefined);
        });
      });
    }
  }, [form, related, section]);

  useEffect(() => {
    if (isBoolean(isActive) && !isActive) {
      handleFieldsReset();
    }
  }, [isActive]);

  return (
    <Paper sx={{ p: 2, mb: 2, opacity: disabled ? 0.4 : 1 }}>
      <Typography variant='h3' sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
      {SECTION_FIELDS_MAP[section].map(({ name, focusable = true }) => (
        // eslint-disable-next-line react/jsx-key
        <Fragment key={name}>
          <OnChange name={name}>
            {() => {
              if (!isActive && focusable) {
                setIsActive(true);
              }

              if (!focusable) {
                handleFieldsReset();
              }
            }}
          </OnChange>
          {focusable && (
            <OnBlur name={name}>
              {() => {
                setIsActive(false);
              }}
            </OnBlur>
          )}
        </Fragment>
      ))}
    </Paper>
  );
};

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  section: PropTypes.oneOf([
    SECTION.DELIVERY_ADDRESS,
    SECTION.COLLECTION_ADDRESS,
    SECTION.PARCEL,
    SECTION.DELIVER_TO,
    SECTION.COLLECT_FROM,
    SECTION.NETWORK,
    SECTION.SHIPMENT_DATE,
    SECTION.INSURANCE,
  ]).isRequired,
  related: PropTypes.array,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FormSection;
