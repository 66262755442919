import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { Field, Form } from 'react-final-form';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VERIFICATION } from '../../../constants/analytics';
import {
  BACK,
  ENTER_PIN,
  LOGIN_ERROR,
  NEXT,
  RESEND_PIN,
  RESEND_PIN_WITH_TIMER_$,
  VALIDATE_YOUR_MOBILE,
} from '../../../constants/strings';
import { validateWithJoi } from '../../../utils/validators';
import { verifyPinSchema } from '../../Order/validators/shipment';
import { AuthActions, useAuth } from '../../../features/Auth';
import { useOverlay } from '../../../features/Overlay';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import PinInput from '../components/PinInput';
import { VerifyEmailSlice } from '../SignIn/slice';
import { FIELDS } from '../../../constants/forms';
import { SignUpSlice } from './slice';

function ConfirmPin({ previousStep, values, setValues }) {
  const navigate = useNavigate();
  const auth = useAuth();
  const overlay = useOverlay();
  const dispatch = useDispatch();
  const snackbar = useCustomSnackbar();

  useEffect(() => {
    if (!values.phoneNumber) {
      previousStep();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (values.resendPinTimer > 0) {
        setValues({
          ...values,
          resendPinTimer: values.resendPinTimer - 1,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [values, setValues]);

  const confirmPin = useCallback(
    async ({ pin }, formApi) => {
      if (values.verificationId) {
        overlay.show();
        try {
          const { dpdonline_user_id, customToken } = await auth
            .verifyPin({ pin, verificationId: values.verificationId })
            .unwrap();

          if (dpdonline_user_id) {
            // TODO: clarify if correct reducer for clean up
            await dispatch(VerifyEmailSlice.actions.clearAll());
            await dispatch(SignUpSlice.actions.clearAll());
            await dispatch(
              AuthActions.signInWithCustomToken(customToken)
            ).unwrap();
            navigate('/', { replace: true, state: {} });
          }
        } catch (e) {
          formApi.reset();
          // TODO: Need a message here
          snackbar.showError({
            message: e.message ?? LOGIN_ERROR,
          });
        } finally {
          overlay.hide();
        }
      }
    },
    [auth]
  );

  const handleBack = useCallback(() => {
    previousStep();
  }, [previousStep]);

  const handleResendPin = useCallback(async () => {
    try {
      overlay.show();

      const verificationId = await auth
        .verifyPhoneNumber(values.phoneNumber)
        .unwrap();

      setValues({
        verificationId,
        pin: null,
        resendPinTimer: 60,
        phoneNumber: values.phoneNumber,
      });
    } catch (e) {
      snackbar.showError({
        message: e.message ?? LOGIN_ERROR,
      });
    } finally {
      overlay.hide();
    }
  }, [auth, setValues, values.phoneNumber]);

  return (
    <Trackable
      interfaceId={VERIFICATION.INTERFACE_ID}
      loadId={VERIFICATION.LOAD}
    >
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          validate={values => validateWithJoi(values, verifyPinSchema)}
          onSubmit={confirmPin}
        >
          {({ handleSubmit, invalid, form }) => (
            <>
              <Box
                component='form'
                onSubmit={handleSubmit}
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h5'>{VALIDATE_YOUR_MOBILE}</Typography>
                <Typography variant='body2' sx={{ mt: 3 }}>
                  {ENTER_PIN}
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                    flexGrow: { xs: 1, md: 0 },
                  }}
                >
                  <Field name={FIELDS.PIN.KEY}>
                    {({ input }) => (
                      <PinInput onChange={input.onChange} value={input.value} />
                    )}
                  </Field>
                </Box>
                <Box
                  sx={{
                    mt: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    type='reset'
                    onClick={() => {
                      form.reset();
                      handleResendPin();
                    }}
                    color='primary'
                    disabled={values.resendPinTimer > 0}
                  >
                    {values.resendPinTimer
                      ? RESEND_PIN_WITH_TIMER_$(values.resendPinTimer)
                      : RESEND_PIN}
                  </Button>
                  <Box>
                    <Button
                      sx={{
                        alignSelf: 'flex-end',
                      }}
                      variant='outlined'
                      color='inherit'
                      onClick={handleBack}
                    >
                      {BACK}
                    </Button>
                    <Button
                      sx={{
                        ml: 2,
                        alignSelf: 'flex-end',
                      }}
                      type='submit'
                      variant='contained'
                      disabled={invalid}
                      onClick={handleSubmit}
                    >
                      {NEXT}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Trackable>
  );
}

export default ConfirmPin;
