import { Box, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { SALES_CONDITIONS } from '../../../constants/strings';
import { DPD_URL } from '../../../constants/externalLinks';

const DutyAndTaxInformation = ({ destinationCountry }) => {
  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant='h6'>
        {SALES_CONDITIONS.DUTY_AND_TAX_INFO.TITLE}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant='body2'>
          {SALES_CONDITIONS.DUTY_AND_TAX_INFO.SHIPPING_TO_COUNTRY_$(
            destinationCountry
          )}
        </Typography>
        <Typography variant='body2'>
          {SALES_CONDITIONS.DUTY_AND_TAX_INFO.DESCRIPTION}
        </Typography>
        <Typography variant='body2'>
          {SALES_CONDITIONS.DUTY_AND_TAX_INFO.LINK}
          <Link href={DPD_URL}>dpd.co.uk</Link>
        </Typography>
      </Box>
    </Box>
  );
};

DutyAndTaxInformation.propTypes = {
  destinationCountry: PropTypes.string.isRequired,
};

export default DutyAndTaxInformation;
