import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
} from '@mui/material';
import {
  countriesActions,
  countriesSelectors,
} from '../../../../redux/countriesSlice';

import { ADDRESSES_SECTION } from '../../../../constants/strings';
import { ADDRESS_BOOK_FIELDS } from '../../../../constants/forms';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { DEFAULT_PAGINATION_SEARCH_PAGE_SIZE } from '../../../../constants/pagination';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import AddressDetailsForm from '../../../../components/AddressDetailsForm';
import { PostcodeHelpers } from '../../../../helpers';
import {
  AddressBookUtil,
  AddressUtil,
  FormUtil,
  StringUtil,
  Validators,
} from '../../../../utils';

const StyledCheckbox = styled(Checkbox)(() => ({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const AddressBookForm = ({
  isEditMode,
  onSidebarClose,
  currentPage,
  setPaginationParameters,
  setTotalPages,
  searchPageSize = DEFAULT_PAGINATION_SEARCH_PAGE_SIZE,
  resetSearch = () => {},
}) => {
  const countries = useSelector(countriesSelectors.getCountries);
  const dispatch = useDispatch();

  const { showSuccess } = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();

  const initialFormState = useSelector(
    addressBooksSlice.selectors.getSelectedAddressBook
  );

  const fieldProps = {
    ...FormUtil.fieldTrimOnBlur,
    parse: StringUtil.trimFirstSpace,
  };

  const onSubmit = useCallback(
    async formData => {
      const addressBookData =
        AddressBookUtil.normalizeAddressBookDataForPayload({
          data: formData,
        });

      onSidebarClose();

      try {
        if (isEditMode) {
          await dispatch(
            addressBooksSlice.actions.updateAddressBook({
              addressBookId: initialFormState.addressBookId,
              addressBookData,
            })
          ).unwrap();
        } else {
          await dispatch(
            addressBooksSlice.actions.createAddressBook(addressBookData)
          ).unwrap();
        }

        const data = await dispatch(
          addressBooksSlice.actions.fetchAddressBooks({
            searchPage: currentPage,
            searchPageSize: searchPageSize,
          })
        ).unwrap();

        setPaginationParameters &&
          setPaginationParameters({
            currentPage: data.totalPages,
          });

        setTotalPages && setTotalPages(data.totalPages);

        dispatch(addressBooksSlice.actions.resetSelectedAddressBook());

        showSuccess({
          message: isEditMode
            ? ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.UPDATE
            : ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.CREATE,
          variant: 'default',
        });
      } catch (error) {
        errorHandler(
          isEditMode
            ? ADDRESSES_SECTION.NOTIFICATIONS.ERROR.UPDATE
            : ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CREATE,
          error,
          'AddressBookForm'
        );
      } finally {
        resetSearch();
      }
    },
    [
      currentPage,
      dispatch,
      errorHandler,
      initialFormState.addressBookId,
      isEditMode,
      onSidebarClose,
      showSuccess,
      setPaginationParameters,
      resetSearch,
    ]
  );

  useEffect(() => {
    if (!countries?.length) {
      dispatch(countriesActions.fetchCountries());
    }
  }, [countries?.length, dispatch]);

  return (
    <Box
      sx={{
        padding: '32px 16px 24px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h3'>
        {isEditMode
          ? ADDRESSES_SECTION.SIDEBAR_FORM.EDIT_ADDRESS
          : ADDRESSES_SECTION.SIDEBAR_FORM.ADD_NEW_ADDRESS}
      </Typography>
      <Box
        sx={{
          mt: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initialFormState}
          validate={values => {
            const selectedCountry = AddressUtil.getCountryValue({
              countries,
              countryCode: values.address.countryCode,
            });

            const isPostcodeRequired = PostcodeHelpers.isPostcodeRequired(
              values.address.countryCode,
              countries
            );

            return Validators.validateWithJoi(
              AddressBookUtil.normalizeFormValuesForValidation(values),
              Validators.addressBookSchema(selectedCountry),
              { context: { isPostcodeRequired } }
            );
          }}
          render={({ handleSubmit, pristine, invalid, submitting, values }) => (
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    maxHeight: 'calc(100vh - 148px)',
                    overflow: 'auto',
                    px: '16px',
                    mx: '-16px',
                    pt: 1,
                  }}
                >
                  <form id='addressBookSidebarForm' onSubmit={handleSubmit}>
                    <AddressDetailsForm
                      values={values}
                      validatePostcodeOnCountryChange
                      config={{
                        fields: {
                          addressId: {
                            name: ADDRESS_BOOK_FIELDS.ADDRESS_KEY.KEY,
                            props: { sx: { display: 'none' } },
                          },
                          coordinates: {
                            name: ADDRESS_BOOK_FIELDS.ADDRESS_COORDINATES.KEY,
                            props: { sx: { display: 'none' } },
                          },
                          country: {
                            name: ADDRESS_BOOK_FIELDS.COUNTRY.KEY,
                            label: ADDRESS_BOOK_FIELDS.COUNTRY.LABEL,
                            required: true,
                          },
                          postcode: {
                            name: ADDRESS_BOOK_FIELDS.POSTCODE.KEY,
                            label: ADDRESS_BOOK_FIELDS.POSTCODE.LABEL,
                            required: PostcodeHelpers.isPostcodeRequired(
                              values.address.countryCode,
                              countries
                            ),
                            props: {
                              fieldProps,
                            },
                          },
                          nickname: {
                            name: ADDRESS_BOOK_FIELDS.NICKNAME.KEY,
                            label: ADDRESS_BOOK_FIELDS.NICKNAME.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          organisation: {
                            name: ADDRESS_BOOK_FIELDS.ORGANISATION.KEY,
                            label: ADDRESS_BOOK_FIELDS.ORGANISATION.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          street: {
                            name: ADDRESS_BOOK_FIELDS.ADDRESS.KEY,
                            label: ADDRESS_BOOK_FIELDS.ADDRESS.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          locality: {
                            name: ADDRESS_BOOK_FIELDS.LOCALITY.KEY,
                            label: ADDRESS_BOOK_FIELDS.LOCALITY.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          town: {
                            name: ADDRESS_BOOK_FIELDS.CITY.KEY,
                            label: ADDRESS_BOOK_FIELDS.CITY.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          county: {
                            name: ADDRESS_BOOK_FIELDS.COUNTY.KEY,
                            label: ADDRESS_BOOK_FIELDS.COUNTY.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          contactName: {
                            name: ADDRESS_BOOK_FIELDS.CONTACT_NAME.KEY,
                            label: ADDRESS_BOOK_FIELDS.CONTACT_NAME.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          email: {
                            name: ADDRESS_BOOK_FIELDS.EMAIL.KEY,
                            label: ADDRESS_BOOK_FIELDS.EMAIL.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          phoneNumber: {
                            name: ADDRESS_BOOK_FIELDS.PHONE_NUMBER.KEY,
                            label: ADDRESS_BOOK_FIELDS.PHONE_NUMBER.LABEL,
                            required: true,
                            props: {
                              fieldProps,
                            },
                          },
                          vatNumber: {
                            name: ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY,
                            label: ADDRESS_BOOK_FIELDS.VAT_NUMBER.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          eoriNumber: {
                            name: ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY,
                            label: ADDRESS_BOOK_FIELDS.EORI_NUMBER.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          pidNumber: {
                            name: ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY,
                            label: ADDRESS_BOOK_FIELDS.PID_NUMBER.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                          ukimsNumber: {
                            name: ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
                            label: ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.LABEL,
                            props: {
                              fieldProps,
                            },
                          },
                        },
                        inputSize: 'small',
                        hasActionButtons: false,
                        idPrefix: 'addressBookSidebarForm',
                      }}
                    />
                    <Box sx={{ mt: 2, px: 2 }}>
                      <FormGroup>
                        <Field
                          type='checkbox'
                          name={ADDRESS_BOOK_FIELDS.IS_DEFAULT.KEY}
                        >
                          {({ input }) => (
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  {...input}
                                  // disable isDefault option for non GB countries
                                  disabled={
                                    !AddressUtil.isCountryGB(
                                      values.address.countryCode
                                    ) || initialFormState.isDefault
                                  }
                                />
                              }
                              label={ADDRESS_BOOK_FIELDS.IS_DEFAULT.LABEL}
                            />
                          )}
                        </Field>
                        <Field
                          type='checkbox'
                          name={ADDRESS_BOOK_FIELDS.IS_BILLING.KEY}
                        >
                          {({ input }) => (
                            <FormControlLabel
                              sx={{ mt: 1 }}
                              control={
                                <StyledCheckbox
                                  {...input}
                                  disabled={initialFormState.isBilling}
                                />
                              }
                              label={ADDRESS_BOOK_FIELDS.IS_BILLING.LABEL}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Box>
                  </form>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                }}
              >
                <Button
                  sx={{ flex: 1 }}
                  variant='outlined'
                  onClick={onSidebarClose}
                >
                  {ADDRESSES_SECTION.SIDEBAR_FORM.CANCEL}
                </Button>
                <Button
                  sx={{ ml: 2, flex: 1 }}
                  variant='contained'
                  form='addressBookSidebarForm'
                  type='submit'
                  disabled={pristine || submitting || invalid}
                >
                  {ADDRESSES_SECTION.SIDEBAR_FORM.SAVE}
                </Button>
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

AddressBookForm.propTypes = {
  isEditMode: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  setPaginationParameters: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  resetSearch: PropTypes.func,
};

export default AddressBookForm;
