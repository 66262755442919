import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import EmptySearchResults from '../../components/EmptySearchResults';

import useAnalytics from '../../utils/useAnalytics';
import { TRACK } from '../../constants/analytics';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../features/ErrorBoundary';
import { ErrorUtil } from '../../utils';
import { Sidebar } from './components';

const Track = () => {
  const location = useLocation();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();
  const error = location.state?.error;

  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(TRACK.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={TRACK.LOAD}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          height: '100%',
          flexGrow: 1,
        }}
      >
        <Sidebar />
        {error ? (
          <>
            {ErrorUtil.isNotFoundError(error) ? (
              <EmptySearchResults sx={{ width: '100%' }} />
            ) : (
              <ErrorBoundary
                config={getErrorBoundaryConfig[
                  ERROR_BOUNDARY_TYPES.WE_HAVE_A_LITTLE_PROBLEM
                ](error.message)}
              />
            )}
          </>
        ) : (
          <Outlet />
        )}
      </Box>
    </Trackable>
  );
};

export default Track;
