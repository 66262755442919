import { Box, Typography } from '@mui/material';

import FaceThinkingIcon from '../icons/FaceThinkingIcon';
import { NO_SEARCH_SEARCH_RESULT } from '../../constants/strings';

export default function EmptySearchResults({ button, sx }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 1,
          my: 5,
          ...sx,
        }}
      >
        <FaceThinkingIcon />
        <Typography variant='h3'>{NO_SEARCH_SEARCH_RESULT}</Typography>
        {button}
      </Box>
    </>
  );
}
