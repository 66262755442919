import apis from './apis';

export const getVoucherByCode = async voucherCode => {
  const { data } = await apis.get(`vouchers/`, {
    params: {
      voucherCode,
    },
  });
  return data;
};

export const getVoucherById = async voucherId => {
  const { data } = await apis.get(`vouchers/${voucherId}`);
  return data;
};
