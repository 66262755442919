import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import Joi from 'joi';
import { OrderSliceSelectors } from '../../slice';
import {
  getMaxWeightAndParcelNumber,
  getNetworksByParcelSizeAndWeight,
  sortNetworksByPrice,
} from '../../../../utils/networks';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { POINT_TYPE } from '../../../../constants';
import { parcelSchema } from '../../validators/parcel';
import { requireKeysSchema } from '../../../../utils/validators';
import {
  REQUIRED_TYPE,
  WEEKEND_NETWORKS,
} from '../../../../constants/shipment';
import { isFriOrSat } from '../../../../utils/date';
import { NetworkHelpers, ShipmentHelpers } from '../../../../helpers';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import { isFlagOptional } from '../../../../helpers/shipment';

export const getOutboundNetworkParameters = createSelector(
  OrderSliceSelectors.getValues,
  NetworkHelpers.getNetworkQuery
);

export const getNetworksByParcelParams = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  ({ networks }, values) => getNetworksByParcelSizeAndWeight(networks, values)
);

export const getFilteredNetworks = createSelector(
  [getNetworksByParcelParams, OrderSliceSelectors.getValues],
  (networks, values) => {
    let filteredNetworks = networks;
    const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
    const deliveryTo = get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);

    const shippingDate = get(values, SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
    // Only show Sat or Sun options if the selected collection date is Fri or Sat
    if (!isFriOrSat(new Date(shippingDate))) {
      filteredNetworks = networks.filter(
        ({ networkKey }) => !WEEKEND_NETWORKS.includes(networkKey)
      );
    }

    if (deliveryTo === POINT_TYPE.SHOP) {
      filteredNetworks = filteredNetworks.filter(({ networkDetails }) =>
        isFlagOptional(networkDetails, 'shipToPickup')
      );
    } else {
      filteredNetworks = filteredNetworks.filter(({ networkDetails }) =>
        collectFrom === POINT_TYPE.SHOP
          ? !networkDetails?.premium &&
            !isFlagOptional(networkDetails, 'shipToPickup')
          : !isFlagOptional(networkDetails, 'shipToPickup')
      );
    }

    return sortNetworksByPrice(filteredNetworks);
  }
);

export const getMaxWeightAndNumberOfParcels = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (references, values) => {
    const dropAtShop = get(values, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);

    return getMaxWeightAndParcelNumber(dropAtShop, references);
  }
);

export const validateParcelsValues = createSelector(
  [OrderSliceSelectors.getValues, getMaxWeightAndNumberOfParcels],
  (values, context) => {
    const { error } = Joi.array()
      .items(
        requireKeysSchema(parcelSchema(context), [
          '_weight',
          '_quantity',
          '_value',
          '_width',
          '_height',
          '_length',
        ])
      )
      .min(1)
      .validate(get(values, SHIPMENT_FIELDS.PARCELS.KEY));

    return error;
  }
);

export const getParcelDetailsRequiredFields = createSelector(
  [
    OrderSliceSelectors.getValues,
    OrderSliceSelectors.getReferences,
    countriesSelectors.getCountries,
  ],
  ShipmentHelpers.getParcelDetailsRequiredFields
);

export const getParcelDetailsRequiredFieldsMap = createSelector(
  [getParcelDetailsRequiredFields],
  requiredFields =>
    requiredFields.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {})
);

export const getShipToShopAvailable = createSelector(
  [getNetworksByParcelParams],
  networks =>
    networks.some(
      ({ networkDetails }) =>
        networkDetails.shipToPickup === REQUIRED_TYPE.OPTIONAL
    )
);
