import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { referenceApis } from '../apis';
import { InvoiceHelpers } from '../helpers';

const initialState = {
  data: [],
};

const fetchCurrencies = createAsyncThunk('currencies/fetch', async () => {
  const response = await referenceApis.fetchCurrencies();
  return InvoiceHelpers.sortCurrencies(response.data);
});

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchCurrencies.rejected, state => {
      state.data = [];
    });
  },
});

const getCurrenciesState = state => state.currencies || [];

const getCurrencies = createSelector([getCurrenciesState], ({ data }) =>
  data?.length ? data : []
);

const getCurrenciesKeyValue = createSelector(
  getCurrencies,
  InvoiceHelpers.getCurrenciesKeyValue
);

export const CurrenciesReducer = currenciesSlice.reducer;

export const CurrenciesActions = {
  fetchCurrencies,
};

export const CurrenciesSelectors = {
  getCurrencies,
  getCurrenciesKeyValue,
};
