import axios from 'axios';
import { AUTH_BASE_URL } from '../constants/env';

const baseURL = AUTH_BASE_URL;

const apis = axios.create({
  baseURL,
  withCredentials: true,
  transformResponse: axios.defaults.transformResponse.concat(
    params => params.data || params
  ),
});

export const createResponseInterceptor = (onFulfilled, onRejected) => {
  apis.interceptors.response.use(onFulfilled, onRejected);
};

export const sendMagicLink = (email, recaptchaToken) =>
  apis.post(
    '/auth/sendMagicLink',
    {
      email,
    },
    {
      headers: {
        'X-Firebase-AppCheck': recaptchaToken,
      },
      baseURL,
    }
  );

export const verifyMagicLink = verificationId =>
  apis.get('/auth/verifyMagicLink', {
    params: {
      verificationId,
    },
    baseURL,
  });

export const getSession = async () => apis.get('/auth/session', { baseURL });

export const login = async token =>
  apis.post(
    '/auth/session/login',
    {
      idToken: token,
    },
    { baseURL }
  );

export const logout = () => apis.post('/auth/session/logout', {}, { baseURL });

export const verifyPhoneNumber = (phoneNumber, recaptchaToken) =>
  apis.post(
    '/auth/verifyPhoneNumber',
    {
      phoneNumber,
    },
    {
      baseURL,
      headers: {
        'X-Firebase-AppCheck': recaptchaToken,
      },
    }
  );

export const verifyPin = (verificationId, pin) =>
  apis.post(
    '/auth/confirmVerificationCode',
    {
      verificationId,
      code: pin,
    },
    {
      baseURL,
    }
  );

export const replicateConsumerUser = () =>
  apis.post('/auth/replicateConsumerUser', null, {
    baseURL,
  });
