import { isNil } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import addressBooksSlice from '../../redux/addressBooksSlice';
import { BasketSelectors } from '../../redux/basket';
import userSlice from '../../redux/userSlice';
import { AddressUtil, Formatters } from '../../utils';
import { FORM } from '../../constants';
import { SHIPMENT_FIELDS } from '../../constants/forms';
import { GB } from '../../constants/strings';

const defaultEmptyAddress = {
  organisation: '',
  // property: deliveryAddress.property, there is no mapping on server side
  street: '',
  locality: '',
  town: '',
  county: '',
  postcode: '',
  countryCode: '',
  [FORM.FIELDS.ADDRESS_KEY.KEY]: '',
};

export const getDeliveryAddress = (
  _state,
  { deliveryCountryCode, deliveryAddress }
) => {
  if (!deliveryAddress) {
    return defaultEmptyAddress;
  }
  const { addressLine1, addressLine2 } = AddressUtil.transformAddressValues({
    street: deliveryAddress.street,
    locality: deliveryAddress.locality,
    property: deliveryAddress.property,
  });
  return {
    organisation: deliveryAddress.organisation,
    // property: deliveryAddress.property, there is no mapping on server side
    street: addressLine1,
    locality: addressLine2,
    town: deliveryAddress.town,
    county: deliveryAddress.county,
    postcode: deliveryAddress.postcode,
    countryCode: deliveryCountryCode,
    [FORM.FIELDS.ADDRESS_KEY.KEY]: deliveryAddress.key,
    _coordinates: AddressUtil.formatCoordinates(deliveryAddress),
  };
};

export const getDefaultCollectionAddress = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  (_state, { collectionDetailedAddress }) => collectionDetailedAddress,
  (defaultAddress, collectionDetailedAddress) =>
    // Return only country in case if default address is unavailable
    isNil(defaultAddress)
      ? {
          countryCode: GB,
        }
      : {
          addressId: collectionDetailedAddress?.Key,
          countryCode: defaultAddress.countryCode,
          postcode: defaultAddress.postcode,
          county: defaultAddress.addressLine4,
          town: defaultAddress.addressLine3,
          organisation: defaultAddress.organisation,
          street: defaultAddress.addressLine1,
          locality: defaultAddress.addressLine2,
          _coordinates: collectionDetailedAddress
            ? AddressUtil.formatCoordinates(collectionDetailedAddress)
            : null,
        }
);

export const getDefaultCollectionContactDetails = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  userSlice.selectors.getAccountDetailsData,
  (defaultAddress, accountDetails) => {
    if (!isNil(defaultAddress)) {
      return {
        _addressBookId: defaultAddress.addressBookId,
        contactName: defaultAddress.contactName,
        telephone: defaultAddress.phoneNumber,
        email: defaultAddress.email,
      };
    }

    if (!isNil(accountDetails)) {
      return {
        _addressBookId: null,
        contactName: Formatters.joinStringsWith(
          [accountDetails.firstName, accountDetails.lastName],
          ' '
        ),
        telephone: accountDetails.phone,
        email: accountDetails.email,
      };
    }

    return null;
  }
);

export const getShipmentDto = createStructuredSelector({
  [SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY]: getDeliveryAddress,
  [SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY]: getDefaultCollectionAddress,
  'outboundConsignment.collectionDetails.contactDetails':
    getDefaultCollectionContactDetails,
  'outboundConsignment.shippingRef2': BasketSelectors.getBasketId,
  [SHIPMENT_FIELDS.PARCELS.KEY]: () => [
    {
      _parcelId: uuidv4(),
    },
  ], // NOTE: required in case  with default addressBook
});
