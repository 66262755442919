import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';
import useBackdropController from './useBackdropController';
import OverlayContext from './Context';

const Provider = ({ children }) => {
  const [open, show, hide, showWhile] = useBackdropController();
  const contextValue = React.useMemo(
    () => ({
      show,
      hide,
      showWhile,
    }),
    [show, hide, showWhile]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      <Backdrop open={open} sx={{ zIndex: theme => theme.zIndex.tooltip + 1 }}>
        <CircularProgress
          className='dpd-online-spinner'
          data-testId='dpdOnlineSpinner'
        />
      </Backdrop>
    </OverlayContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  spinner: PropTypes.func,
};

export default Provider;
