import { useEffect, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { styled } from '@mui/system';
import { useTheme } from '@emotion/react';
import {
  calculateMapFitBounds,
  getPickupAddressPoint,
  getPickupLocationCode,
} from '../../../utils/pickupPoint';
import { getGoogleMapsDirectionLink } from '../../../utils/googleMaps';
import { NO_RESULT } from '../../../constants/strings';
import { POSTCODE } from '../constants';
import { getURLParam } from '../../../utils/url';
import PickupPointItem from './PickupPointItem';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.primary,
  width: '90%',
}));

const LoadingBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '50%',
  alignItems: 'center',
}));

const PickupPointsList = ({
  mapRef,
  currentLocation,
  pickupPoints,
  activePickupPoint,
  setActivePickupPoint,
  onSelectClick = noop,
  className,
  pickupListRef,
  hasFilteredData = false,
  pickupMapPadding,
  onDirectionsClick = noop,
  loading,
  isModal = false,
  onChange,
  hasFilteredLocations,
  showSelectButton,
}) => {
  const theme = useTheme();
  const urlPostcode = getURLParam(POSTCODE);

  const selectPickupPoint = pickupPoint => {
    onSelectClick(pickupPoint);
  };

  useEffect(() => {
    if (hasFilteredData && window.innerWidth <= 768) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hasFilteredData]);

  const fitBounds = useMemo(() => {
    if (currentLocation && pickupPoints.length !== 0) {
      return calculateMapFitBounds({
        currentLocation,
        pickupLocations: pickupPoints,
      });
    }
    return null;
  }, [currentLocation, pickupPoints]);

  useEffect(() => {
    if (mapRef.current && fitBounds) {
      mapRef.current.fitBounds(fitBounds, {
        padding: pickupMapPadding,
        duration: 2000,
      });
    }
  }, [fitBounds, pickupMapPadding]);

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color='primary' />
      </LoadingBox>
    );
  }

  return (
    <StyledBox className={className} ref={pickupListRef}>
      {((urlPostcode || currentLocation) && !hasFilteredLocations) ||
      (isModal && !hasFilteredLocations) ? (
        <Box sx={{ padding: theme.spacing(2, 3) }}>
          <Typography variant='h3'>{NO_RESULT.title}</Typography>
          <StyledTypography variant='body1'>
            {NO_RESULT.description}
          </StyledTypography>
        </Box>
      ) : (
        pickupPoints.map(pickupPoint => {
          const pickupCode = getPickupLocationCode(pickupPoint);
          const isActive =
            pickupCode === getPickupLocationCode(activePickupPoint);

          const directionLink =
            currentLocation?.latitude && currentLocation?.longitude
              ? getGoogleMapsDirectionLink(
                  currentLocation,
                  getPickupAddressPoint(pickupPoint)
                )
              : '';

          return (
            <PickupPointItem
              showSelectButton={showSelectButton}
              key={pickupCode}
              number={pickupPoint.number}
              pickupPoint={pickupPoint}
              onSelectClick={() => selectPickupPoint(pickupPoint)}
              setActivePickupPoint={setActivePickupPoint}
              directionLink={directionLink}
              isActive={isActive}
              onDirectionsClick={onDirectionsClick}
              isModal={isModal}
              onChange={onChange}
            />
          );
        })
      )}
    </StyledBox>
  );
};

PickupPointsList.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  pickupPoints: PropTypes.array.isRequired,
  activePickupPoint: PropTypes.object,
  setActivePickupPoint: PropTypes.func,
  onSelectClick: PropTypes.func,
  onPickupDetailsClick: PropTypes.func,
  pickupListRef: PropTypes.any,
  className: PropTypes.string,
  responseError: PropTypes.bool,
  onDirectionsClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  hasFilteredData: PropTypes.bool.isRequired,
  showSelectButton: PropTypes.bool,
};

export default PickupPointsList;
