import { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Button, FormControl, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { FORM, STRINGS } from '../../../constants';

const CommoditySearch = ({
  onSubmit,
  initialValues = { commodity: '' },
  searchValue,
  disabled,
  clearInput,
  handleChange,
}) => {
  const formRef = useRef();

  return (
    <Box sx={{ padding: 2 }}>
      <Form
        onSubmit={onSubmit}
        ref={formRef}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <Box
            component='form'
            autoComplete='off'
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <FormControl
              fullWidth
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Field name={FORM.FIELDS.COMMODITY.KEY}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      id='commodity-code'
                      label={FORM.FIELDS.COMMODITY.LABEL}
                      placeholder={FORM.FIELDS.COMMODITY.PLACEHOLDER}
                      fullWidth
                      size='medium'
                      error={!!(meta.touched && meta.error)}
                      {...input}
                      value={searchValue}
                      helperText={
                        meta.touched && meta.error
                          ? meta.error
                          : STRINGS.COMMODITY_FINDER.SUBTITLE
                      }
                      onChange={e => {
                        handleChange(e, input);
                      }}
                      sx={{
                        borderRadius: '4px',
                        height: '40px',
                        mr: 1,
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                        '& .MuiOutlinedInput-root': {
                          padding: '0',
                        },
                        '& .MuiInputLabel-root': {
                          top: '-6px',
                        },
                        '& .MuiFormHelperText-root': {
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          marginLeft: '8px',
                          maxWidth: {
                            xs: '100%',
                            sm: '75%',
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={clearInput}>
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                    <Button
                      variant='contained'
                      onClick={handleSubmit}
                      disabled={disabled}
                      sx={{
                        height: '40px',
                      }}
                      type='submit'
                    >
                      {STRINGS.COMMODITY_FINDER.SEARCH_BUTTON}
                    </Button>
                  </>
                )}
              </Field>
            </FormControl>
          </Box>
        )}
      />
    </Box>
  );
};

CommoditySearch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    commodity: PropTypes.string,
  }),
  searchValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  clearInput: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CommoditySearch;
