import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HttpStatusCode } from 'axios';

import Sentry, { getSentryUser } from '../../sentry';
import { createResponseInterceptor } from '../../apis/apis';
import Loader from '../Loader';
import Error from '../../pages/Auth/SignIn/Error';
import { formatTime } from '../../utils/formatters';
import { TOO_MANY_REQUESTS_MESSAGE } from '../../constants/strings';
import { getAuthData } from './selectors';
import { AuthContext } from './Context';
import * as Actions from './actions';
import * as AuthModel from './model';

const AuthProvider = props => {
  const dispatch = useDispatch();
  const { currentSession } = useSelector(getAuthData);
  const [isAppRestricted, setIsAppRestricted] = useState(false);
  const [retryAfter, setRetryAfter] = useState();
  const isSessionExpirationHandled = useRef(false);

  const value = useMemo(() => {
    const actions = bindActionCreators(Actions, dispatch);
    return {
      ...actions,
      currentSession,
    };
  }, [dispatch, currentSession]);

  useEffect(() => {
    Sentry.setUser(getSentryUser(currentSession?.uid));
  }, [currentSession?.uid]);

  useEffect(
    () => () => {
      isSessionExpirationHandled.current = false;
    },
    []
  );

  const fetchCurrentSession = useCallback(async () => {
    if (isAppRestricted) {
      return Promise.reject({
        code: HttpStatusCode.TooManyRequests,
        message: TOO_MANY_REQUESTS_MESSAGE(formatTime(retryAfter)),
      });
    }

    const currentSession = await dispatch(Actions.getCurrentSession()).unwrap();
    if (
      AuthModel.isConsumerAccount(currentSession) &&
      !AuthModel.isDpdUserAccount(currentSession)
    ) {
      await dispatch(Actions.replicateConsumerUser()).unwrap();
    }
  }, [currentSession?.uid, isAppRestricted, retryAfter]);

  const handleErrorListener = useCallback(
    async error => {
      if (
        error.response &&
        error.response.status === 401 &&
        !isSessionExpirationHandled.current
      ) {
        isSessionExpirationHandled.current = true;
        await dispatch(Actions.getCurrentSession()).unwrap();
        dispatch({ type: 'auth/clear' });
      } else {
        if (
          error.response &&
          error.response.status === HttpStatusCode.TooManyRequests
        ) {
          setIsAppRestricted(true);
          setRetryAfter(error.response.data.error.retryAfter);
        }

        return Promise.reject(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    createResponseInterceptor(response => response, handleErrorListener);
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Loader
        promiseFn={fetchCurrentSession}
        watch={currentSession?.uid}
        errorComponent={error => <Error values={{ error }} />}
      >
        {props.children}
      </Loader>
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
