import { SvgIcon } from '@mui/material';

const GooglePay = props => (
  <SvgIcon {...props} viewBox='0 0 26 12'>
    <path
      d='M12.1439 8.83912H11.1992V1.48844H13.7036C14.3383 1.48844 14.8795 1.70001 15.3223 2.12314C15.7749 2.54627 16.0012 3.06288 16.0012 3.67298C16.0012 4.29784 15.7749 4.81445 15.3223 5.23266C14.8844 5.65087 14.3432 5.85752 13.7036 5.85752H12.1439V8.83912ZM12.1439 2.39375V4.95713H13.7232C14.0972 4.95713 14.4121 4.82921 14.6581 4.57828C14.909 4.32736 15.0369 4.02231 15.0369 3.6779C15.0369 3.33841 14.909 3.03828 14.6581 2.78736C14.4121 2.52659 14.1021 2.39866 13.7232 2.39866H12.1439V2.39375Z'
      fill='#3C4043'
    />
    <path
      d='M18.471 3.64346C19.1697 3.64346 19.7208 3.83042 20.1242 4.20435C20.5277 4.57828 20.7294 5.08998 20.7294 5.73943V8.83912H19.829V8.14046H19.7896C19.4009 8.71611 18.8794 9.00148 18.23 9.00148C17.674 9.00148 17.2115 8.83912 16.8376 8.50947C16.4636 8.17982 16.2767 7.77145 16.2767 7.27943C16.2767 6.7579 16.4735 6.34461 16.8671 6.03956C17.2607 5.72959 17.7872 5.57707 18.4415 5.57707C19.0024 5.57707 19.4649 5.68039 19.8241 5.88704V5.67055C19.8241 5.3409 19.6962 5.06537 19.4354 4.83413C19.1746 4.60288 18.8696 4.48972 18.5202 4.48972C17.9938 4.48972 17.5756 4.71113 17.2705 5.15886L16.439 4.63732C16.8966 3.97311 17.5756 3.64346 18.471 3.64346ZM17.2509 7.29419C17.2509 7.5402 17.3542 7.74685 17.5657 7.90921C17.7724 8.07158 18.0184 8.15522 18.2988 8.15522C18.6974 8.15522 19.0516 8.00761 19.3616 7.71241C19.6716 7.4172 19.829 7.07279 19.829 6.67426C19.5338 6.44301 19.1254 6.32493 18.599 6.32493C18.2152 6.32493 17.8954 6.41841 17.6395 6.60046C17.3788 6.79234 17.2509 7.02359 17.2509 7.29419Z'
      fill='#3C4043'
    />
    <path
      d='M25.866 3.80587L22.7171 11.0483H21.743L22.914 8.51444L20.8377 3.80587H21.866L23.3617 7.41724H23.3814L24.8377 3.80587H25.866Z'
      fill='#3C4043'
    />
    <path
      d='M8.38634 5.26218C8.38634 4.95418 8.35878 4.65947 8.30761 4.37607H4.34791V5.99971L6.62838 6.0002C6.53588 6.54043 6.23822 7.00096 5.78212 7.30797V8.36137H7.13958C7.93221 7.62778 8.38634 6.54339 8.38634 5.26218Z'
      fill='#4285F4'
    />
    <path
      d='M5.7826 7.30802C5.40474 7.56289 4.91814 7.71197 4.34889 7.71197C3.24924 7.71197 2.31639 6.971 1.98231 5.97221H0.582052V7.05857C1.27579 8.43523 2.70163 9.37989 4.34889 9.37989C5.4874 9.37989 6.44387 9.00547 7.14006 8.36093L5.7826 7.30802Z'
      fill='#34A853'
    />
    <path
      d='M1.85041 5.16623C1.85041 4.88578 1.89715 4.61469 1.98227 4.35982V3.27346H0.58201C0.295168 3.84272 0.133789 4.48529 0.133789 5.16623C0.133789 5.84718 0.29566 6.48975 0.58201 7.05901L1.98227 5.97264C1.89715 5.71778 1.85041 5.44668 1.85041 5.16623Z'
      fill='#FABB05'
    />
    <path
      d='M4.34889 2.62007C4.97029 2.62007 5.52676 2.8341 5.96612 3.25231L7.16909 2.05032C6.43845 1.36987 5.48592 0.952148 4.34889 0.952148C2.70213 0.952148 1.27575 1.89681 0.58201 3.27346L1.98227 4.35982C2.31635 3.36104 3.24924 2.62007 4.34889 2.62007Z'
      fill='#E94235'
    />
  </SvgIcon>
);

export default GooglePay;
