import { createAsyncThunk } from '@reduxjs/toolkit';

import { basketApis, paymentApis } from '../../apis';
import { getBasketId } from './selectors';

export const createBasket = createAsyncThunk('basket/create', async () => {
  const response = await basketApis.createBasket();
  return response.data;
});

export const getBasketItems = createAsyncThunk(
  'basket/getBasketItems',
  async (_, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.getBasketItems(basketId);
    return response.data;
  }
);

export const createBasketItem = createAsyncThunk(
  'basket/createBasketItem',
  async (shipment, { getState, rejectWithValue }) => {
    try {
      const basketId = getBasketId(getState());
      const response = await basketApis.createBasketItem(basketId, shipment);
      return { basketId, basketItemId: response.data?.basketItemId, shipment };
    } catch (err) {
      if (err.response?.data?.error) {
        return rejectWithValue(err.response.data.error);
      } else {
        throw err;
      }
    }
  }
);

export const fetchBasketItem = createAsyncThunk(
  'basket/fetchBasketItem',
  async (basketItemId, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.fetchBasketItem(basketId, basketItemId);
    return response.data;
  }
);

export const duplicateBasketItem = createAsyncThunk(
  'basket/duplicateBasketItem',
  async (parcelCode, { getState, rejectWithValue }) => {
    try {
      const basketId = getBasketId(getState());

      const duplicateResponse = await basketApis.duplicateBasketItem(
        basketId,
        parcelCode
      );

      const fetchResponse = await basketApis.fetchBasketItem(
        basketId,
        duplicateResponse?.data.basketItemId
      );

      return fetchResponse.data;
    } catch (error) {
      if (error.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

export const updateBasketItem = createAsyncThunk(
  'basket/updateBasketItem',
  async ({ basketItemId, shipment }, { getState, rejectWithValue }) => {
    try {
      const basketId = getBasketId(getState());
      await basketApis.updateBasketItem(basketId, basketItemId, shipment);
      return { basketId, basketItemId, shipment };
    } catch (error) {
      if (error.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

export const deleteBasketItem = createAsyncThunk(
  'basket/deleteBasketItem',
  async (basketItemId, { getState }) => {
    const basketId = getBasketId(getState());
    const response = await basketApis.deleteBasketItem(basketId, basketItemId);
    return response.data;
  }
);

export const checkout = createAsyncThunk(
  'basket/checkout',
  async ({ basketItemIds, purchaseData }, { getState, rejectWithValue }) => {
    try {
      const basketId = getBasketId(getState());
      return await paymentApis.verifyAndPay(
        basketId,
        basketItemIds,
        purchaseData
      );
    } catch (error) {
      if (error.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);
