import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { referenceApis } from '../apis';

const initialState = {
  data: [],
};

const fetchNiRequiredFields = createAsyncThunk(
  'niRequiredFields/fetch',
  async query => {
    const response = await referenceApis.fetchNiRequiredFields(query);
    return response.data;
  }
);

const niRequiredFieldsSlice = createSlice({
  name: 'niRequiredFields',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNiRequiredFields.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchNiRequiredFields.rejected, state => {
      state.data = [];
    });
  },
});

const getNiRequiredFieldsState = state => state.niRequiredFields || [];

const getNiRequiredFields = createSelector(
  [getNiRequiredFieldsState],
  ({ data }) => (data?.length ? data : [])
);

export const NiRequiredFieldsReducer = niRequiredFieldsSlice.reducer;

export const NiRequiredFieldsActions = {
  fetchNiRequiredFields,
};

export const NiRequiredFieldsSelectors = {
  getNiRequiredFields,
};
