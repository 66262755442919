import moment from 'moment-timezone';

import { FORM } from '../../constants';
import { DAYS_LIMIT_FOR_CLAIM_PROCESSING } from './constants';

export const isMissingFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.MISSING_CONTENTS;

export const isDamagedFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.DAMAGED;

export const isNotArrivedFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.NOT_ARRIVED;

export const isClaimInvalid = deliveredDate => {
  const currentDate = moment();

  return (
    deliveredDate &&
    currentDate.diff(deliveredDate, 'days') > DAYS_LIMIT_FOR_CLAIM_PROCESSING
  );
};

export const getValidationContext = issueType => {
  if (!issueType) {
    return null;
  }

  return {
    isGoodsValueRequired: !isMissingFlow(issueType),
    isPackageDescriptionRequired: isNotArrivedFlow(issueType),
    isAdditionalInfoRequired: isNotArrivedFlow(issueType),
    isDamageDescriptionRequired: !isNotArrivedFlow(issueType),
    isMissingDescriptionRequired: isMissingFlow(issueType),
    isDamagedItemRequired: isDamagedFlow(issueType),
  };
};

export const resetFields = (fields, form) => {
  fields.forEach(field => {
    if (form.getFieldState(field)) {
      form.change(field, undefined);
      form.resetFieldState(field);
    }
  });
};

export const normalizeClaimData = data => ({
  parcelCode: data.parcelCode,
  postcode: data.postCode,
  reasonForContact: data.reasonForContact,
  contactDetails: {
    name: data.contactDetails?.name,
    email: data.contactDetails?.email,
    phone: data.contactDetails?.phone || '',
  },
  parcelContents: data.parcelContents,
  goodsValue: data.goodsValue || '',
  packageDescription: data.packageDescription || '',
  additionalInformation: data.additionalInformation || '',
  damageDescription: data.damageDescription || '',
  damagedItem: data.damagedItem || '',
  missingDescription: data.missingDescription || '',
  attachedImages:
    data.attachedImages?.map(image => ({
      image: image.image,
      fileName: image.fileName,
      contentType: image.contentType,
    })) || [],
});
