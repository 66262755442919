import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isEmpty } from 'lodash';
import { STRINGS } from '../../../constants';
import FaceThinkingIcon from '../../../components/icons/FaceThinkingIcon';
import { CommodityHelpers } from '../helpers';
import { StringUtil } from '../../../utils';

const CommodityList = ({
  commodities,
  levels = [],
  setCommoditySelected,
  pristine,
  onClose,
  searchCommodities,
}) => {
  const theme = useTheme();

  const renderCommodity = (commodityCode, currentLevel = 0) => {
    const commodityData = commodities[commodityCode];
    if (!commodityData) {
      return null;
    }

    const commodity = {
      ...commodityData,
      description: StringUtil.replaceHtmlTags(commodityData.description),
    };
    const isDisabled = !commodity.applyCode;
    const codePairs = CommodityHelpers.splitCode(commodity.code);

    return (
      <Box
        key={commodityCode}
        sx={{
          mt: 2,
          padding: '10px',
          border: `1px solid ${theme.palette.primary.iconGray}`,
          marginLeft: `${currentLevel * 20}px`,
          borderRadius: '4px',
          color: isDisabled ? 'gray' : 'black',
          background: 'transparent',
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      >
        <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>
          {commodity.description}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          {codePairs.map((pair, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: CommodityHelpers.getBackgroundColor(
                  index,
                  theme
                ),
                padding: '8px',
                margin: '4px',
                borderRadius: '4px',
                color: theme.palette.text.primary,
                pointerEvents: isDisabled ? 'auto' : 'none',
                cursor: isDisabled ? 'pointer' : 'default',
              }}
            >
              <Typography
                onClick={() => {
                  if (isDisabled) {
                    searchCommodities({ commodityCode: commodity.code });
                  }
                }}
              >
                {pair}
              </Typography>
            </Box>
          ))}
          {!isDisabled && (
            <Button
              onClick={() => {
                setCommoditySelected({
                  commodity: commodity,
                  commodityCode: commodityCode,
                });
                onClose();
              }}
              sx={{ ml: 1 }}
              variant='outlined'
            >
              {STRINGS.SELECT}
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  const renderLevels = () => {
    let previousLevel = 0;

    return levels.map((level, index) => {
      const levelParts = level.split('.').filter(Boolean);

      if (level.startsWith('..')) {
        return levelParts.map((commodityCode, idx) => (
          <Box key={`${commodityCode}-${index}-${idx}`} sx={{ mt: 2 }}>
            {renderCommodity(commodityCode, previousLevel)}
          </Box>
        ));
      } else {
        return levelParts.map((commodityCode, idx) => {
          const currentLevel = idx;
          previousLevel = currentLevel;
          return (
            <Box key={`${commodityCode}-${index}-${idx}`} sx={{ mt: 2 }}>
              {renderCommodity(commodityCode, currentLevel)}
            </Box>
          );
        });
      }
    });
  };

  const renderCommodities = commodities =>
    Object.keys(commodities).map(commodityCode =>
      renderCommodity(commodityCode, levels?.length)
    );

  if (!pristine && !levels?.length) {
    return (
      <Box
        sx={{
          paddingX: '8px',
          minHeight: '45vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FaceThinkingIcon />
        <Typography sx={{ mb: 2 }} variant='h3'>
          {STRINGS.COMMODITY_FINDER.NO_RESULT.TITLE}
        </Typography>
        <Box>
          <Typography variant='body2'>
            {STRINGS.COMMODITY_FINDER.NO_RESULT.SUBTITLE}
          </Typography>
          <Typography variant='body2'>
            {STRINGS.COMMODITY_FINDER.NO_RESULT.DESCRIPTION}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingX: '8px', minHeight: '45vh', overflow: 'scroll' }}>
      {!isEmpty(levels) ? renderLevels() : renderCommodities(commodities)}
    </Box>
  );
};

CommodityList.propTypes = {
  commodities: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  setCommoditySelected: PropTypes.func,
  pristine: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  searchCommodities: PropTypes.func,
};

export default CommodityList;
