import { createContext, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
// import { purgeStoredState } from 'redux-persist';
import { useNavigate } from 'react-router';
import useErrorHandler from '../../hooks/useErrorHandler';
import * as Actions from '../../features/Auth/actions';
// import { persistor, persistConfig } from '../../redux/store';
import { AUTH } from '../../constants/strings';
import { useOverlay } from '../Overlay';
import { AUTH_MISSING_COOKIE } from '../../constants/error';

export const AuthContext = createContext({});

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();
  const overlay = useOverlay();

  const signOut = useCallback(
    // Redirect to Home by default
    async (redirectUrl = '/') => {
      // Signout
      try {
        overlay.show();

        await dispatch(Actions.logoutSession()).unwrap();
      } catch (error) {
        // Don't notify about cookie expired/missing error
        if (![AUTH_MISSING_COOKIE].includes(error.code)) {
          errorHandler(AUTH.SIGNOUT_ERROR, error, 'SignOut');
        }
      } finally {
        overlay.hide();
        navigate(redirectUrl);
      }
    },
    [dispatch, errorHandler, navigate]
  );

  return {
    ...auth,
    signOut,
  };
};

// eslint-disable-next-line react/display-name, react-refresh/only-export-components
export const withAuth = Component => props =>
  (
    <AuthContext.Consumer>
      {context => <Component {...props} auth={context} />}
    </AuthContext.Consumer>
  );
