import { createSelector } from '@reduxjs/toolkit';

import { OrderSliceSelectors } from '../../slice';
import { ExportReasonsSelectors } from '../../../../redux/exportReasonSlice';
import { InvoiceHelpers, ShipmentHelpers } from '../../../../helpers';
import { ObjectUtil } from '../../../../utils';
import { getActivePickupPoints, getAllowedFields } from './helpers';

export const getRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  ShipmentHelpers.getAddressDetailsRequiredFields
);

export const getRequiredData = createSelector(
  getRequiredFields,
  requiredFields => ({
    requiredFields,
    requiredKeys: ObjectUtil.convertObjectKeysToArray(requiredFields),
  })
);

export const getAvailableFields = createSelector(
  [
    OrderSliceSelectors.getValues,
    OrderSliceSelectors.getReferences,
    (state, currentSession) => currentSession,
  ],
  getAllowedFields
);

export const getPickupPoints = createSelector(
  OrderSliceSelectors.getReferences,
  getActivePickupPoints
);

export const getExportReasonsKeyValue = createSelector(
  ExportReasonsSelectors.getExportReasons,
  OrderSliceSelectors.getValues,
  InvoiceHelpers.filterExportReasons
);
