import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';
import { getProductRowBgColor } from '../helpers';

const ProductTableItem = ({ item, column }) => {
  const { path, render, bodyColspan, align } = column;

  if (!path && !render) {
    return null;
  }

  return (
    <TableCell
      sx={{ p: 2, whiteSpace: !item[path] && 'nowrap' }}
      colSpan={bodyColspan}
      align={align}
    >
      {path ? item[path] || '--' : render(item, item.isDefaultValue)}
    </TableCell>
  );
};

const StyledTableRow = styled(props => (
  <TableRow {...omit(props, ['isInvalid', 'isSelectable', 'isSelected'])} />
))(({ theme, isInvalid, isSelectable, isSelected }) => {
  const backgroundColor = getProductRowBgColor(theme, isSelected, isInvalid);
  return {
    cursor: isSelectable ? 'pointer' : 'auto',
    backgroundColor,
  };
});

const ProductTable = ({
  ariaLabel,
  config,
  items = [],
  selectedItemId,
  invalidIds = [],
  onRowSelect,
}) => (
  <Table aria-label={ariaLabel} size='small'>
    <TableHead>
      <TableRow>
        {config.columns.map(
          ({ heading, width, headColspan, align, sx }, index) =>
            heading && (
              <TableCell
                key={index}
                width={width}
                colSpan={headColspan}
                align={align}
                sx={{ whiteSpace: { sm: 'nowrap' }, ...sx }}
              >
                {heading}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map((item = {}, index) => {
        const isInvalid = invalidIds.includes(item.id);
        const isSelected = selectedItemId && selectedItemId === item.id;

        return (
          <StyledTableRow
            key={`${item.id}-${index}`}
            isSelected={isSelected}
            onClick={() => onRowSelect && onRowSelect(item)}
            isInvalid={isInvalid}
            isSelectable={onRowSelect && !item.isDefaultValue}
          >
            {config.columns.map((column, index) => (
              <ProductTableItem
                key={`${column.path}-${index}`}
                item={item}
                column={column}
              />
            ))}
          </StyledTableRow>
        );
      })}
    </TableBody>
  </Table>
);

ProductTable.propTypes = {
  ariaLabel: PropTypes.string,
  config: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        heading: PropTypes.string,
        width: PropTypes.string,
        headColspan: PropTypes.number,
        bodyColspan: PropTypes.number,
        align: PropTypes.oneOf(['left', 'right', 'center']),
        render: PropTypes.func,
      })
    ).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isDefaultValue: PropTypes.bool,
      [PropTypes.string]: PropTypes.any,
    })
  ).isRequired,
  selectedItemId: PropTypes.string,
  invalidIds: PropTypes.arrayOf(PropTypes.string),
  onRowSelect: PropTypes.func,
};

export default ProductTable;
