import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, includes, set } from 'lodash';
import { Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import {
  OrderPriceAccumulator,
  ShipmentPrice,
} from '@dpdgroupuk/consumer-shipping-helper';
import { format } from 'date-fns';
import { BasketItemUtil, NetworkUtil, useAnalytics } from '../../utils';
import { ShipmentHelpers } from '../../helpers';
import { BASKET } from '../../constants/analytics';
import BasketItemsGroupsList from '../../components/BasketItemsGroupsList';
import { SummaryDetails } from '../../components/SummaryDetails';
import Voucher from '../../features/Voucher';
import SalesConditions from '../../components/SalesConditions';
import InformationCollecting from '../../components/InformationCollecting';
import ConfirmDialog from '../../components/modals/ConfirmModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import { BasketActions, BasketSelectors } from '../../redux/basket';
import PurchaseWidget from '../../components/PurchaseWidget';
import { useOverlay } from '../../features/Overlay';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { SHIPMENT_FIELDS } from '../../constants/forms';
import ConfigSlice from '../../redux/configSlice';
import Loader from '../../features/Loader';
import { FORM, POINT_TYPE, STRINGS } from '../../constants';
import { countriesSelectors } from '../../redux/countriesSlice';
import { validateFullShipmentSync } from '../Order/helpers/shipment';
import * as locationsApis from '../../apis/locationsApis';
import { PICKUP_STATUS_ACTIVE } from '../Order/constants';
import BasketCard from './components/BasketCard';
import BasketViewSlice, { validateCollectionDateAsync } from './slice';

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: theme.spacing(2),
  height: '100%',
  marginTop: theme.spacing(4),
}));

const Basket = () => {
  const dispatch = useDispatch();
  const basketId = useSelector(BasketSelectors.getBasketId);
  const basketItems = useSelector(BasketSelectors.getBasketItems);
  const checkoutList = useSelector(BasketViewSlice.selectors.getCheckoutList);
  const threeDSecureData = useSelector(
    BasketViewSlice.selectors.getThreeDSecureData
  );
  const [errors, setErrors] = useState({});
  const countries = useSelector(countriesSelectors.getCountries);
  const defaultCutOff = useSelector(
    ConfigSlice.selectors.getCollectionCutOffConfig
  );

  const { terms: termsLink } = useSelector(
    ConfigSlice.selectors.getLinksConfig
  );
  const { voucher: displayVoucher } = useSelector(
    ConfigSlice.selectors.getFeatureConfig
  );
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const isSmallScreen = useBreakpoint('sm');
  const theme = useTheme();
  const { setInterfaceId, Trackable } = useAnalytics();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const references = useSelector(BasketViewSlice.selectors.getBasketReferences);
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState();
  const [selectedBasketItem, setSelectedBasketItem] = useState();
  const receiverContactName = ShipmentHelpers.getReceiverContactName(
    selectedBasketItem?.shipment
  );
  const parcels = get(
    selectedBasketItem,
    `shipment.${SHIPMENT_FIELDS.PARCELS.KEY}`,
    []
  );

  const isCheckoutButtonDisabled = !acceptTerms || !checkoutList.length;

  const onAddToCheckout = useCallback(
    basketItemId => {
      dispatch(
        BasketViewSlice.actions.setCheckoutList([...checkoutList, basketItemId])
      );
    },
    [checkoutList]
  );

  const onRemoveFromCheckout = useCallback(
    basketItemIdToRemove => {
      const newCheckoutList = checkoutList.filter(
        basketItemId => basketItemId !== basketItemIdToRemove
      );
      return dispatch(BasketViewSlice.actions.setCheckoutList(newCheckoutList));
    },
    [checkoutList]
  );

  const onDeleteBasketItem = useCallback(async () => {
    try {
      overlay.show();
      setSelectedBasketItem(null);

      await dispatch(
        BasketActions.deleteBasketItem(selectedBasketItem.basketItemId)
      ).unwrap();
      onRemoveFromCheckout(selectedBasketItem.basketItemId);
      const newBasketItems = await dispatch(
        BasketActions.getBasketItems()
      ).unwrap();

      if (newBasketItems.length === 1) {
        const lastBasketItemId = newBasketItems[0].basketItemId;

        if (
          !checkoutList.includes(lastBasketItemId) &&
          !errors[lastBasketItemId]
        ) {
          dispatch(BasketViewSlice.actions.setCheckoutList([lastBasketItemId]));
        }
      }
    } catch (e) {
      snackbar.showError({
        message: STRINGS.FAILED_TO_DELETE_BASKET_ITEM,
      });
    } finally {
      overlay.hide();
    }
  }, [selectedBasketItem]);

  const checkoutListPrice = useMemo(() => {
    // totals section
    const totals = checkoutList.reduce((acc, id) => {
      const basketItem = basketItems.find(
        basketItem => basketItem.basketItemId === id
      );
      const reference = references[basketItem?.basketItemId];

      if (!reference || !basketItem) {
        return acc;
      }

      return OrderPriceAccumulator.aggregate(
        acc,
        id,
        ShipmentPrice.calculateShipmentPrice(
          basketItem.shipment,
          reference.networkPrice,
          priceConfig
        )
      );
    }, new OrderPriceAccumulator());

    // voucher section
    if (voucher) {
      totals.applyVoucherDiscount(voucher);
    }

    return totals.toViewJSON(2);
  }, [checkoutList, references, voucher, priceConfig, basketItems]);

  const basketItemsWithPrice = BasketItemUtil.getBasketItemsWithPrice(
    checkoutList,
    basketItems,
    checkoutListPrice
  );

  const handleCheckoutSubmit = useCallback(
    async payload => {
      try {
        overlay.show();
        const purchaseData = {
          amount: checkoutListPrice.totalIncVatAmount,
          paymentNonce: payload.nonce,
          vendorRef: payload.type,
          voucherId: voucher && voucher.voucherId,
          diaryDate: format(new Date(), 'dd/MM/yyyy'),
        };
        const { orderId, shipments } = await dispatch(
          BasketActions.checkout({
            basketItemIds: checkoutList,
            purchaseData,
          })
        ).unwrap();

        if (shipments.length === 1) {
          snackbar.showSuccess({
            // put correct message later
            message: 'Shipment have been successfully created',
          });

          // navigate to shipments/:shipmentId
          navigate(`/shipments/${shipments[0].shipmentId}`, {
            replace: true,
          });
        } else {
          snackbar.showSuccess({
            message: `${shipments.length} shipment(s) have been successfully created`,
          });

          navigate(`/orders/${orderId}`, {
            replace: true,
          });
        }
      } catch (error) {
        if (!error.details) {
          error.details = [error];
        }
        error.details.forEach(detail => {
          snackbar.showError({
            autoHideDuration: null,
            message: detail.message,
          });
        });
      } finally {
        overlay.hide();
      }
    },
    [checkoutList, voucher, checkoutListPrice]
  );

  useEffect(() => {
    const validateBasketItems = async () => {
      setInterfaceId(BASKET.INTERFACE_ID);

      const initialErrors = {};
      const defaultCheckoutList = [];

      for (const basketItem of basketItems) {
        const shipment = basketItem.shipment;
        const networkCode = get(
          shipment,
          FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY
        );
        const pickupLocationCode =
          shipment.outboundConsignment?.pickupDetails?.pickupLocationCode;

        if (
          networkCode &&
          NetworkUtil.removeBusinessUnit(networkCode) ===
            STRINGS.NETWORK_SHIP_TO_SHOP_KEY &&
          pickupLocationCode
        ) {
          try {
            const {
              data: { pickupLocationStatus },
            } = await locationsApis.getPickupLocationsById(pickupLocationCode);

            if (pickupLocationStatus !== PICKUP_STATUS_ACTIVE) {
              set(initialErrors, basketItem.basketItemId, {
                pickupLocationError: true,
              });
            }
          } catch (error) {
            snackbar.showError({
              // TODO: put correct message later
              message: 'Error validating pickup point',
            });
          }
        }

        const errors = validateFullShipmentSync(
          // TODO: load requiredFields, networks and handle it inside
          shipment,
          references,
          countries
        );

        if (
          !errors.includes(STRINGS.DATE_CANNOT_BE_IN_THE_PAST) &&
          get(shipment, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR
        ) {
          // Async validation for collection date
          try {
            const isValidCollectionDate = await dispatch(
              validateCollectionDateAsync({ values: shipment, defaultCutOff })
            ).unwrap();

            if (!isValidCollectionDate) {
              errors.push(STRINGS.INVALID_COLLECTION_DATE);
            }
          } catch (error) {
            snackbar.showError({
              // TODO: put correct message later
              message: 'Error validating collection date',
            });
          }
        }

        if (errors?.length !== 0) {
          set(initialErrors, basketItem.basketItemId, {
            ...initialErrors[basketItem.basketItemId],
            basketErrors: errors,
          });
        }

        if (errors.length === 0) {
          defaultCheckoutList.push(basketItem.basketItemId);
        }
      }

      setErrors(initialErrors);

      dispatch(BasketViewSlice.actions.setCheckoutList(defaultCheckoutList));
    };

    validateBasketItems();
  }, []);

  const loadReferences = useCallback(
    ({ basketId }) =>
      dispatch(
        BasketViewSlice.actions.fetchBasketReferences(basketId)
      ).unwrap(),
    []
  );

  return (
    <Trackable loadId={BASKET.LOAD}>
      <ConfirmDialog
        open={!!selectedBasketItem}
        onConfirm={onDeleteBasketItem}
        onClose={() => setSelectedBasketItem(null)}
        title={STRINGS.ADDRESSES_SECTION.DELETE_MODAL.TITLE}
        confirmButtonText={STRINGS.ADDRESSES_SECTION.DELETE_MODAL.DELETE_BUTTON}
        containerStyle={{
          maxWidth: isSmallScreen ? '100%' : '400px',
        }}
      >
        {STRINGS.WANT_TO_REMOVE_$(
          STRINGS.PARCEL_TO_$(receiverContactName, parcels.length)
        )}
      </ConfirmDialog>
      <Loader promiseFn={loadReferences} basketId={basketId}>
        {references => (
          <Grid
            sx={{
              background: theme.palette.primary.pageBackground,
              pb: 4,
              flexGrow: 1,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: 'center',
                mt: 1,
              }}
            >
              <Grid item>
                <Typography variant='h2' sx={{ m: 2 }} color='primary.main'>
                  {STRINGS.YOUR_CART}
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems='center' justifyContent='center'>
              {basketItems.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 0 }}
                  xs={12}
                  md={8}
                  justifyContent='center'
                >
                  <Grid item xs={12} md={8}>
                    <BasketItemsGroupsList
                      basketItems={basketItems}
                      basketItemComponent={({ basketItem }) => {
                        const isInCheckoutList = includes(
                          checkoutList,
                          basketItem.basketItemId
                        );

                        return (
                          <BasketCard
                            references={references[basketItem.basketItemId]}
                            key={basketItem.basketItemId}
                            basketItem={basketItem}
                            onRemove={() => setSelectedBasketItem(basketItem)}
                            isInCheckoutList={isInCheckoutList}
                            onAddToCheckout={onAddToCheckout}
                            onRemoveFromCheckout={onRemoveFromCheckout}
                            errors={errors}
                            isOnlyItem={basketItems.length === 1}
                            isOnlyItemInCheckout={
                              checkoutList.length === 1 && isInCheckoutList
                            }
                          />
                        );
                      }}
                    />
                    {displayVoucher && isSmallScreen && (
                      <Voucher voucher={voucher} setVoucher={setVoucher} />
                    )}
                    <SalesConditions
                      onAcceptTerms={setAcceptTerms}
                      salesConditionsLink={termsLink}
                    />
                    <InformationCollecting />
                  </Grid>
                  <StyledGrid item xs={12} md={4} sx={{ mt: 0, p: 0 }}>
                    <SummaryDetails
                      price={checkoutListPrice}
                      basketItemsWithPrice={basketItemsWithPrice}
                      showMoreDetails={false}
                    />
                    {displayVoucher && !isSmallScreen && (
                      <Voucher voucher={voucher} setVoucher={setVoucher} />
                    )}
                    <PurchaseWidget
                      basketId={basketId}
                      threeDSecure={threeDSecureData}
                      amount={checkoutListPrice.totalIncVatAmount.toString()}
                      handleCheckout={handleCheckoutSubmit}
                      sx={{ width: '100%' }}
                      disabled={isCheckoutButtonDisabled}
                    >
                      {checkoutList.length > 0
                        ? STRINGS.PROCESS_$_SHIPMENT(checkoutList.length)
                        : STRINGS.PROCESS_TO_CHECKOUT}
                    </PurchaseWidget>
                  </StyledGrid>
                </Grid>
              ) : (
                <Typography sx={{ mt: 1, color: 'text.secondary' }}>
                  {STRINGS.EMPTY_CARD.toUpperCase()}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Loader>
    </Trackable>
  );
};

export default Basket;
