import {
  createWizardSlice,
  initState as wizardInitialState,
} from '../../../features/Wizard/slice';

export const SignUpSlice = createWizardSlice('verifyEmail', undefined, {
  clearAll: state => wizardInitialState,
});

export const SignUpSliceSelectors = SignUpSlice.selectors;

export const SignUpSliceReducer = SignUpSlice.reducer;
