import { SvgIcon } from '@mui/material';

const Visa = props => (
  <SvgIcon {...props} viewBox='0 0 30 10'>
    <path
      d='M14.8581 0.433641L12.8991 9.59111H10.5302L12.4895 0.433641H14.8581ZM24.8248 6.34665L26.072 2.90771L26.7896 6.34665H24.8248ZM27.4678 9.59111H29.659L27.7469 0.433641H25.7244C25.27 0.433641 24.8865 0.697974 24.7158 1.10549L21.1618 9.59111H23.6495L24.1434 8.22365H27.1826L27.4678 9.59111ZM21.2853 6.60113C21.2954 4.18416 17.9428 4.05113 17.966 2.97147C17.973 2.64251 18.2863 2.29354 18.9706 2.20398C19.3106 2.15963 20.2453 2.12572 21.3067 2.6141L21.7223 0.672178C21.1522 0.465233 20.4187 0.266113 19.5062 0.266113C17.1649 0.266113 15.5169 1.51097 15.503 3.29349C15.4882 4.61167 16.6794 5.34729 17.5771 5.78552C18.4999 6.23449 18.81 6.52201 18.806 6.92343C18.7999 7.53818 18.0698 7.80889 17.3887 7.81962C16.1974 7.83846 15.5062 7.49818 14.9552 7.24139L14.5259 9.24794C15.0792 9.50213 16.1009 9.72299 17.1609 9.73429C19.6491 9.73429 21.2772 8.5048 21.2853 6.60113ZM11.4731 0.433641L7.63502 9.59111H5.13052L3.24193 2.28282C3.12715 1.83269 3.02744 1.66807 2.67877 1.47822C2.10981 1.16954 1.16929 0.879413 0.341797 0.699713L0.398315 0.433641H4.42911C4.9427 0.433641 5.40499 0.775651 5.52151 1.36721L6.51913 6.66664L8.98421 0.433641H11.4731Z'
      fill='#1434CB'
    />
  </SvgIcon>
);

export default Visa;
