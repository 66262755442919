import {
  PICKUP_POINT_AMENITIES,
  PICKUP_POINT_AMENITIES_TYPE,
} from '../../../constants/pickupPoint';
import { GB } from '../../../constants/strings';

// MAP CONFIG CONSTANTS
export const ITEM_HEIGHT = 145;
export const MAX_DISTANCE = 10;
export const SEARCH_PAGE_SIZE = 10;
export const ZOOM = 15;
export const POSTCODE = 'postcode';
export const MAP_OPTIONS = {
  maxDistance: 10,
  searchPageSize: 10,
  searchPage: 1,
  countryCode: GB,
};

export const FILTER_MENU_ITEMS = [
  {
    key: PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING,
    value: PICKUP_POINT_AMENITIES.LABEL_PRINTING,
  },
  {
    key: PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE,
    value: PICKUP_POINT_AMENITIES.OPEN_LATE,
  },
  {
    key: PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING,
    value: PICKUP_POINT_AMENITIES.CAR_PARKING,
  },
  {
    key: PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS,
    value: PICKUP_POINT_AMENITIES.OPEN_SATURDAYS,
  },
  {
    key: PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS,
    value: PICKUP_POINT_AMENITIES.OPEN_SUNDAYS,
  },
  {
    key: PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS,
    value: PICKUP_POINT_AMENITIES.WHEELCHAIR_ACCESS,
  },
];
