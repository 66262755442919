import { filter, get, head, indexOf, isEmpty, last, pick } from 'lodash';
import moment from 'moment-timezone';
import * as DATE_FORMAT from '../constants/dateFormat';
import * as M from '../constants/strings';
import {
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_TYPE,
} from '../constants/pickupPoint';

export const calculateMapFitBounds = ({
  currentLocation,
  pickupLocations = [],
}) => {
  let newLocation = currentLocation;

  if (!currentLocation) {
    if (pickupLocations.length === 0) {
      // bounds for Great Britain if no current location and pickup points
      return [
        [-7, 52.86],
        [2.35, 53.86],
      ];
    } else {
      newLocation = pickupLocations[0].pickupLocation.addressPoint;
    }
  }

  const calculatePickupShop = pickupLocations.reduce(
    (
      bounds,
      {
        pickupLocation: {
          addressPoint: { latitude, longitude },
        },
      }
    ) => {
      bounds.southWest.longitude = Math.max(
        longitude,
        bounds.southWest.longitude
      );
      bounds.southWest.latitude = Math.min(latitude, bounds.southWest.latitude);

      bounds.northEast.longitude = Math.min(
        longitude,
        bounds.northEast.longitude
      );
      bounds.northEast.latitude = Math.max(latitude, bounds.northEast.latitude);

      return bounds;
    },
    {
      southWest: {
        longitude: newLocation.longitude,
        latitude: newLocation.latitude,
      },
      northEast: {
        longitude: newLocation.longitude,
        latitude: newLocation.latitude,
      },
    }
  );

  // Bounds format: [[south, west], [north, east]]
  return [
    [
      calculatePickupShop.southWest.longitude,
      calculatePickupShop.southWest.latitude,
    ],
    [
      calculatePickupShop.northEast.longitude,
      calculatePickupShop.northEast.latitude,
    ],
  ];
};

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const weekdaysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const getCurrentDay = () =>
  getCurrentMomentDate().format(DATE_FORMAT.DAY);

export const getCurrentDayNumber = () => indexOf(weekdays, getCurrentDay()) + 1;

export const getPickupLocationCode = pickupPoint =>
  get(pickupPoint, 'pickupLocation.pickupLocationCode');

export const getPickupShortName = pickupPoint =>
  get(pickupPoint, 'pickupLocation.shortName');

export const getPickupAddress = pickupPoint =>
  get(pickupPoint, 'pickupLocation.address', {});

export const filterByType = (pickupPoints, type) =>
  type
    ? filter(pickupPoints, {
        pickupLocation: { kind: type },
      })
    : pickupPoints;

export const getPickupAddressPoint = pickupPoint =>
  get(pickupPoint, 'pickupLocation.addressPoint');

export const getCurrentMomentDate = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

const getOpenWindowStart = openWindow =>
  get(openWindow, 'pickupLocationOpenWindowStartTime');

const getOpenWindowEnd = openWindow =>
  get(openWindow, 'pickupLocationOpenWindowEndTime');

export const getTimeMomentDate = time =>
  moment.tz(time, DATE_FORMAT.TIME, DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

const getDayOpenWindows = (pickupPoint, dayNumber) => {
  const pickupLocationOpenWindow = get(
    pickupPoint,
    'pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow',
    []
  );

  const openWindows = filter(pickupLocationOpenWindow, [
    'pickupLocationOpenWindowDay',
    dayNumber,
  ]);

  if (isEmpty(openWindows)) {
    return {};
  }

  const openTime = getOpenWindowStart(head(openWindows));
  const closeTime = getOpenWindowEnd(last(openWindows));

  const breakWindows = [];
  for (let i = 0; i < openWindows.length - 1; i++) {
    const currentEndTime = getOpenWindowEnd(openWindows[i]);
    const nextStartTime = getOpenWindowStart(openWindows[i + 1]);
    if (
      getTimeMomentDate(currentEndTime).isBefore(
        getTimeMomentDate(nextStartTime)
      )
    ) {
      breakWindows.push({ start: currentEndTime, end: nextStartTime });
    }
  }

  return {
    openTime,
    closeTime,
    breaks: breakWindows,
  };
};

export const getOpenSaturday = pickupPoint =>
  get(pickupPoint, 'pickupLocation.openSaturday');

export const getOpenSunday = pickupPoint =>
  get(pickupPoint, 'pickupLocation.openSunday');

const isPickupOpenAt = (pickupPoint, day) => {
  switch (day) {
    case 'Saturday':
      return getOpenSaturday(pickupPoint);
    case 'Sunday':
      return getOpenSunday(pickupPoint);
    default:
      return true;
  }
};

export const getTimeString = time => {
  const hours = moment(time, DATE_FORMAT.TIME).format('h');
  const minutes = moment(time, DATE_FORMAT.TIME).format('mm');

  return `${hours}${minutes !== '00' ? `:${minutes}` : ''}${moment(
    time,
    DATE_FORMAT.TIME
  ).format('a')}`;
};

export const isCurrentTimeAfter = time =>
  getCurrentMomentDate().isAfter(getTimeMomentDate(time));

const getNextOpenDayOpensStatus = (currentDayNumber, pickupPoint) => {
  const getDayNumber = day => get(day, 'pickupLocationOpenWindowDay');
  const allDaysOpenWindows = get(
    pickupPoint,
    'pickupLocation.pickupLocationAvailability.pickupLocationOpenWindow'
  );

  if (isEmpty(allDaysOpenWindows)) {
    return '';
  }

  const nextOpenDay =
    find(allDaysOpenWindows, day => getDayNumber(day) > currentDayNumber) ||
    head(allDaysOpenWindows);
  const nextOpenDayNumber = getDayNumber(nextOpenDay);
  const { openTime } = getDayOpenWindows(pickupPoint, nextOpenDayNumber);

  return `${M.OPENS} ${weekdaysShort[nextOpenDayNumber - 1]} ${getTimeString(
    openTime
  )}`;
};

export const isCurrentTimeBefore = time =>
  getCurrentMomentDate().isBefore(getTimeMomentDate(time));

const getOpenStatusString = (status, time) =>
  `${status} ${getTimeString(time)}`;

export const getIsCurrentTimeBetween = (startTime, endTime) => {
  const currentDate = getCurrentMomentDate();

  const isCurrentTimeAfterStartTime = currentDate.isSameOrAfter(
    getTimeMomentDate(startTime)
  );

  const isCurrentTimeBeforeEndTime = currentDate.isBefore(
    getTimeMomentDate(endTime)
  );

  return isCurrentTimeAfterStartTime && isCurrentTimeBeforeEndTime;
};

export const getOpenStatusObject = pickupPoint => {
  const currentDate = getCurrentMomentDate();
  const currentDayName = currentDate.format(DATE_FORMAT.DAY);
  const currentDayNumber = getCurrentDayNumber();
  const currentDayOpenWindows = getDayOpenWindows(
    pickupPoint,
    currentDayNumber
  );

  const isOpenDay =
    isPickupOpenAt(pickupPoint, currentDayName) &&
    !isEmpty(currentDayOpenWindows);
  const { openTime, closeTime, lunchStart, lunchEnd } = currentDayOpenWindows;

  let openStatusObject = {};

  if (!isOpenDay || isCurrentTimeAfter(closeTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: getNextOpenDayOpensStatus(currentDayNumber, pickupPoint),
    };
  }

  if (isCurrentTimeBefore(openTime)) {
    openStatusObject = {
      isOpen: false,
      openStatus: getOpenStatusString(M.OPENS, openTime),
    };
  }

  if (getIsCurrentTimeBetween(openTime, closeTime)) {
    openStatusObject = {
      isOpen: true,
      openStatus: getOpenStatusString(M.CLOSES, closeTime),
    };
  }

  if (getIsCurrentTimeBetween(lunchStart, lunchEnd)) {
    openStatusObject = {
      isOpen: false,
      openStatus: M.CLOSED_FOR_LUNCH,
    };
  }

  if (lunchStart && lunchEnd) {
    openStatusObject = {
      ...openStatusObject,
      closedForLunch: `${lunchStart} - ${lunchEnd}`,
    };
  }

  return openStatusObject;
};

export const getPickupKind = pickupPoint =>
  get(pickupPoint, 'pickupLocation.kind');

export const getPickupType = pickupPoint => {
  const pickupLocationKind = getPickupKind(pickupPoint);

  switch (pickupLocationKind) {
    case PICKUP_POINT_TYPE.SHOP:
      return M.SHOP;
    case PICKUP_POINT_TYPE.LOCKER:
      return M.LOCKER;
    default:
      return M.SHOP;
  }
};

export const getDistanceToPickup = pickupPoint => {
  const distance = get(pickupPoint, 'distance');

  return distance ? distance.toFixed(2) : '';
};

export const getPickupInfo = pickupPoint => {
  const { isOpen, openStatus } = getOpenStatusObject(pickupPoint);
  return {
    type: getPickupType(pickupPoint),
    distance: getDistanceToPickup(pickupPoint),
    isOpen,
    openStatus,
  };
};

export const getPartnerLogo = pickupPoint =>
  get(pickupPoint, 'pickupLocation.partnerImageLogo');

export const getPickupAmenities = pickupPoint =>
  pick(get(pickupPoint, 'pickupLocation'), [
    PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS,
    PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS,
    PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS,
    PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING,
  ]);

export const getDayNameByShortName = shortName =>
  weekdays[indexOf(weekdaysShort, shortName)];

const mergeOpenWindows = openWindows => {
  const getMergedOpenWindowObject = mergedOpenSlots => ({
    day:
      mergedOpenSlots.length > 1
        ? `${head(mergedOpenSlots).day} - ${last(mergedOpenSlots).day}`
        : head(mergedOpenSlots).day,
    openWindow: head(mergedOpenSlots).openWindow,
  });

  const mergedOpenWindowList = openWindows
    .reduce((groups, window) => {
      const groupDays = last(groups);

      if (!groupDays || last(groupDays).openWindow !== window.openWindow) {
        groups.push([window]);
      } else {
        groupDays.push(window);
      }
      return groups;
    }, [])
    .map(groupDays => getMergedOpenWindowObject(groupDays));

  return mergedOpenWindowList;
};

const mergeTimeWindows = (windows, breakStart, breakEnd) => {
  const mergedWindows = [];
  for (const window of windows) {
    if (window.end <= breakStart || window.start >= breakEnd) {
      mergedWindows.push(window);
    } else {
      if (window.start < breakStart) {
        mergedWindows.push({ start: window.start, end: breakStart });
      }
      if (window.end > breakEnd) {
        mergedWindows.push({ start: breakEnd, end: window.end });
      }
    }
  }
  return mergedWindows;
};

export const getPickupOpenWindowList = pickupPoint => {
  const openWindows = weekdaysShort.map((day, index) => {
    const dayOpenWindows = getDayOpenWindows(pickupPoint, index + 1);
    const isOpenDay =
      isPickupOpenAt(pickupPoint, getDayNameByShortName(day)) &&
      !isEmpty(dayOpenWindows);

    if (isOpenDay) {
      const { openTime, closeTime, breaks } = dayOpenWindows;

      let openWindows = [{ start: openTime, end: closeTime }];
      if (breaks && breaks.length > 0) {
        // Filter out breaks where start equals end
        const validBreaks = breaks.filter(b => b.start !== b.end);

        // Merge breaks into openWindows
        for (const b of validBreaks) {
          openWindows = mergeTimeWindows(openWindows, b.start, b.end);
        }
      }

      const openWindowStr = openWindows
        .map(w => `${w.start} - ${w.end}`)
        .join(', ');

      return {
        day,
        openWindow: openWindowStr,
      };
    } else {
      return {
        day,
        openWindow: M.CLOSED,
      };
    }
  });

  return mergeOpenWindows(openWindows);
};

export const splitTimeRange = timeRange =>
  timeRange.split('-').map(time => time.trim());
